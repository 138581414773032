import React, { useState } from "react";
import { Link } from "react-router-dom";
// import "./Registration.css";
import Table from "react-bootstrap/Table";
import { FaEye } from "react-icons/fa";
import Header from "../header/Header";
import Modal from "react-bootstrap/Modal";

function Registration() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedOptions, setSelectedOptions] = useState();

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_compliance wrapper_Registration">
      <Header />
      <div className="container">
        <div className="wrapper_cards_consultant">
          <div className="cards_consult">
            <div className="cstm_cards_cols">
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>40</h3>
                  <p>Total registration</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>200</h3>
                  <p>Active registration</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>25000</h3>
                  <p>Expired registration</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>40</h3>
                  <p>Due for renewable</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>10</h3>
                  <p>Not applicable</p>
                </div>
              </div>
            </div>
            <div className="cstm_cards_cols">
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>200</h3>
                  <p>Hold</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>2500</h3>
                  <p>Applied for</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper_custom_search">
            <div className="custom_search">
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>Choose company</option>
                    <option>Select Industry</option>
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>Select location</option>
                    <option>Active</option>
                    <option>InActive</option>
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>State</option>
                    <option>Delhi</option>
                    <option>Delhi</option>
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>Status</option>
                    <option>Active</option>
                    <option>InActive</option>
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>Registration type</option>
                    <option>Offline</option>
                    <option>Online</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="data_table">
            <Table striped bordered hover>
              <thead>
                <tr className="custom_tr">
                  <th>S.NO.</th>
                  <th>COMPANY NAME</th>
                  <th>LOCATION</th>
                  <th>STATE</th>
                  <th>EXECUTOR</th>
                  <th>MANAGER</th>
                  <th>ACT/RULE</th>
                  <th>REGISTRATION TYPE</th>
                  <th>REGISTRATION NO.</th>
                  <th>UPLOADED</th>
                  <th>ISSUE DATE</th>
                  <th>EXPIRY DATE</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr className="cstm_tr">
                  <td>1</td>
                  <td>Goodwill Comp.</td>
                  <td>Sec-44, Noida</td>
                  <td>U.P.</td>
                  <td>Gourav kapoor</td>
                  <td>Sourabh Dutta</td>
                  <td>Section 11A</td>
                  <td>Online</td>
                  <td>31832</td>
                  <td>Self</td>
                  <td>22/02/2022</td>
                  <td>22/02/2023</td>
                  <td>Active</td>
                  <td>
                    <Link to="">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr className="cstm_tr">
                  <td>1</td>
                  <td>Goodwill Comp.</td>
                  <td>Sec-44, Noida</td>
                  <td>U.P.</td>
                  <td>Gourav kapoor</td>
                  <td>Sourabh Dutta</td>
                  <td>Section 11A</td>
                  <td>Online</td>
                  <td>31832</td>
                  <td>Self</td>
                  <td>22/02/2022</td>
                  <td>22/02/2023</td>
                  <td>Active</td>
                  <td>
                    <Link to="">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {/* Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Title></Modal.Title>

            <Modal.Body>
              <div className="wrapper-modal-div">
                <div className="modal_uin">
                  <div className="modal_exce">
                    <p>UIN Number</p>
                    <span>34224232</span>
                  </div>
                  <div className="modal_exce">
                    <p>Executor</p>
                    <span>kapil@gmail.com</span>
                  </div>
                </div>
                <div className="modal_uin modelpopup">
                  <div className="modal_exce">
                    <p>Manager</p>
                    <span>sourabh@gmail.com</span>
                  </div>
                  <div className="modal_exce">
                    <p>Periodicity</p>
                    <span>Annualy</span>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </section>
  );
}

export default Registration;
