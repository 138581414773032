import { api, AuthToken } from "./AuthController";

export async function notificationList(value) {
  var token = await AuthToken();

  var res = await api.get(`notification/notificationListing?isRead=${value}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function setReadNotification(id) {
  var token = await AuthToken();

  var res = await api.get(
    `notification/notificationManagementDataById?notificationId=${id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}
