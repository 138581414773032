import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Manage.css";
import { IoIosArrowBack } from "react-icons/io";
import { useRef } from "react";
import Header from "../../Layout/Header";

function AddTask() {
  const [fileName, SetFileName] = useState();

  const kyMbrImg = (e, i) => {
    // SetFileName(e.target.files[0].name);
    document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  const ref = useRef();

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_add_task">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/manager/manage">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Add Task</h5>
              </div>
            </div>
            <div className="content_wrapper_acts">
              <div className="form_field">
                <input
                  type="text"
                  className="input"
                  placeholder="Enter Company Name"
                />
                <input type="text" className="input" placeholder="Location" />
                <input
                  type="text"
                  className="input"
                  placeholder="Enter task name"
                />
              </div>
              <div className="form_field">
                <input
                  className="input"
                  placeholder="Due date"
                  type="text"
                  ref={ref}
                  onFocus={() => (ref.current.type = "date")}
                  onBlur={() => (ref.current.type = "text")}
                />
                <input type="text" className="input" placeholder="Purodicity" />
                <input type="text" className="input" placeholder="Executor" />
              </div>
              <div className="form_field">
                <input type="text" className="input" placeholder="Manager" />
              </div>
              <div className="heads_text">
                <h4>Upload Documents</h4>
              </div>
              <div className="input_upload_file">
                <label>
                  <input className="input" type="file" onChange={kyMbrImg} />
                  <div className="wrapper_upload_div">
                    <span className="upload_div1" id="image_">
                      file name {fileName}
                    </span>
                    <span className="Upload-file">+ Upload</span>
                  </div>
                </label>
                <span>+ Add more</span>
              </div>
            </div>
            <div className="applicable_not">
              <Link className="custom_btn2" to="">
                Save
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddTask;
