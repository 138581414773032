import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Registration.css";
import Header from "../Layout/Header";
import { IoIosArrowBack } from "react-icons/io";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Table from "react-bootstrap/Table";
import Select from "react-select";
import {
  companyData,
  stateData,
  locationData,
  consultantList,
  addRegistration,
  registrationType,
  documentList,
  getRegistrationDetail,
  updateRegistration,
  getNormalRegistrationDetail,
} from "../../controller/RegistrationController";
import {
  headByCompanyId,
  managerByCompanyId,
} from "../../controller/ManageController";

import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import useDebounce from "../../hooks/useDebounce";
import { getDefaultNormalizer } from "@testing-library/react";

function RenewRegistration() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [files, setFiles] = useState([""]);
  const [allTypes, setAllTypes] = useState([]);
  const [allManager, setAllManager] = useState([]);
  const [allHead, setAllHead] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const { id } = useParams();

  // debounce hook
  const debounceValue = useDebounce(searchValue, 500);

  const [selfModal, setSelfModal] = useState(false);
  const [consultantModal, setConsultantModal] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState("");
  const [enableDocumentSection, setEnableDocumentSection] = useState(false);
  const [tempImage, setTempImage] = useState([]);

  const [selectedManager, setSelectedManager] = useState([]);
  const [selectedHead, setSelectedHead] = useState([]);

  const [getRegistration, setRegistration] = useState({
    companyId: "",
    RegistrationType: "",
    location: "",
    state: "",
    managerEmail: "",
    filledBy: "self",
    registrationNumber: "",
    issueDate: "",
    expireDate: "",
    file: "",
    masterRegistraionId: "",
  });

  const navigate = useNavigate();

  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  // files handler
  const kyMbrImg = (e, ind) => {
    const image = [...allDocType];
    image[ind]["file"] = e.target.files[0];
    image[ind]["new"] = true;
    setAllDocType(image);
    //! document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  // self files handler
  const kyMbrImg2 = (e, ind) => {
    const image = [...files];
    image[ind] = e.target.files[0];
    image[ind]["new"] = true;
    setFiles(image);

    //! document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  const [getCompany, setCompany] = useState([]);
  const getCompanyList = async () => {
    var res = await companyData();
    if (res.status == 200) {
      var isArray = Array.isArray(res.data);
      var arr = [];
      if (isArray == false) {
        arr.push(res.data);
      } else {
        arr = res.data;
      }
      setCompany(arr);
    } else {
      toast.error(res.message);
    }
  };

  const [getState, setState] = useState([]);
  const getStateList = async (id = getRegistration.companyId) => {
    var data = {
      companyId: id,
    };
    if (id !== "") {
      var res = await stateData(data);
      if (res.status === 200) {
        setState(res.data);
      } else {
        toast.error(res.message);
      }
    } else {
      setState([]);
      setLocationsList([]);
    }
  };

  const [getConsultant, setConsultant] = useState([]);
  const getConsultantList = async (companyId, search) => {
    if (companyId) {
      // if ((company != '') && (state != '') && (registrationType != '')) {
      var res = await consultantList(companyId, search);
      if (res.status === 200) {
        setConsultant(res.data);
      } else {
        toast.error(res.message);
      }
    } else {
      setConsultant([]);
    }
  };

  const [allDocType, setAllDocType] = useState([]);
  const getDocList = async () => {
    try {
      const data = {
        registrationTypeId: getRegistration.RegistrationType,
        state: getRegistration.state,
      };
      const res = await documentList(data);

      if (res.status === 200) {
        setAllDocType(
          res.data.registratonData.document.map((data, i) => {
            return {
              ...data,
              file: tempImage[i]?.images || "",
              _id: tempImage[i]?._id || "",
            };
          })
        );
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const [getLocationsList, setLocationsList] = useState([]);
  const locationList = async (
    companyId = getRegistration.companyId,
    stateId = getRegistration.state
  ) => {
    var data = {
      companyId: companyId,
      stateId: stateId,
    };
    if (companyId !== "" && stateId !== "") {
      var res = await locationData(data);
      if (res.status === 200) {
        setLocationsList(res.data);
      } else {
        toast.error(res.message);
      }
    } else {
      setLocationsList([]);
    }
  };

  const selfSubmitWithModal = (e) => {
    e.preventDefault();
    if (!getRegistration.companyId) {
      toast.error("Please Select Company");
    } else if (!getRegistration.state) {
      toast.error("Please Select State");
    } else if (!getRegistration.location) {
      toast.error("Please Select Location");
    } else if (!getRegistration.RegistrationType) {
      toast.error("Please Enter Registration Type");
    } else if (!getRegistration.managerEmail) {
      toast.error("Please Enter Manager Mail");
    } else {
      setSelfModal(true);
    }
  };

  const consultantSubmitWithModal = async (e) => {
    e.preventDefault();
    if (!getRegistration.companyId) {
      toast.error("Please Select Company");
    } else if (!getRegistration.state) {
      toast.error("Please Select State");
    } else if (!getRegistration.location) {
      toast.error("Please Select Location");
    } else if (!getRegistration.RegistrationType) {
      toast.error("Please Enter Registration Type");
    } else if (!getRegistration.managerEmail) {
      toast.error("Please Enter Manager Mail");
    } else {
      await getConsultantList(getRegistration.companyId);
      setConsultantModal(true);
    }
  };

  const finalSelfSubmit = async (e) => {
    e.preventDefault();
    if (!getRegistration.registrationNumber) {
      toast.error("Please Enter Registration Number");
    } else if (!startDate) {
      toast.error("Please Select Issue Date");
    }
    // else if (!endDate) {
    //   toast.error("Please Select Expiry Date");
    // }
    else if (!files[0]) {
      toast.error("Please Select Files");
    } else {
      try {
        setSubmitLoading(true);
        getRegistration.file = files;
        getRegistration.issueDate = startDate;
        getRegistration.expireDate = endDate;

        let formData = new FormData();
        formData.append(
          "masterRegistraionId",
          getRegistration.masterRegistraionId
        );
        formData.append("registrationTypeId", getRegistration.RegistrationType);
        formData.append("companyId", getRegistration.companyId);
        formData.append("issueDate", getRegistration.issueDate);
        formData.append("expireDate", getRegistration.expireDate);
        formData.append("filledBy", getRegistration.filledBy);
        formData.append("location", getRegistration.location);
        getRegistration.managerEmail.forEach((data) =>
          formData.append("managerEmail", data.value)
        );
        // formData.append(
        //   "headEmail",
        //   getRegistration.headEmail.map((data) => data.value)
        // );

        getRegistration.headEmail.forEach((data) =>
          formData.append("headEmail", data.value)
        );
        formData.append(
          "registrationNumber",
          getRegistration.registrationNumber
        );
        formData.append("state", getRegistration.state);

        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
          }
        }
        var res = await addRegistration(formData);
        if (res.status === 200) {
          toast.success("Success");
          setSubmitLoading(false);
          navigate("/registration");
        } else {
          setSubmitLoading(false);
          toast.error(res.message);
        }
      } catch (err) {
        setSubmitLoading(false);
        toast.error(err);
      }
    }
  };

  const toggleDocumentSection = (e) => {
    e.preventDefault();
    if (!selectedConsultant) {
      toast.error("Please Select Consultant !");
    } else {
      getDocList();
      setEnableDocumentSection(true);
    }
  };

  const finalConsultantSubmit = async (e) => {
    e.preventDefault();
    const validation = allDocType.map((data) => {
      if (data.isRequired) {
        if (!data.file) {
          toast.error(`Please Select ${data.documentName}`);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    if (allDocType.length > 0 && validation.every((data) => data)) {
      try {
        setSubmitLoading(true);
        let formData = new FormData();
        formData.append(
          "masterRegistraionId",
          getRegistration.masterRegistraionId
        );
        formData.append("registrationTypeId", getRegistration.RegistrationType);
        formData.append("companyId", getRegistration.companyId);
        formData.append("filledBy", getRegistration.filledBy);
        formData.append("location", getRegistration.location);
        getRegistration.managerEmail.forEach((data) =>
          formData.append("managerEmail", data.value)
        );
        // formData.append("managerEmail", getRegistration.managerEmail.forEach(data=>data.value));
        // formData.append(
        //   "headEmail",
        //   getRegistration.headEmail.forEach((data) => data.value)
        // );
        getRegistration.headEmail.forEach((data) =>
          formData.append("headEmail", data.value)
        );
        formData.append("consultantEmail", selectedConsultant);
        formData.append("state", getRegistration.state);

        if (getRegistration.remarks?.length > 0) {
          formData.append(
            "remarksId",
            getRegistration.remarks[getRegistration.remarks.length - 1]._id
          );
        }

        if (files.length > 0) {
          for (let i = 0; i < allDocType.length; i++) {
            if (allDocType[i]["new"]) {
              formData.append("file", allDocType[i]["file"]);
              formData.append("imageId", allDocType[i]["_id"]);
            }
          }
        }
        var res = await addRegistration(formData);

        if (res.status === 200) {
          toast.success("Success");
          setSubmitLoading(false);
          setSelectedConsultant("");
          setEnableDocumentSection(false);
          navigate("/registration");
        } else {
          setSubmitLoading(false);
          toast.error(res.message);
        }
      } catch (err) {
        setSubmitLoading(false);
        toast.error(err);
      }
    }
  };

  //   get registration detail
  const getRegistrationDetails = async (id) => {
    try {
      var res = await getNormalRegistrationDetail(id);
      if (res.status === 200) {
        console.log(res, "get Response");
        setRegistration({
          ...getRegistrationDetail,
          masterRegistraionId: res.data[0].masterRegistraionId,
          companyId: res.data[0].companyId,
          RegistrationType: res.data[0].registrationTypeId,
          location: res.data[0].location,
          state: res.data[0].state,
          managerEmail: res.data[0].managerEmails.map((data) => {
            return {
              label: data.email,
              value: data.email,
            };
          }),
          headEmail: res.data[0].headEmails.map((data) => {
            return {
              label: data.email,
              value: data.email,
            };
          }),
          filledBy: res.data[0].filledBy,
          registrationNumber: res.data[0].registrationNumber,
          remarks: res.data[0].remarks,
        });
        setSelectedConsultant(res.data[0].consultantEmail);
        setTempImage(res.data[0].imagesArray);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    getConsultantList(getRegistration.companyId, debounceValue);
  }, [debounceValue]);

  useEffect(() => {
    const getManagersList = async () => {
      try {
        const res = await managerByCompanyId(getRegistration.companyId);

        if (res.status === 200) {
          setAllManager(
            res.data.map((data) => {
              return {
                label: data.email,
                value: data.email,
              };
            })
          );
        } else {
          toast.error(res.message);
          if (res.status === 200) {
            toast.success("Success");
            setSubmitLoading(false);
            navigate("/registration");
          } else {
            setSubmitLoading(false);
            toast.error(res.message);
          }
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };
    const getHeadsList = async () => {
      try {
        const res = await headByCompanyId(getRegistration.companyId);

        if (res.status === 200) {
          setAllHead(
            res.data.map((data) => {
              return {
                label: data.email,
                value: data.email,
              };
            })
          );
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };

    getRegistration.companyId && getManagersList();
    getRegistration.companyId && getHeadsList();
    getStateList(getRegistration.companyId);
  }, [getRegistration.companyId]);

  useEffect(() => {
    locationList(getRegistration.companyId, getRegistration.state);
  }, [getRegistration.state]);

  useEffect(() => {
    const getRegistrationType = async () => {
      try {
        const res = await registrationType();

        if (res.status === 200) {
          setAllTypes(res.data);
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };

    getRegistrationType();
    getRegistrationDetails(id);
  }, []);

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_registration_detail wrapper_noticed_details">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/registration">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Update Registration</h5>
              </div>
            </div>
            <form>
              <div className="content_wrapper_acts">
                <div className="noticedetails_form_wrapper">
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <select
                        className="input"
                        value={getRegistration.companyId}
                        onChange={(e) =>
                          setRegistration({
                            ...getRegistration,
                            companyId: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Company</option>
                        {getCompany && getCompany.length > 0
                          ? getCompany.map((value, i) => (
                              <option value={value._id}>
                                {value.companyName}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="form_field">
                      <select
                        className="input"
                        value={getRegistration.location}
                        onChange={(e) =>
                          setRegistration({
                            ...getRegistration,
                            location: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Location</option>
                        {getLocationsList && getLocationsList.length > 0
                          ? getLocationsList.map((value, i) => (
                              <option value={value}>{value}</option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="form_field">
                      {/* <select
                        className="input"
                        value={getRegistration.managerEmail}
                        onChange={(e) =>
                          setRegistration({
                            ...getRegistration,
                            managerEmail: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Manager Email</option>
                        {allManager &&
                          allManager.map(({ email }) => {
                            return (
                              <option value={email} key={email}>
                                {email}
                              </option>
                            );
                          })}
                      </select> */}

                      {/* Manager multiple select  */}
                      <Select
                        value={getRegistration.managerEmail}
                        onChange={(data) =>
                          setRegistration({
                            ...getRegistration,
                            managerEmail: data,
                          })
                        }
                        options={allManager}
                        isMulti
                        className="w-100"
                      />
                      {/* End  */}
                    </div>

                    <div className="form_field">
                      {/* <select
                        className="input"
                        value={getRegistration.headEmail}
                        onChange={(e) =>
                          setRegistration({
                            ...getRegistration,
                            headEmail: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Head Email</option>
                        {allHead &&
                          allHead.map(({ email }) => {
                            return (
                              <option value={email} key={email}>
                                {email}
                              </option>
                            );
                          })}
                      </select> */}
                      {/* select multiple Head emails  */}
                      <Select
                        value={getRegistration.headEmail}
                        onChange={(data) =>
                          setRegistration({
                            ...getRegistration,
                            headEmail: data,
                          })
                        }
                        options={allHead}
                        isMulti
                        className="w-100"
                      />

                      {/* End  */}
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <select
                        className="input"
                        value={getRegistration.state}
                        onChange={(e) =>
                          setRegistration({
                            ...getRegistration,
                            state: e.target.value,
                          })
                        }
                      >
                        <option value="">State</option>
                        {getState && getState.length > 0
                          ? getState.map((value, i) => (
                              <option value={value}>{value}</option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="form_field">
                      <select
                        className="input"
                        value={getRegistration.RegistrationType}
                        onChange={(e) =>
                          setRegistration({
                            ...getRegistration,
                            RegistrationType: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Type</option>
                        {allTypes &&
                          allTypes.map((type) => {
                            return (
                              <option value={type._id} key={type._id}>
                                {type.registrationType}
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    <div className="form_field">
                      <div className="wrapper_radio">
                        <div className="radio_wrap_">
                          <label htmlFor="red">
                            <input
                              value={"self"}
                              onChange={(e) =>
                                setRegistration({
                                  ...getRegistration,
                                  filledBy: e.target.value,
                                  registrationNumber: "",
                                  expireDate: "",
                                  issueDate: "",
                                  file: "",
                                })
                              }
                              type="radio"
                              name="colors"
                              id="red"
                              checked={getRegistration.filledBy === "self"}
                              required
                            />
                            <span>Self</span>
                          </label>
                        </div>
                        <div className="radio_wrap_">
                          <label htmlFor="blue">
                            <input
                              value={"consultant"}
                              onChange={(e) =>
                                setRegistration({
                                  ...getRegistration,
                                  filledBy: e.target.value,
                                  registrationNumber: "",
                                  expireDate: "",
                                  issueDate: "",
                                  file: "",
                                })
                              }
                              type="radio"
                              name="colors"
                              id="blue"
                              checked={
                                getRegistration.filledBy === "consultant"
                              }
                              required
                            />
                            <span>Consultant</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="applicable_not">
                  {getRegistration.filledBy === "consultant" && (
                    <Button
                      // disabled={getRadioOne}
                      className="custom_btn tempo_button"
                      variant="primary"
                      onClick={consultantSubmitWithModal}
                    >
                      Proceed
                    </Button>
                  )}

                  {getRegistration.filledBy === "self" && (
                    <Button
                      className="custom_btn tempo_button"
                      variant="primary"
                      onClick={selfSubmitWithModal}
                    >
                      No Action Required
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>

          <Modal
            show={selfModal}
            onHide={() => setSelfModal(false)}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design modal_cstm_design2"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Title></Modal.Title>
            <Modal.Body>
              <div className="wrapper-modal-div">
                <form>
                  <div className="modal_uin">
                    <div className="form_field">
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter Registration Number"
                        value={getRegistration.registrationNumber}
                        onChange={(e) =>
                          setRegistration({
                            ...getRegistration,
                            registrationNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form_field">
                      <div className="flex_direction_cstm">
                        <DatePicker
                          selected={startDate}
                          className="input input1"
                          onChange={(date) => setStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          selectsStart
                          startDate={startDate}
                          placeholderText="Enter Issue Date"
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </div>
                    </div>
                    <div className="form_field">
                      <div className="flex_direction_cstm">
                        <DatePicker
                          selected={endDate}
                          className="input input1"
                          onChange={(date) => setEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          selectsStart
                          startDate={endDate}
                          placeholderText="Enter Expiry Date"
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </div>
                    </div>
                    <div className="form_field">
                      <h5>Add Attachment</h5>
                      {files &&
                        files.map((file, ind) => {
                          return (
                            <div className="input_upload_file" key={ind}>
                              <label className="my-2">
                                <input
                                  className="input"
                                  // name={file.id}
                                  type="file"
                                  onChange={(e) => kyMbrImg2(e, ind)}
                                />
                                <div className="wrapper_upload_div">
                                  <span className="upload_div1" id="image_">
                                    file name {file?.name}
                                  </span>
                                  <span className="Upload-file">+ Upload</span>
                                </div>
                              </label>
                              {ind === 0 ? (
                                <span
                                  className="add_more_append"
                                  onClick={() => setFiles([...files, ""])}
                                >
                                  + Add more
                                </span>
                              ) : (
                                <span
                                  className="add_more_append"
                                  onClick={(e) =>
                                    setFiles(
                                      files.filter(
                                        (file, index) => index !== ind
                                      )
                                    )
                                  }
                                >
                                  Remove
                                </span>
                              )}
                            </div>
                          );
                        })}
                    </div>
                    <div className="form_field">
                      <button
                        className="custom_btn"
                        onClick={finalSelfSubmit}
                        ref={submitButtonElement}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal
            show={consultantModal}
            onHide={() => {
              setConsultantModal(false);
            }}
            // backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design modal_cstm_design2 modal_design3"
          >
            {!enableDocumentSection ? (
              <>
                <Modal.Header closeButton>
                  <h5>Choose Consultant</h5>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="wrapper-modal-div-2">
                      <div className="modal_uin">
                        <div className="form_field form_field_csto">
                          <input
                            className="input"
                            type="search"
                            placeholder="Search Consultant"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </div>
                        {getConsultant.length > 0 ? (
                          getConsultant.map(({ email }) => {
                            return (
                              <div
                                className="form_field radio_wrap_"
                                key={email}
                              >
                                <label htmlFor={email}>
                                  <input
                                    type="radio"
                                    name="colors"
                                    id={email}
                                    value={email}
                                    checked={email === selectedConsultant}
                                    onChange={(e) =>
                                      setSelectedConsultant(e.target.value)
                                    }
                                    required
                                  />
                                  <span className="text-dark">{email}</span>
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <div className="form_field radio_wrap_">
                            <h5>Consultant Not Available</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form_field form_fill2">
                      <button
                        className="custom_btn"
                        onClick={toggleDocumentSection}
                        ref={submitButtonElement}
                      >
                        Next
                      </button>
                      {/* <button className="custom_btn">Submit</button> */}
                    </div>
                  </form>
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Header closeButton>
                  <h5>Upload Documents</h5>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    {allDocType.length > 0 ? (
                      allDocType.map((file, ind) => {
                        return (
                          <div className="input_upload_file" key={ind}>
                            <p className="m-0">
                              {file.documentName}
                              <span className="text-danger">
                                {file.isRequired && "*"}
                              </span>
                            </p>
                            <p
                              className="tooltips-effect"
                              style={{ fontSize: "12px" }}
                            >
                              Desc: {file.description.slice(0, 30)}
                              {file.description.length > 30 ? "..." : ""}
                              <span className="tooltiptext">
                                Desc: {file.description}
                              </span>
                            </p>
                            <label className="my-2">
                              <input
                                className="input"
                                // name={file.id}
                                type="file"
                                onChange={(e) => kyMbrImg(e, ind)}
                              />
                              <div className="wrapper_upload_div">
                                <span className="upload_div1" id="image_">
                                  {file?.file?.name
                                    ? file?.file?.name
                                    : `file name: ${file?.file || ""}`}
                                </span>
                                <span className="Upload-file">+ Upload</span>
                              </div>
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <div className="input_upload_file">
                        <p className="m-0">
                          <span className="text-danger">
                            No Documents assigned by admin
                          </span>
                        </p>
                      </div>
                    )}
                    <div className="form_field form_fill2">
                      <button
                        className="custom_btn mx-3"
                        onClick={(e) => {
                          e.preventDefault();
                          setEnableDocumentSection(false);
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="custom_btn"
                        onClick={finalConsultantSubmit}
                        ref={submitButtonElement}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </>
            )}
          </Modal>
        </div>
      </section>
    </>
  );
}

export default RenewRegistration;
