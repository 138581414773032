import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import Logo from "../../assets/image/logo12.png";
import { FaEye, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { Url } from "../../config/config";
import useButtonLoader from "../../hooks/useButtonLoader";

function Login() {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Log In",
    "Please wait..."
  );

  //***********Handel Form Validation************ */
  const handleValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!email) {
      error.emailError = "Please enter valid email Id";
      formIsValid = false;
    }

    if (!password) {
      error.passwordError = "Please enter password";
      formIsValid = false;
    }
    setErrors(error);
    return formIsValid;
  };
  //**********Handel Login************* */
  const handelLogin = async (e) => {
    try {
      e.preventDefault();
      if (handleValidation()) {
        setSubmitLoading(true);
        const res = await axios({
          url: `${Url}/login`,
          method: "POST",
          data: {
            email,
            password,
          },
        });
        setSubmitLoading(false);
        if (res.data.status === 200) {
          localStorage.setItem(
            "userLogin",
            JSON.stringify({
              token: res.data.data.accessToken,
              name: res.data.data.name,
              email: res.data.data.email,
              role: res.data.data.role,
              companyId: res.data.data.companyId,
            })
          );

          toast.success(res.data.message);
          res.data.data.role === "Consultant"
            ? history("/registration")
            : history("/");
        } else if (res.data.status === 422) {
          setSubmitLoading(false);

          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
      toast.error("Something wents wrong, please try again");
    }
  };

  //*********Password toggle hide show******** */
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <section className="wrapper_log_in_page">
      <title>Praans | Login</title>
      <div className="log_in_page">
        <div className="text_governance">
          <div className="text_governance_flow">
            <div className="button_wrap">
              <span>Governance</span>
              <span>Risk</span>
            </div>
            <div className="button_wrap">
              <span>Compliance</span>
            </div>
          </div>
          <div className="company_bottom_text">
            <figure class="text-center mb-0">
              <blockquote class="blockquote">
                <p class="pb-1">
                  <FaQuoteLeft fontSize={14} className="mb-3" />
                  <span class="lead font-italic mx-2 py-2">
                    If you ignore invest in risk management tracking, your
                    business
                    <br />
                    is at a live volcano. Be ready for any time eruption
                  </span>
                  <FaQuoteRight fontSize={14} className="mt-2" />
                </p>
              </blockquote>
              {/* <figcaption class="blockquote-footer text-light mb-0">
                Garry Cohn
              </figcaption> */}
            </figure>
            <div className="mt-4">
              <p>Copyright Praans Compliance 2023</p>
            </div>
          </div>
        </div>
        <div className="log_in_form">
          <div className="praans_logo">
            <figure className="image_set">
              <img src={Logo} alt="praans" />
            </figure>
            <div className="form_wrapper">
              <form>
                <h3>Login</h3>
                <div className="form_field">
                  <input
                    className="input"
                    type="text"
                    placeholder="Enter your e-mail"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span style={{ color: "red" }} className="error">
                    {errors.emailError}
                  </span>
                </div>
                <div className="form_field custom_position_eye">
                  <input
                    className="input"
                    type={passwordType}
                    placeholder="Password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span style={{ color: "red" }} className="error">
                    {errors.passwordError}
                  </span>
                  {/* <span className="fa_eye">
                    <FaEye />
                  </span> */}
                  <span
                    className="passwordHideShow"
                    onClick={(e) => {
                      togglePassword(e);
                    }}
                  >
                    {passwordType === "password" ? (
                      <i className="fa_eye">
                        <FaEyeSlash />
                      </i>
                    ) : (
                      <i className="fa_eye">
                        <FaEye />
                      </i>
                    )}
                  </span>
                </div>
                <div className="form_fields">
                  {/* <a href="/forgot-password">Forgot password?</a> */}
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
                <div className="button_wrap">
                  <button
                    className="custom_button"
                    onClick={(e) => {
                      handelLogin(e);
                    }}
                    ref={submitButtonElement}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
