import React, { useState } from "react";
import "./Library.css";
import Header from "../header/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";
import { BsDownload } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import { useRef } from "react";
import { FaEye } from "react-icons/fa";
import { FaFile } from "react-icons/fa";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FiDownload } from "react-icons/fi";

function Library() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [value, setValue] = useState(0);
  const handleShow = (value) => {
    setValue(value);
    setShow(true);
  };

  //

  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const ref = useRef();

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_library">
        <div className="container">
          <div className="wrap_viewacts">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              //   className="mb-3"
            >
              <Tab eventKey="home" title="Acts">
                <div className="wrapper_custom_search">
                  <div className="custom_search remarks_tempo">
                    <div className="form_tempo">
                      <Select
                        options={optionList}
                        placeholder="Search by act name, rule name etc…"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        // isMulti
                      />
                    </div>
                    <div className="form_field_wrap">
                      <div className="form_field">
                        <select className="input">
                          <option>Select State</option>
                          <option>Uttar Pradesh</option>
                          <option>Delhi</option>
                        </select>
                      </div>
                      <div className="form_field">
                        <button className="download-excel">
                          <span>
                            <BsDownload />
                          </span>
                          Download excel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data_table">
                  <Table striped bordered hover>
                    <thead>
                      <tr className="custom_tr">
                        <th>S.N0.</th>
                        <th>ACT NAME</th>
                        <th>STATE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>2</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>3</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>4</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>5</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>6</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>7</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Rules">
                <div className="wrapper_custom_search">
                  <div className="custom_search remarks_tempo">
                    <div className="form_tempo">
                      <Select
                        options={optionList}
                        placeholder="Search by act name, rule name etc…"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        // isMulti
                      />
                    </div>
                    <div className="form_field_wrap">
                      <div className="form_field">
                        <select className="input">
                          <option>Select State</option>
                          <option>Uttar Pradesh</option>
                          <option>Delhi</option>
                        </select>
                      </div>
                      <div className="form_field">
                        <button className="download-excel">
                          <span>
                            <BsDownload />
                          </span>
                          Download excel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data_table">
                  <Table striped bordered hover>
                    <thead>
                      <tr className="custom_tr">
                        <th>S.N0.</th>
                        <th>ACT NAME</th>
                        <th>STATE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>2</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>3</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>4</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>5</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>6</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>7</td>
                        <td>Section (11B)</td>
                        <td>U.P.</td>
                        <td>
                          <button>
                            <span>
                              <FiDownload />
                            </span>
                            Download
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="contact" title="Notifications">
                <div className="wrapper_custom_search">
                  <div className="custom_search remarks_tempo">
                    <div className="form_tempo">
                      <Select
                        options={optionList}
                        placeholder="Search by act name, rule name etc…"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        // isMulti
                      />
                    </div>
                    <div className="form_field_wrap">
                      <div className="form_field">
                        <select className="input">
                          <option>Select State</option>
                          <option>Uttar Pradesh</option>
                          <option>Delhi</option>
                        </select>
                      </div>
                      <div className="form_field">
                        <input
                          className="input"
                          placeholder="Filter by date"
                          type="text"
                          ref={ref}
                          onFocus={() => (ref.current.type = "date")}
                          onBlur={() => (ref.current.type = "text")}
                        />
                      </div>

                      <div className="form_field">
                        <button className="download-excel">
                          <span>
                            <BsDownload />
                          </span>
                          Download excel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data_table">
                  <Table striped bordered hover>
                    <thead>
                      <tr className="custom_tr">
                        <th>NOTIFICATION NO.</th>
                        <th>NOTIFICATION TITTLE</th>
                        <th>NOTIFICATION DATE</th>
                        <th>EFFECTIVE DATE</th>
                        <th>STATE</th>
                        <th>LAW AREA</th>
                        <th>ACT</th>
                        <th>ATTACHMENT</th>
                        <th>IMPACT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr className="cstm_tr">
                        <td>1</td>
                        <td>Notification Name1212</td>
                        <td>22/02/2022</td>
                        <td>12/02/2023</td>
                        <td>U.P.</td>
                        <td>Law Area 1231</td>
                        <td>Section A</td>
                        <td>
                          <Link to="">
                            <span className="icon">
                              <FaFile />
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to="" onClick={handleShow}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Tab>
            </Tabs>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design modal_cstm_design2 modal_cstm_design_6"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="modal_after_before">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="custom_col_modl">
                      <h6>Before</h6>
                      <div className="content_before_txt">
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores
                          et ea rebum. Stet clita kasd gubergren, no sea
                          takimata sanctus est Lorem ipsum dolor sit
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="custom_col_modl">
                      <h6>After</h6>
                      <div className="content_before_txt">
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores
                          et ea rebum. Stet clita kasd gubergren, no sea
                          takimata sanctus est Lorem ipsum dolor sit
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  );
}

export default Library;
