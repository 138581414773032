import React from "react";
import { Link } from "react-router-dom";
import "./Notices.css";
import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";

function NoticeDetails() {
  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_add_task wrapper_Notice_details">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/manager/notices">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Noticed details</h5>
              </div>
            </div>
            <div className="wrap_row_cstm">
              <div className="row">
                <div className="col-lg-8 col-md-4">
                  <div className="col_cstm">
                    <div className="ul_list">
                      <ul type="none">
                        <li>
                          <span>Company name</span>
                          <b>Gourav kapoor &amp; sons</b>
                        </li>
                        <li>
                          <span>Court name</span>
                          <b>Kadkaddoma court</b>
                        </li>
                        <li>
                          <span>Party name1</span>
                          <b>Arrant singh</b>
                        </li>
                        <li>
                          <span>Hearing date</span>
                          <b>22/02/2022</b>
                        </li>
                        <li>
                          <span>Liability</span>
                          <b>Liability</b>
                        </li>
                        <li>
                          <span>Authorized Represntative</span>
                          <b>Amit Sharma</b>
                        </li>
                        <li>
                          <span>Manager Email</span>
                          <b>prateek@gmail.com</b>
                        </li>
                      </ul>
                    </div>
                    <div className="ul_list">
                      <ul type="none">
                        <li>
                          <span>Location</span>
                          <b>Noida</b>
                        </li>
                        <li>
                          <span>Council Name</span>
                          <b>Bharadwaj Shah</b>
                        </li>
                        <li>
                          <span>Party name2</span>
                          <b>Gourav kapoor</b>
                        </li>
                        <li>
                          <span>Next Hearing date</span>
                          <b>12/03/2022</b>
                        </li>
                        <li>
                          <span>Status</span>
                          <b>Hold</b>
                        </li>
                        <li>
                          <span>Executor Email</span>
                          <b>alok@gmail.com</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col_Hearing">
                    <div className="ul_listing">
                      <ul type="none">
                        <li>
                          <span>Hearing date</span>
                          <b>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="col_cstm2">
                    <div className="data_table">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr className="custom_tr">
                            <th>S.No.</th>
                            <th>File Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="cstm_tr">
                            <td>1.</td>
                            <td>Section (11B)</td>
                            <td>
                              <a href="">
                                <span className="icon">
                                  <FaEye />
                                </span>
                              </a>
                              <span className="Bs_download">
                                <FiDownload />
                              </span>
                            </td>
                          </tr>
                          <tr className="cstm_tr">
                            <td>2.</td>
                            <td>Section (11B)</td>
                            <td>
                              <a href="">
                                <span className="icon">
                                  <FaEye />
                                </span>
                              </a>
                              <span className="Bs_download">
                                <FiDownload />
                              </span>
                            </td>
                          </tr>
                          <tr className="cstm_tr">
                            <td>3.</td>
                            <td>Section (11B)</td>
                            <td>
                              <a href="">
                                <span className="icon">
                                  <FaEye />
                                </span>
                              </a>
                              <span className="Bs_download">
                                <FiDownload />
                              </span>
                            </td>
                          </tr>
                          <tr className="cstm_tr">
                            <td>4.</td>
                            <td>Section (11B)</td>
                            <td>
                              <a href="">
                                <span className="icon">
                                  <FaEye />
                                </span>
                              </a>
                              <span className="Bs_download">
                                <FiDownload />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NoticeDetails;
