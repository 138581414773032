import React from "react";

import "./Compliance.css";
import Header from "../header/Header";

function Remarks() {
  //   const [selectedOptions, setSelectedOptions] = useState();
  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_complaince_detail_page remarks_page">
      <Header />
      <div className="container">
        <div className="Managercompliance_details">
          <h4>Remarks</h4>
          <p>
            "Very well, Sir Francis," replied Mr. Fogg; "if he had been caught
            he would have been condemned and punished, and then would have
            quietly returned to Europe. I don't see how this affair could have
            delayed his master."
          </p>
          <p>
            The conversation fell again. During the night the train left the
            mountains behind, and passed Nassik, and the next day proceeded over
            the flat, well-cultivated country of the Khandeish, with its
            straggling villages, above which rose the minarets of the pagodas.
          </p>
          <p>
            Jules Verne was a French author who pioneered the genre of science
            fiction in the late nineteenth and early twentieth century. Follow
            him on Twitter.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Remarks;
