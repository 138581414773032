import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Manage.css";
import { IoIosArrowBack } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { companyData } from "../../../controller/ComplianceController";
import useButtonLoader from "../../../hooks/useButtonLoader";
import {
  addTask,
  executerByCompanyId,
  managerByCompanyId,
  singleTaskData,
  updateTask,
} from "../../../controller/ManageController";
import CreatableSelect from "react-select/creatable";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../Layout/Header";
import moment from "moment";

function EditTask() {
  const [taskData, setTaskData] = useState({
    companyId: "",
    description: "",
    taskName: "",
    startDate: "",
    dueDate: "",
    periodicity: "",
    managerEmail: "",
    file: "",
  });

  const [files, setFiles] = useState([""]);
  const { id } = useParams();

  const navigate = useNavigate();

  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  // files handler
  const kyMbrImg = (e, ind) => {
    const image = [...files];
    image[ind] = e.target.files[0];
    setFiles(image);
    // document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  const [selectedExecuter, setSelectedExecuter] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);

  const [getCompany, setCompany] = useState([]);

  const getCompanyList = async () => {
    var res = await companyData();
    if (res.status === 200) {
      var isArray = Array.isArray(res.data);
      var arr = [];
      if (isArray === false) {
        arr.push(res.data);
      } else {
        arr = res.data;
      }
      setCompany(arr);
    } else {
      toast.error(res.message);
    }
  };

  // get executer list by company id
  const [executerList, setExecuterList] = useState([]);

  const getExecuterList = async (companyId = taskData.companyId) => {
    if (companyId) {
      var res = await executerByCompanyId(companyId);
      if (res.status === 200) {
        setExecuterList(res.data);
      } else {
        toast.error(res.message);
      }
    } else {
      setExecuterList([]);
    }
  };

  // get manager list by company id
  const [managerList, setManagerList] = useState([]);

  const getManagerList = async (companyId = taskData.companyId) => {
    if (companyId) {
      var res = await managerByCompanyId(companyId);
      if (res.status === 200) {
        setManagerList(res.data);
      } else {
        toast.error(res.message);
      }
    } else {
      setManagerList([]);
    }
  };

  // update task handler
  const updateTaskHandler = async () => {
    try {
      if (!taskData.companyId) {
        toast.error("Please Select Company");
      } else if (!taskData.description) {
        toast.error("Please Enter Description");
      } else if (!taskData.taskName) {
        toast.error("Please Enter Task Name");
      } else if (!taskData.periodicity) {
        toast.error("Please Select Periodicity");
      } else if (!taskData.startDate) {
        toast.error("Please Select Start Date");
      } else if (taskData.periodicity !== "OneTimeOnly" && !taskData.dueDate) {
        toast.error("Please Select Due Date");
      } 
      // else if (selectedExecuter.length <= 0) {
      //   toast.error("Please Select Executor");
      // } 
      // else if (selectedManager.length <= 0) {
      //   toast.error("Please Select Manager");
      // } 
      else {
        setSubmitLoading(true);
        const oldImages = files.filter((data) => !data?.name);

        const newImages = files.filter((data) => data?.name);

        let formData = new FormData();
        formData.append("manageId", id);
        formData.append("companyId", taskData.companyId);
        formData.append("description", taskData.description);
        formData.append("taskName", taskData.taskName);
        formData.append(
          "startDate",
          moment(taskData.startDate).format("YYYY-MM-DD")
        );
        formData.append(
          "dueDate",
          moment(taskData.dueDate).format("YYYY-MM-DD")
        );
        formData.append(
          "notificationTime",
          +moment(taskData.notificationTime).format("HH:mm").split(":")[0]
        );
        formData.append("periodicity", taskData.periodicity);
        selectedManager.forEach((data) => {
          formData.append("managerEmail", data.value);
        });
        selectedExecuter.forEach((data) => {
          formData.append("executerEmail", data.value);
        });
        if (newImages.length > 0) {
          for (let i = 0; i < newImages.length; i++) {
            formData.append("file", newImages[i]);
          }
        }
        if (oldImages.length > 0) {
          for (let i = 0; i < oldImages.length; i++) {
            formData.append("oldImage", oldImages[i]);
          }
        }
        var res = await updateTask(formData);
        if (res.status === 200) {
          toast.success("Success");
          setSubmitLoading(false);
          navigate("/manage");
        } else {
          setSubmitLoading(false);
          toast.error(res.message);
        }
      }
    } catch (err) {
      setSubmitLoading(false);
      toast.error(err);
    }
  };

  // get single manage details
  const getSingleManageDetails = async (id) => {
    try {
      if (id) {
        var res = await singleTaskData(id);
        if (res.status === 200) {
          const fullYear = new Date(res.data[0].dueDate).getFullYear();
          const month = new Date(res.data[0].dueDate).getMonth();
          const date = new Date(res.data[0].dueDate).getDate();
          const time = res.data[0].notificationTime;

          setTaskData({
            ...res.data[0],
            startDate: new Date(res.data[0].startDate),
            dueDate: new Date(res.data[0].dueDate),
            notificationTime: new Date(fullYear, month, date, time),
          });
          setSelectedExecuter(
            res.data[0].executerEmail.map(({ email }) => {
              return { value: email, label: email };
            })
          );
          setSelectedManager(
            res.data[0].managerEmail.map(({ email }) => {
              return { value: email, label: email };
            })
          );
          setFiles(
            res.data[0].documentArray.length > 0
              ? res.data[0].documentArray.map((data) => data.images)
              : [""]
          );
        } else {
          toast.error(res.message);
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    getCompanyList();
    getSingleManageDetails(id);
  }, []);

  useEffect(() => {
    if (taskData.companyId) {
      getManagerList(taskData.companyId);
      getExecuterList(taskData.companyId);
    }
  }, [taskData.companyId]);

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_registration_detail wrapper_noticed_details wrapper_add_task">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/manage">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Update Task</h5>
              </div>
            </div>
            <form>
              <div className="content_wrapper_acts">
                <div className="noticedetails_form_wrapper">
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <select
                        className="input"
                        value={taskData.companyId}
                        onChange={(e) =>
                          setTaskData({
                            ...taskData,
                            companyId: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Company</option>
                        {getCompany && getCompany.length > 0
                          ? getCompany.map((value, i) => (
                              <option value={value.companyId} key={i}>
                                {value.companyName}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="form_field">
                      <input
                        className="input"
                        type="text"
                        value={taskData.taskName}
                        onChange={(e) =>
                          setTaskData({
                            ...taskData,
                            taskName: e.target.value,
                          })
                        }
                        placeholder="Task Name"
                      />
                    </div>
                    <div className="form_field">
                      <DatePicker
                        selected={taskData.startDate}
                        minDate={new Date()}
                        className="input input1"
                        onChange={(date) =>
                          setTaskData({
                            ...taskData,
                            startDate: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        startDate={taskData.startDate}
                        placeholderText="Enter Start Date"
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                      />
                    </div>

                    <div className="form_field">
                      <CreatableSelect
                        className="w-100"
                        options={executerList.map(({ email }) => {
                          return { value: email, label: email };
                        })}
                        placeholder="Executor"
                        value={selectedExecuter}
                        onChange={(data) => setSelectedExecuter(data)}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                    <div className="form_field">
                      <DatePicker
                        className="input input1"
                        selected={taskData.notificationTime}
                        minDate={new Date()}
                        onChange={(date) =>
                          setTaskData({
                            ...taskData,
                            notificationTime: date,
                          })
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        placeholderText="Periodicity Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        className="input"
                        type="text"
                        value={taskData.description}
                        onChange={(e) =>
                          setTaskData({
                            ...taskData,
                            description: e.target.value,
                          })
                        }
                        placeholder="Description"
                      />
                    </div>
                    <div className="form_field">
                      <select
                        className="input"
                        value={taskData.periodicity}
                        onChange={(e) =>
                          setTaskData({
                            ...taskData,
                            periodicity: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Periodicity</option>
                        {[
                          { name: "weekly", value: "weekly" },
                          { name: "monthly", value: "monthly" },
                          { name: "quarterly", value: "quarterly" },
                          { name: "annually", value: "annually" },
                          { name: "daily", value: "daily" },
                          { name: "one time only", value: "OneTimeOnly" },
                        ].map((data) => {
                          return (
                            <option key={data.value} value={data.value}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form_field">
                      <DatePicker
                        disabled={taskData.periodicity === "OneTimeOnly"}
                        selected={taskData.dueDate}
                        className="input input1"
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        showMonthDropdown
                        useShortMonthInDropdown
                        scrollableYearDropdown
                        onChange={(date) =>
                          setTaskData({
                            ...taskData,
                            dueDate: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        startDate={taskData.dueDate}
                        minDate={taskData.startDate}
                        placeholderText="Enter Due Date"
                      />
                    </div>
                    <div className="form_field">
                      <CreatableSelect
                        className="w-100"
                        options={managerList.map(({ email }) => {
                          return { value: email, label: email };
                        })}
                        placeholder="Manager"
                        value={selectedManager}
                        onChange={(data) => setSelectedManager(data)}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="mx-auto">
                    <div className="attachment_file">
                      <h5>Add Attachment</h5>
                      {files &&
                        files.map((file, ind) => {
                          return (
                            <div className="input_upload_file" key={ind}>
                              <label className="my-2">
                                <input
                                  className="input"
                                  // name={file.id}
                                  type="file"
                                  onChange={(e) => kyMbrImg(e, ind)}
                                />
                                <div className="wrapper_upload_div">
                                  <span className="upload_div1" id="image_">
                                    {file?.name
                                      ? file?.name
                                        ? file?.name
                                        : "file name"
                                      : file || "file name"}
                                  </span>
                                  <span className="Upload-file">+ Upload</span>
                                </div>
                              </label>
                              {ind === 0 ? (
                                <span
                                  className="add_more_append"
                                  onClick={() => setFiles([...files, ""])}
                                >
                                  + Add more
                                </span>
                              ) : (
                                <span
                                  className="add_more_append"
                                  onClick={(e) =>
                                    setFiles(
                                      files.filter(
                                        (file, index) => index !== ind
                                      )
                                    )
                                  }
                                >
                                  Remove
                                </span>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="applicable_not">
                <Button
                  // disabled={getRadioOne}
                  className="custom_btn tempo_button"
                  variant="primary"
                  onClick={updateTaskHandler}
                  ref={submitButtonElement}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditTask;
