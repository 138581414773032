import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./Login.css";
import { Url } from "../../config/config";
import Logo from "../../assets/image/logo12.png";
import useButtonLoader from "../../hooks/useButtonLoader";

function Forgot() {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setError] = useState({ email: "" });
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      errors["email"] = "Please enter registerd Email Id";
    }
    setError(errors);
    return formIsValid;
  };

  const handelForgotPassword = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setSubmitLoading(true);
      const res = await axios({
        url: `${Url}/sendLink`,
        method: "POST",
        data: {
          email,
        },
      });
      setSubmitLoading(false);
      if (res.data.status === 200) {
        setEmail("");
        localStorage.setItem("emailId", email);
        history("/succesful");
        toast.success(
          "Please check your email inbox. Password reset instructions sent to the associated email address."
        );
      } else if (res.data.status === 422) {
        toast.error(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    }
  };
  return (
    <section className="wrapper_log_in_page">
      <title>Praans | Forgot Password</title>
      <div className="log_in_page">
        <div className="text_governance">
          <div className="text_governance_flow">
            <div className="button_wrap">
              <span>Governance</span>
              <span>Risk</span>
            </div>
            <div className="button_wrap">
              <span>Compliance</span>
            </div>
          </div>
          <div className="company_bottom_text">
            <p>A company without any rule is no company at all</p>
          </div>
        </div>
        <div className="log_in_form">
          <div className="praans_logo">
            <figure className="image_set">
              <img src={Logo} alt="praans" />
            </figure>
            <div className="form_wrapper">
              <form>
                <h3>Forgot password?</h3>
                <span>
                  We will sent you a verification link on your registered email
                  for reset the password.
                </span>
                <div className="form_field">
                  <input
                    className="input"
                    type="text"
                    placeholder="Enter your e-mail"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="mt-2" style={{ color: "red" }}>
                    {errors.email}
                  </span>
                </div>
                <div className="button_wrap">
                  <button
                    className="custom_button"
                    onClick={(e) => {
                      handelForgotPassword(e);
                    }}
                    ref={submitButtonElement}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Forgot;
