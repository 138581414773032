import React from 'react'

const Tooltip=({limit=0,children})=>{
    return <p className="tooltips-effect">
    {children.slice(0, limit)}
    {children.length > limit ? "..." : ""}
    {children.length > limit ? (
      <>
        <span className="tooltiptext">
          {children}
        </span>
      </>
    ) : (
      ""
    )}
  </p>
} 

export default Tooltip