import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./Notices.css";
import { IoIosArrowBack } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import {
  letigationDetailById,
  letigationDetailByLitigationId,
} from "../../../controller/LetigationController";
import { toast } from "react-toastify";
import Header from "../../Layout/Header";
import { dateFormat } from "../../../utils";
import MainListing from "./section/MainListing";
import CommonListing from "./section/CommonListing";


function MultiNoticeDetails() {
  useEffect(() => {
    letigationDetail();
  }, []);

  const { id } = useParams();

  const [getLetigationDetail, setLetigationDetail] = useState([]);

  const [getNewDocs, setNewDocs] = useState([]);
  const letigationDetail = async () => {
    var res = await letigationDetailByLitigationId(id);

    if (res.status === 200) {
      console.log(res);
      setLetigationDetail(res.data);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_add_task wrapper_Notice_details">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/notices">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                {/* <h5>Litigation details</h5> */}
              </div>
            </div>
            <div className="wrap_row_cstm">
              {getLetigationDetail &&
                getLetigationDetail.map((litigation, i) => {
                  return (
                    <React.Fragment key={i}>
                      {i === 0 ? (
                        <MainListing litigation={litigation} />
                      ) : (
                        <CommonListing litigation={litigation} />
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MultiNoticeDetails;
