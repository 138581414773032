import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Notices.css";
import Header from "../../Layout/Header";
// import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";
import Select from "react-select";
import { useRef } from "react";
import {
  getCompany,
  getHead,
  getLocationLists,
  getManager,
  storeExecutorLetigation,
} from "../../../controller/LetigationController";
import { toast } from "react-toastify";
import { FaTrash } from "react-icons/fa";
import useButtonLoader from "../../../hooks/useButtonLoader";
import DatePicker from "react-datepicker";
import moment from "moment";

function AddNotices() {
  var navigate = useNavigate();
  const [fileName, SetFileName] = useState();
  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedHeads, setSelectedHeads] = useState(null);

  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Save",
    "Please wait..."
  );

  const [getCompanies, setCompany] = useState([]);
  const getCompanyList = async () => {
    var res = await getCompany();
    if (res.status == 200) {
      setCompany(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const [getManagers, setManagers] = useState([]);
  const getManagerList = async (id = companyId) => {
    console.log(companyId);
    var data = {
      companyId: id,
    };

    var res = await getManager(data);
    console.log(res, "response");

    if (res.status == 200) {
      setManagers(
        res.data.map((data) => {
          return {
            label: data.email,
            value: data.email,
          };
        })
      );
    } else {
      toast.error(res.message);
    }
  };

  const [getHeads, setHeads] = useState([]);
  const getHeadList = async (id = companyId) => {
    var data = {
      companyId: id,
    };

    var res = await getHead(data);

    if (res.status == 200) {
      setHeads(
        res.data.map((data) => {
          return {
            label: data.email,
            value: data.email,
          };
        })
      );
    } else {
      toast.error(res.message);
    }
  };

  const [getLocations, setLocations] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const getLocationList = async (id = companyId) => {
    var data = {
      companyId: id,
    };
    var res = await getLocationLists(data);
    if (res.status == 200) {
      setLocations(res.data);
    } else {
      toast.error(res.message);
    }
  };

  // const kyMbrImg = (e, i) => {
  //   // SetFileName(e.target.files[0].name);
  //   document.getElementById("image_").innerHTML = e.target.files[0].name;
  // };

  const [getLocation, setLocation] = useState("");
  const [getCourt, setCourt] = useState("");
  const [getPartyNameOne, setPartyNameOne] = useState("");
  const [getPartyNameTwo, setPartyNameTwo] = useState("");
  const [getHearingDate, setHearingDate] = useState("");
  const [getLiability, setLiability] = useState("");
  const [getLetigtionStatus, setLetigtionStatus] = useState("open");
  const [getAuthorizedRepresentative, setAuthorizedRepresentative] =
    useState("");
  const [getManagerEmail, setManagerEmail] = useState([]);
  const [getHeadEmail, setHeadEmail] = useState([]);
  const [getMinutesofHearingRemark, setMinutesofHearingRemark] = useState("");
  const handleInput = (name, value) => {
    if (name == "company") {
      setCompanyId(value);
      getManagerList(value);
      getHeadList(value);
      getLocationList(value);
    } else if (name == "location") {
      setLocation(value);
    } else if (name == "court") {
      setCourt(value);
    } else if (name == "partyNameOne") {
      setPartyNameOne(value);
    } else if (name == "partyNameTwo") {
      setPartyNameTwo(value);
    } else if (name == "hearingDate") {
      setHearingDate(value);
    } else if (name == "liability") {
      setLiability(value);
    } else if (name == "letigtionStatus") {
      setLetigtionStatus(value);
    } else if (name == "authorizedRepresentative") {
      setAuthorizedRepresentative(value);
    } else if (name == "managerEmail") {
      setManagerEmail(value);
    } else if (name == "headEmail") {
      setHeadEmail(value);
    } else if (name == "minutesofHearingRemark") {
      setMinutesofHearingRemark(value);
    }
  };

  //*********************options******************
  // const options = [
  //   { value: "", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];

  // ************handle validation*****************

  const handleValidation = () => {
    let isFormValid = true;
    if (!companyId) {
      toast.error("Please Select Company");
      isFormValid = false;
    } else if (!getLocation) {
      toast.error("Please Select Location");
      isFormValid = false;
    } else if (!getCourt) {
      toast.error("Please Enter Court/Authority");
      isFormValid = false;
    } else if (!getPartyNameOne) {
      toast.error("Please Enter First Party Name");
      isFormValid = false;
    } else if (!getPartyNameTwo) {
      toast.error("Please Enter Second Party Name");
      isFormValid = false;
    } else if (!getHearingDate) {
      toast.error("Please Enter Hearing Date");
      isFormValid = false;
    } else if (!getLetigtionStatus) {
      toast.error("Please Select Litigation Status");
      isFormValid = false;
    }
    // else if (selectedManager.length===0) {
    //   toast.error("Please Enter Manager Email");
    //   isFormValid = false;
    // } else if (selectedHeads.length===0) {
    //   toast.error("Please Enter Head Email");
    //   isFormValid = false;
    // }
    else if (!getAuthorizedRepresentative) {
      toast.error("Please Enter Authorized Representative");
      isFormValid = false;
    } else if (!getMinutesofHearingRemark) {
      toast.error("Please Enter Minutes of Hearing");
      isFormValid = false;
    } else {
      isFormValid = true;
    }
    return isFormValid;
  };

  const handleFinalSubmit = async () => {
    if (handleValidation()) {
      setSubmitLoading(true);
      var data = {
        companyId: companyId,
        getLocation: getLocation,
        getCourt: getCourt,
        getPartyNameOne: getPartyNameOne,
        getPartyNameTwo: getPartyNameTwo,
        getHearingDate: getHearingDate,
        getLiability: getLiability,
        getLetigtionStatus: getLetigtionStatus,
        getManagerEmail: selectedManager.map((data) => data.value),
        getHeadEmail: selectedHeads.map((data) => data.value),
        getAuthorizedRepresentative: getAuthorizedRepresentative,
        getMinutesofHearingRemark: getMinutesofHearingRemark,
        file: getDoc,
      };

      let formData = new FormData();
      formData.append("companyId", companyId);
      formData.append("location", getLocation);
      formData.append("court", getCourt);
      formData.append("partyNameOne", getPartyNameOne);
      formData.append("partyNameTwo", getPartyNameTwo);
      formData.append(
        "hearingDate",
        moment(getHearingDate).format("YYYY-MM-DD")
      );
      formData.append("liability", getLiability);
      formData.append("letigtionStatus", getLetigtionStatus);
      selectedManager.forEach((element) => {
        formData.append("managerEmail", element.value);
      });
      selectedHeads.forEach((element) => {
        formData.append("headEmail", element.value);
      });
      formData.append("authorizedRepresentative", getAuthorizedRepresentative);
      formData.append("minutesofHearingRemark", getMinutesofHearingRemark);

      if (getDoc.length > 0) {
        for (let i = 0; i < getDoc.length; i++) {
          formData.append("file", getDoc[i]);
        }
      }

      var res = await storeExecutorLetigation(formData);

      if (res.status == 200) {
        toast.success("Success");
        setSubmitLoading(false);
        navigate("/notices");
      } else {
        setSubmitLoading(false);
        toast.error(res.message);
      }
    }
  };

  const setAddMoreDoc = (e, index) => {
    const image = [...getDoc];
    const file = e.target.files[0].type;
    if (
      file === "application/pdf" ||
      file === "image/png" ||
      file === "image/jpeg" ||
      file ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      image[index] = e.target.files[0];
      SetFileName(e.target.files[0].name);
      setDoc(image);
    } else {
      toast.error("Please upload valid file");
    }
  };
  const [getDoc, setDoc] = useState([""]);
  const handleAddMoreDoc = () => {
    setDoc([...getDoc, ""]);
  };

  const handleRemoveMoreDoc = (e, index) => {
    e.preventDefault();
    const docs = [...getDoc];
    docs.splice(index, 1);
    setDoc(docs);
  };

  const ref = useRef();

  useEffect(() => {
    getCompanyList();
  }, []);

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_add_notices  wrapper_add_task">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling d-flex">
                <Link to="/notices">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
                <h5 className="mx-2">Add Notice</h5>
              </div>
              <div className="form_field"></div>
            </div>
            <form>
              <div className="content_wrapper_acts">
                <div className="left_side_form">
                  <div className="form_field_wrap">
                    <div className="form_field">
                      {/* <input
                        type="text"
                        className="input"
                        placeholder="Company name"
                      /> */}
                      <select
                        className="input"
                        onChange={(e) => handleInput("company", e.target.value)}
                      >
                        <option value="">Select company</option>
                        {getCompanies && getCompanies.length > 0
                          ? getCompanies.map((value, i) => (
                              <option value={value._id}>
                                {value.companyName}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="form_field">
                      {/* <input
                        type="text"
                        className="input"
                        placeholder="Location"
                      /> */}
                      <select
                        className="input"
                        onChange={(e) =>
                          handleInput("location", e.target.value)
                        }
                      >
                        <option value="">Select Location</option>
                        {getLocations && getLocations.length > 0
                          ? getLocations.map((value, i) => (
                              <option value={value.location}>
                                {value.location}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        onChange={(e) => handleInput("court", e.target.value)}
                        placeholder="Court/Authority/Council Name"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        onChange={(e) =>
                          handleInput("partyNameOne", e.target.value)
                        }
                        placeholder="Party name1"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        onChange={(e) =>
                          handleInput("partyNameTwo", e.target.value)
                        }
                        placeholder="Party name2"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        onChange={(e) =>
                          handleInput("liability", e.target.value)
                        }
                        placeholder="Liability"
                      />
                    </div>
                    <div className="form_field date-picker">
                      <DatePicker
                        placeholderText="Hearing Date"
                        className="input"
                        selected={getHearingDate}
                        value={getHearingDate}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        onChange={(date) => handleInput("hearingDate", date)}
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      {/* Add Multiple Select  */}

                      {/* <select
                        className="input"
                        onChange={(e) =>
                          handleInput("managerEmail", e.target.value)
                        }
                      >
                        <option value="">Select Manager</option>
                        {getManagers && getManagers.length > 0
                          ? getManagers.map((value, i) => (
                              <option value={value.email}>{value.email}</option>
                            ))
                          : ""}
                      </select> */}
                      <Select
                        value={selectedManager}
                        onChange={setSelectedManager}
                        options={getManagers}
                        isMulti
                        className="w-100"
                      />

                      {/* End Multiple Select  */}
                    </div>

                    <div className="form_field">
                      {/* <select
                        className="input"
                        onChange={(e) =>
                          handleInput("headEmail", e.target.value)
                        }
                      >
                        <option value="">Select HOD</option>
                        {getHeads && getHeads.length > 0
                          ? getHeads.map((value, i) => (
                              <option value={value.email}>{value.email}</option>
                            ))
                          : ""}
                      </select> */}

                      {/* Add Multiple Select  */}
                      <Select
                        value={selectedHeads}
                        onChange={setSelectedHeads}
                        options={getHeads}
                        isMulti
                        className="w-100"
                      />

                      {/* End Multiple Select  */}
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        onChange={(e) =>
                          handleInput(
                            "authorizedRepresentative",
                            e.target.value
                          )
                        }
                        placeholder="Authrozided Represnative"
                      />
                    </div>
                    <div className="form_field">
                      <select
                        className="input"
                        value={getLetigtionStatus}
                        onChange={(e) =>
                          handleInput("letigtionStatus", e.target.value)
                        }
                      >
                        <option value="">Status</option>
                        <option value="open">Open</option>
                        <option value="noActionRequired">
                          No Action Required
                        </option>
                        <option value="close">Close</option>
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <div className="form_field">
                      <textarea
                        className="input"
                        cols="30"
                        placeholder="Minutes of hearing.."
                        rows="5"
                        onChange={(e) =>
                          handleInput("minutesofHearingRemark", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="right_side_form">
                  <div className="heads_text">
                    <h4>Upload Documents</h4>
                  </div>
                  {getDoc && getDoc.length > 0
                    ? getDoc.map((value, i) => (
                        <div className="input_upload_file">
                          <label>
                            <input
                              className="input"
                              type="file"
                              onChange={(e) => setAddMoreDoc(e, i)}
                              accept="image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            />
                            <div className="wrapper_upload_div">
                              <span className="upload_div1" id="image_">
                                file name {value?.name}
                              </span>
                              <span className="Upload-file">+ Upload</span>
                            </div>
                          </label>
                          {i == 0 ? (
                            <span
                              className="add_span_style"
                              onClick={handleAddMoreDoc}
                            >
                              +
                            </span>
                          ) : (
                            <span
                              className="add_span_style delete_span_style"
                              onClick={(e) => handleRemoveMoreDoc(e, i)}
                            >
                              <FaTrash />
                            </span>
                          )}
                        </div>
                      ))
                    : ""}
                </div>

                <div className="applicable_not">
                  <button
                    className="custom_btn2"
                    onClick={handleFinalSubmit}
                    type="button"
                    ref={submitButtonElement}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddNotices;
