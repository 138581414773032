import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Notices.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaEye } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import Header from "../header/Header";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { useRef } from "react";

function Notices() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const ref = useRef();

  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_compliance wrapper_Notices">
      <Header />
      <div className="container">
        <div className="wrapper_cards_consultant">
          <div className="textbar">
            <button className="download-excel">
              <span>
                <BsDownload />
              </span>
              Download excel
            </button>
            <Link to="/manager/notices/add-notices" className="custom_btn2">
              + Add Notices
            </Link>
          </div>
          <div className="cards_consult">
            <div className="cstm_cards_cols">
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>40</h3>
                  <p>Total</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>20</h3>
                  <p>Open</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>10</h3>
                  <p>Closed</p>
                </div>
              </div>
              <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>40</h3>
                  <p>Due of renewable</p>
                </div>
              </div>
            </div>
            <div className="cstm_cards_cols">
              <div className="small-box custom_div_styling">
                <div className="inner">
                  <h3>40</h3>
                  <p>Due Today</p>
                </div>
              </div>
              <div className="small-box custom_div_styling">
                <div className="inner">
                  <h3>40</h3>
                  <p>Upcoming Hearings</p>
                </div>
              </div>
              <div className="small-box custom_div_styling">
                <div className="inner">
                  <h3>40</h3>
                  <p>Pending For Action</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper_custom_search">
            <div className="custom_search">
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>Choose company</option>
                    <option>Select Industry</option>
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>Choose notice</option>
                    <option>Active</option>
                    <option>InActive</option>
                  </select>
                </div>
              </div>
              <div className="form_field_wrap remark_tempo">
                <div className="form_tempo">
                  <Select
                    options={optionList}
                    placeholder="Search by act name, rule name etc…"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    // isMulti
                  />
                </div>
              </div>
              <div className="form_field_wrap data_date">
                <div className="form_field">
                  <input
                    className="input"
                    placeholder="Filter by date"
                    type="text"
                    ref={ref}
                    onFocus={() => (ref.current.type = "date")}
                    onBlur={() => (ref.current.type = "text")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="data_table">
            <Table striped bordered hover>
              <thead>
                <tr className="custom_tr">
                  <th>S.NO.</th>
                  <th>ACT NAME</th>
                  <th>LAW AREA</th>
                  <th>LOCATION</th>
                  <th>NEXT DATE OF HEARING</th>
                  <th>COURT/AUTHORITY</th>
                  <th>LIABILITY</th>
                  <th>STATUS</th>
                  <th>MORE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr className="cstm_tr">
                  <td>1.</td>
                  <td>Act Name1</td>
                  <td>Law Area 1231</td>
                  <td>Noida</td>
                  <td>22/02/2022</td>
                  <td>Kadkaddoma Court</td>
                  <td>Assests</td>
                  <td>Hold</td>
                  <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td>
                  <td>
                    <Link to="/manager/notices/notice-details">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                    <Link to="">
                      <span className="icon">
                        <FaRegEdit />
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr className="cstm_tr">
                  <td>2.</td>
                  <td>Act Name1</td>
                  <td>Law Area 1231</td>
                  <td>Noida</td>
                  <td>22/02/2022</td>
                  <td>Kadkaddoma Court</td>
                  <td>Assests</td>
                  <td>Hold</td>
                  <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td>
                  <td>
                    <Link to="/manager/notices/notice-details">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                    <Link to="">
                      <span className="icon">
                        <FaRegEdit />
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr className="cstm_tr">
                  <td>3.</td>
                  <td>Act Name1</td>
                  <td>Law Area 1231</td>
                  <td>Ghaziabad</td>
                  <td>22/02/2022</td>
                  <td>Kadkaddoma Court</td>
                  <td>Assests</td>
                  <td>Hold</td>
                  <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td>
                  <td>
                    <Link to="/manager/notices/notice-details">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                    <Link to="">
                      <span className="icon">
                        <FaRegEdit />
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr className="cstm_tr">
                  <td>4.</td>
                  <td>Act Name1</td>
                  <td>Law Area 1231</td>
                  <td>Mathura</td>
                  <td>22/02/2022</td>
                  <td>Kadkaddoma Court</td>
                  <td>Assests</td>
                  <td>Hold</td>
                  <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td>
                  <td>
                    <Link to="/manager/notices/notice-details">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                    <Link to="">
                      <span className="icon">
                        <FaRegEdit />
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr className="cstm_tr">
                  <td>5.</td>
                  <td>Act Name1</td>
                  <td>Law Area 1231</td>
                  <td>Meerut</td>
                  <td>22/02/2022</td>
                  <td>Kadkaddoma Court</td>
                  <td>Assests</td>
                  <td>Hold</td>
                  <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td>
                  <td>
                    <Link to="/manager/notices/notice-details">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                    <Link to="">
                      <span className="icon">
                        <FaRegEdit />
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr className="cstm_tr">
                  <td>6.</td>
                  <td>Act Name1</td>
                  <td>Law Area 1231</td>
                  <td>Ghaziabad</td>
                  <td>22/02/2022</td>
                  <td>Kadkaddoma Court</td>
                  <td>Assests</td>
                  <td>Hold</td>
                  <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td>
                  <td>
                    <Link to="/manager/notices/notice-details">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                    <Link to="">
                      <span className="icon">
                        <FaRegEdit />
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr className="cstm_tr">
                  <td>7.</td>
                  <td>Act Name1</td>
                  <td>Law Area 1231</td>
                  <td>Greater Noida</td>
                  <td>22/02/2022</td>
                  <td>Kadkaddoma Court</td>
                  <td>Assests</td>
                  <td>Hold</td>
                  <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td>
                  <td>
                    <Link to="/manager/notices/notice-details">
                      <span className="icon">
                        <FaEye />
                      </span>
                    </Link>
                    <Link to="">
                      <span className="icon">
                        <FaRegEdit />
                      </span>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {/* Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Title></Modal.Title>

            <Modal.Body>
              <div className="wrapper-modal-div">
                <div className="modal_uin">
                  <div className="modal_exce">
                    <p>UIN Number</p>
                    <span>34224232</span>
                  </div>
                  <div className="modal_exce">
                    <p>Executor</p>
                    <span>kapil@gmail.com</span>
                  </div>
                </div>
                <div className="modal_uin modelpopup">
                  <div className="modal_exce">
                    <p>Manager</p>
                    <span>sourabh@gmail.com</span>
                  </div>
                  <div className="modal_exce">
                    <p>Periodicity</p>
                    <span>Annualy</span>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </section>
  );
}

export default Notices;
