import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Manage.css";
import Table from "react-bootstrap/Table";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import { useRef } from "react";
import { FiDownload } from "react-icons/fi";
import Header from "../../Layout/Header";
import { deleteTaskData, taskData } from "../../../controller/ManageController";
import { toast } from "react-toastify";
import NotFound from "../../../components/NotFound/NotFound";
import moment from "moment/moment";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { Modal } from "react-bootstrap";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { dateFormat, isLoggedIn } from "../../../utils";
import Pagination from "react-js-pagination";

function Manage() {
  const ref = useRef();
  const [flag, setFlag] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);

  const [taskList, setTaskList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [search, setSearch] = useState("");
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Yes",
    "Please wait..."
  );

  // date filter
  const [getSlectedDate, setSlectedDate] = useState("");
  const [getSlectedDate2, setSlectedDate2] = useState("");

  const handelDateSelect = (e) => {
    const { name, value } = e.target;
    if (name === "from") {
      setSlectedDate(value);
    } else {
      setSlectedDate2(value);
    }
  };

  // full page loader
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const getTaskList = async (
    find = search,
    from = getSlectedDate,
    to = getSlectedDate2,
    page = activePage
  ) => {
    showLoader();
    const data = {
      search: find,
      from: from,
      to: to,
      page: page,
    };
    var res = await taskData(data);
    if (res.status === 200) {
      setTaskList(res.data.listing);
      setTotal(res.data.total);
      hideLoader();
    } else {
      hideLoader();
      toast.error(res.message);
    }
  };

  // Delete task function

  const deleteTaskHandler = async () => {
    if (selectedId) {
      try {
        setSubmitLoading(true);
        const res = await deleteTaskData(selectedId);
        if (res.status === 200) {
          toast.success(res.message);
          setSubmitLoading(false);
          setDeleteModal(false);
          getTaskList();
        } else {
          toast.error(res.message);
          setSubmitLoading(false);
        }
      } catch (err) {
        toast.error(err.response.data.message);
        setSubmitLoading(false);
      }
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    getTaskList("", "", "", page);
  };

  // reset filter
  const resetFilter = () => {
    setSlectedDate("");
    setSlectedDate2("");
    setSearch("");
    setFlag(true);
  };

  useEffect(() => {
    if (getSlectedDate && getSlectedDate2) {
      getTaskList();
    }
  }, [getSlectedDate, getSlectedDate2]);

  useEffect(() => {
    getTaskList();
  }, []);

  useEffect(() => {
    getTaskList(search);
  }, [search]);

  useEffect(() => {
    getTaskList();
  }, [flag]);

  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_compliance wrapper_manage manage">
      <Header />
      <div className="container">
        <div className="wrapper_cards_consultant">
          <div className="wrapper_custom_search">
            {/* <div className="custom_search"></div> */}
            <div className="custom_search remarks_tempo">
              <div className="form_field_wrap remark_tempo">
                <div className="form_field">
                  <input
                    type="search"
                    className="input"
                    placeholder="search by task name and company.."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className=" data_date d-flex flex-wrap">
                <div className="form_field mx-2">
                  <input
                    className="input"
                    name="from"
                    onChange={handelDateSelect}
                    value={getSlectedDate}
                    placeholder="From Date"
                    type="date"
                  />
                </div>
                <div className="form_field">
                  <input
                    className="input"
                    name="to"
                    onChange={handelDateSelect}
                    value={getSlectedDate2}
                    placeholder="To Date"
                    type="date"
                  />
                </div>
                <div className="form_field mx-2">
                  <button className="download-excel" onClick={resetFilter}>
                    Reset
                  </button>
                </div>

                <div className="textbar">
                  {isLoggedIn().role === "Executor" && (
                    <Link to="/manage/add-task" className="custom_btn2">
                      + Add Task
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="data_table">
            <Table striped bordered hover>
              <thead>
                <tr className="custom_tr">
                  <th>S.NO.</th>
                  <th>COMPANY</th>
                  <th>DESCRIPTION</th>
                  <th>TASK NAME</th>
                  <th>START DATE</th>
                  <th>DUE DATE</th>
                  <th>PERIODICITY</th>
                  <th>VIEW</th>
                  {isLoggedIn().role === "Executor" && <th>ACTION</th>}
                </tr>
              </thead>
              <tbody>
                {taskList && taskList.length > 0 ? (
                  taskList.map(
                    (
                      {
                        _id,
                        company,
                        description,
                        taskName,
                        startDate,
                        dueDate,
                        periodicity,
                        eligibleforEdit,
                      },
                      i
                    ) => (
                      <tr className="cstm_tr" key={i}>
                        <td>{++i + (activePage - 1) * 10}</td>
                        <td>{company}</td>
                        <td>
                          <p className="tooltips-effect">
                            {description?.length > 40
                              ? description?.slice(0, 40) + "..."
                              : description}
                            <span className="tooltiptext text-black">
                              {description}
                            </span>
                          </p>
                        </td>
                        <td>
                          {" "}
                          <p className="tooltips-effect">
                            {taskName?.length > 40
                              ? taskName?.slice(0, 40) + "..."
                              : taskName}
                            <span className="tooltiptext text-black">
                              {taskName}
                            </span>
                          </p>
                        </td>
                        <td>{dateFormat(startDate)}</td>
                        <td>{dateFormat(dueDate)}</td>

                        <td>{periodicity}</td>
                        <td>
                          <Link to={`/manage/view-task/${_id}`}>
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                        <td>
                          {isLoggedIn().role === "Executor" &&
                          eligibleforEdit ? (
                            <>
                              <Link to={`/manage/edit-task/${_id}`}>
                                <span className="icon">
                                  <FaEdit />
                                </span>
                              </Link>
                              <span
                                role={"button"}
                                className=""
                                onClick={() => {
                                  setSelectedId(_id);
                                  setDeleteModal(true);
                                }}
                              >
                                <span className="icon">
                                  <FaTrash />
                                </span>
                              </span>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <NotFound col={11} />
                )}
              </tbody>
            </Table>
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
            <div className="w-100 d-flex justify-content-center">{loader}</div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>
          <h5 className="text-center">Delete Task</h5>
        </Modal.Title>
        <Modal.Body>
          <div className="wrapper-modal-div text-center">
            <p>Are you sure you want to delete task ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={() => setDeleteModal(false)} className="custom_btn2">
            No
          </button>
          <button
            className="custom_btn2"
            ref={submitButtonElement}
            onClick={deleteTaskHandler}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Manage;
