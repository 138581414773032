import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Notices.css";
import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";
import { useRef } from "react";

function AddNotices() {
  const [fileName, SetFileName] = useState();

  const kyMbrImg = (e, i) => {
    // SetFileName(e.target.files[0].name);
    document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  const ref = useRef();

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_add_notices  wrapper_add_task">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/manager/notices">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Add Notice</h5>
              </div>
            </div>
            <form>
              <div className="content_wrapper_acts">
                <div className="left_side_form">
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Company name"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Location"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Court/Authority"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Council Name"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Party name1"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Party name2"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Hearing date"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Next hearing date"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Liability"
                      />
                    </div>
                    <div className="form_field">
                      <select className="input">
                        <option>Status</option>
                        <option>Active</option>
                        <option>InActive</option>
                      </select>
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Executor email"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Authrozided Represnative"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Manager email"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Minutes of hearing"
                      />
                    </div>
                  </div>
                </div>
                <div className="right_side_form">
                  <div className="heads_text">
                    <h4>Upload Documents</h4>
                  </div>
                  <div className="input_upload_file">
                    <label>
                      <input
                        className="input"
                        type="file"
                        onChange={kyMbrImg}
                      />
                      <div className="wrapper_upload_div">
                        <span className="upload_div1" id="image_">
                          file name {fileName}
                        </span>
                        <span className="Upload-file">+ Upload</span>
                      </div>
                    </label>
                    <span>+ Add more</span>
                  </div>
                </div>
              </div>
              <div className="applicable_not">
                <button className="custom_btn2" to="">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddNotices;
