import { api, AuthToken } from "./AuthController";

export async function getCompany(data) {
  var token = await AuthToken();

  var res = await api.get(`letigation/companyAccToExec`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function getLocationLists(data) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/locationAccordingToCompany?companyId=${data.companyId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function noticeDetailsList(data) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/letigationDetails?letigationId=${data.id}&page=${data.page}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function getManager(data) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/managerAccordingToCompany?companyId=${data.companyId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function getHead(data) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/hodAccordingToCompany?companyId=${data.companyId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function getAllLetigations(data) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/letigationList?company=${data.company}&location=${
      data.location
    }&hearingDate=${data.hearingDate || ""}&toDate=${
      data.toDate || ""
    }&status=${data.status || ""}&page=${data.page}&search=${
      data.search
    }&currentDate=${data.currentDate || ""}&dueToday=${data.dueToday || ""}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function letigationDetailById(data) {
  var token = await AuthToken();

  var res = await api.get(`letigation/letigationDetailsById?_id=${data.id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function letigationDetailByLitigationId(id) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/letigationDetailsByLetigationId?letigationId=${id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function storeExecutorLetigation(data) {
  var token = await AuthToken();

  var res = await api.post(`letigation/addLetigation`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function updateExecutorLetigation(data) {
  var token = await AuthToken();

  var res = await api.post(`letigation/editLetigation`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function deleteLitigation(id) {
  var token = await AuthToken();

  var res = await api.get(`letigation/deleteLetigation?_id=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}
