import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Routing from "./routing/Routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, HashRouter } from "react-router-dom";
import { AxiosInterceptor } from "./controller/AuthController";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AxiosInterceptor>
          <ToastContainer />
          <Routing />
        </AxiosInterceptor>
      </BrowserRouter>
    </div>
  );
}
export default App;
