import React, { useState } from "react";
// import { Link, NavLink } from "react-router-dom";
import "./home.css";
import Header from "../header/Header";
import PieChart from "../../piechart/PieChart";
import "../../piechart/pie.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Home() {
  const [startDates, setStartDates] = useState();

  return (
    <>
      <Header />
      <section className="home_page">
        <div className="container">
          <div className="home_page_select_date">
            <div className="title_bar">
              <div className="form_field">
                <select className="input">
                  <option>Choose company</option>
                  <option>Select Industry</option>
                </select>
              </div>
              <div className="calender">
                <div className="flex_direction_cstm">
                  <DatePicker
                    selected={startDates}
                    className="input"
                    onChange={(date) => setStartDates(date)}
                    dateFormat="dd/MM/yyyy"
                    selectsStart
                    startDate={startDates}
                    placeholderText="DD/MM/YYYY"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper_compliance_section">
            <div className="complaince_piechart">
              <div className="Complaince">
                <div className="complaince_text">
                  <h5>Compliance</h5>
                </div>
                <div className="cards_consult">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>40</h3>
                          <p>Total</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>5</h3>
                          <p>Complied</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>10</h3>
                          <p>Over Due</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>40</h3>
                          <p>Due Today</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>2</h3>
                          <p>Pending for approval</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>1</h3>
                          <p>Upcoming</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pie_chart">
                <div className="pie">
                  <div className="complaince_text">
                    <h5>Risk analysis</h5>
                  </div>
                  <div className="upcoming_list piechart_wrap">
                    <PieChart />
                  </div>
                </div>
              </div>
            </div>
            <div className="complaince_piechart">
              <div className="Complaince">
                <div className="complaince_text">
                  <h5>Notices</h5>
                </div>
                <div className="cards_consult">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>233</h3>
                          <p>Total</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>40</h3>
                          <p>Open</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>101</h3>
                          <p>Closed</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>12</h3>
                          <p>Hold</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>12</h3>
                          <p>Due Today</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="small-box  custom_div_styling">
                        <div className="inner">
                          <h3>12</h3>
                          <p>Upcoming</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pie_chart">
                <div className="pie">
                  <div className="complaince_text">
                    <h5>Upcoming compliances</h5>
                  </div>
                  <div className="upcoming_list">
                    <ul>
                      <li>
                        <strong>Ripenapps technologies</strong>
                        <span>08/21/1981</span>
                      </li>
                      <li>
                        <strong>Gourav kapoor & sons</strong>
                        <span>07/22/1988</span>
                      </li>
                      <li>
                        <strong>Bharat gas pvt.ltd</strong>
                        <span>07/22/1988</span>
                      </li>
                      <li>
                        <strong>Hindustan petroleum</strong>
                        <span>08/21/1981</span>
                      </li>
                      <li>
                        <strong>Indian oil</strong>
                        <span>08/21/1981</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
