import React, { useRef, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Compliance.css";
// import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import moment from "moment";
import {
  complianceDetail,
  editAvailabilityData,
  storeNotAvailabilityData,
  updateExecuterData,
  updateImage,
} from "../../controller/ComplianceController";
import { isLoggedIn } from "../../utils";
import useButtonLoader from "../../hooks/useButtonLoader";
import { imageUrl } from "../../config/config";
import ComplianceHistory from "../../components/ComplianceHistory/ComplianceHistory";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Accordion } from "react-bootstrap";

const ViewActs = () => {
  var navigate = useNavigate();
  const { uin, id, location } = useParams();
  const applicableRef = useRef();
  const notApplicableRef = useRef();
  const [fileName, SetFileName] = useState();

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  const [notSubmitButtonElement, setNotSubmitLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  const [editButtonElement, setEditLoading] = useButtonLoader(
    "Update",
    "Please wait..."
  );

  // full page loader
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const setNotApplicable = async () => {
    await setNotAplicable(true);
    setApplicable(false);
    notApplicableRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const setApplicables = async () => {
    await setApplicable(true);
    setNotAplicable(false);
    applicableRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const kyMbrImg = (e, i) => {
    SetFileName(e.target.files[0].name);
    document.getElementById(`image_${i}`).innerHTML = e.target.files[0].name;
  };

  const [getComplianceData, setComplianceData] = useState({});
  const [getExecutorData, setExecutorData] = useState({});
  const [executorHistory, setExecutorHistory] = useState([]);
  const [managerRemarks, setManagerRemarks] = useState("");
  const [removeApplicableImage, setRemoveApplicableImage] = useState([]);
  const [removeNotApplicableImage, setRemoveNotApplicableImage] = useState([]);
  const [executerComplianceStatusId, setExecuterComplianceStatusId] =
    useState("");

  // const [notApplicable, setNotAplicable] = useState(false);
  // const [applicable, setApplicable]      = useState(false);
  const [notApplicable, setNotAplicable] = useState(false);
  const [applicable, setApplicable] = useState(false);
  const [getButtonStatus, setButtonStatus] = useState(true);
  const [getComplianceStatus, setComplianceStatus] = useState("");

  const comlianceDetail = async (startDate = "", endDate = "") => {
    showLoader();
    var data = {
      uin: uin,
      companyComplianceId: id,
      startDate: startDate,
      endDate: endDate,
    };

    let res = await complianceDetail(data);
    if (res.status === 200) {
      setComplianceData(res.data.particularUinDetails);
      if (res.data.executerData?.length > 0) {
        if (
          res.data.executerData[0].isEditable ||
          res.data.executerData[0].managerComplianceStatus === "rejected" ||
          res.data.executerData[0].managerComplianceStatus === "complied"
        ) {
          setExecutorHistory(res.data.executerData.slice(1));
        } else {
          setExecutorHistory(res.data.executerData);
        }
        if (
          res.data.executerData[0].isEditable ||
          res.data.executerData[0].managerComplianceStatus === "rejected" ||
          res.data.executerData[0].managerComplianceStatus === "complied"
        ) {
          setExecuterComplianceStatusId(
            res.data.executerData[0].executerComplianceStatusId
          );
          setExecutorData(res.data.executerData[0]);
          setComplianceStatus(res.data.executerData[0].managerComplianceStatus);
          setFromDate(res.data.executerData[0].complianceStartDate);
          setToDate(res.data.executerData[0].complianceEndDate);
          setComplianceDate(res.data.executerData[0].complianceDate);
          setPenaltyInterest(res.data.executerData[0].penalityIntrest);
          setComplileType(res.data.executerData[0].compiled);
          setRemark(res.data.executerData[0].remarks);
          setManagerRemarks(res.data.executerData[0].remarkFromManager);
          setComplileType(res.data.executerData[0].complied);
          setAvailableFile(
            res.data.executerData[0].isEditable ||
              res.data.executerData[0].managerComplianceStatus === "rejected"
              ? res.data.executerData[0].imageArray?.length > 0
                ? res.data.executerData[0].imageArray
                : [""]
              : res.data.executerData[0].imageArray
          );
          setNotAvailableFile(
            res.data.executerData[0].isEditable ||
              res.data.executerData[0].managerComplianceStatus === "rejected"
              ? res.data.executerData[0].imageArray?.length > 0
                ? res.data.executerData[0]?.imageArray
                : [""]
              : res.data.executerData[0]?.imageArray
          );
          if (
            res.data.executerData[0].isExecuterApplicable === "notApplicable"
          ) {
            setNotAplicable(true);
            setApplicable(false);
          } else if (
            res.data.executerData[0].isExecuterApplicable === "applicable"
          ) {
            setNotAplicable(false);
            setApplicable(true);
          } else {
            setNotAplicable(false);
            setApplicable(false);
          }
        } else {
          // compliance checking (compliance apply from scratch or not)
          if (!res.data.executerData[0].executercomplianceStatus) {
            setComplianceStatus("");
            setFromDate("");
            setToDate("");
            setComplianceDate("");
            setPenaltyInterest("");
            setComplileType("complied");
            setRemark("");
            setExecutorHistory(res.data.executerData);

            setManagerRemarks("");
            setAvailableFile([""]);
            setNotAvailableFile([""]);
            setApplicable(false);
            setNotAplicable(false);
            setButtonStatus(false);
          }
        }
      } else {
        setExecutorHistory([]);
        setNotAplicable(false);
        setApplicable(false);
        setButtonStatus(false);
      }

      hideLoader();
    } else {
      toast.error(res.message);
    }
  };

  const setNotAvailableImage = async (e, index, value) => {
    if (value?._id) {
      let data = {
        companyComplianceId: id,
        imageId: value._id,
        file: e.target.files[0],
      };

      let res = await updateImage(data);
      document.getElementById(`image_${index}`).innerHTML =
        e.target.files[0].name;
    } else {
      const image = [...getNotAvailableFile];
      image[index] = e.target.files[0];
      setNotAvailableFile(image);
      document.getElementById(`image_${index}`).innerHTML =
        e.target.files[0].name;
    }
  };

  // not available images
  const [getNotAvailableFile, setNotAvailableFile] = useState([""]);
  const handelAddmoreNotApplicableFile = () => {
    setNotAvailableFile([...getNotAvailableFile, ""]);
  };

  const removeNotAvailableFile = (e, index) => {
    e.preventDefault();
    setRemoveNotApplicableImage([
      ...removeNotApplicableImage,
      ...getNotAvailableFile.filter((val, i) => i === index),
    ]);
    const notAvailableFile = [...getNotAvailableFile];
    notAvailableFile.splice(index, 1);
    setNotAvailableFile(notAvailableFile);
  };

  const setAvailableImage = async (e, index, value) => {
    if (value?._id) {
      let data = {
        companyComplianceId: id,
        imageId: value._id,
        file: e.target.files[0],
      };

      let res = await updateImage(data);
      document.getElementById(`image_${index}`).innerHTML =
        e.target.files[0].name;
    } else {
      const image = [...getAvailableFile];
      image[index] = e.target.files[0];
      setAvailableFile(image);
      document.getElementById(`image_${index}`).innerHTML =
        e.target.files[0].name;
    }
  };

  // available images
  const [getAvailableFile, setAvailableFile] = useState([""]);
  const handelAddmoreApplicableFile = () => {
    setAvailableFile([...getAvailableFile, ""]);
  };

  const removeAvailableFile = (e, index) => {
    e.preventDefault();
    setRemoveApplicableImage([
      ...removeApplicableImage,
      ...getAvailableFile.filter((val, i) => i === index),
    ]);
    const availableFile = [...getAvailableFile];
    availableFile.splice(index, 1);
    setAvailableFile(availableFile);
  };

  const [getRemark, setRemark] = useState("");
  const handleRemark = async (e) => {
    setRemark(e.target.value);
  };

  const [getComplileType, setComplileType] = useState("complied");
  const handelComplileType = async (e) => {
    setComplileType(e.target.value);
  };

  const [getPenaltyInterest, setPenaltyInterest] = useState("");
  const handelPenaltyInterest = async (e) => {
    setPenaltyInterest(e.target.value);
  };

  const [getComplianceDate, setComplianceDate] = useState("");
  const handelComplianceDate = async (e) => {
    setComplianceDate(e.target.value);
  };

  const [getToDate, setToDate] = useState("");
  const handelToDate = async (e) => {
    setToDate(e.target.value);
  };

  const [getFromDate, setFromDate] = useState("");
  const handelFromDate = async (e) => {
    setFromDate(e.target.value);
  };

  const handleAvailable = async () => {
    if (getComplileType === "cannot do" && !getRemark) {
      toast.error("Remark Field is mandatory");
    } else {
      try {
        setSubmitLoading(true);
        let formData = new FormData();
        formData.append("type", 2);
        formData.append("remarks", getRemark);
        formData.append("companyComplianceId", id);
        formData.append(
          "executerComplianceStatusId",
          executerComplianceStatusId
        );
        formData.append("uin", uin);
        formData.append("location", location);
        formData.append("complianceEndDate", getToDate);
        formData.append("complianceDate", getComplianceDate);
        formData.append("penalityIntrest", getPenaltyInterest);
        formData.append("complied", getComplileType);
        formData.append("complianceStartDate", getFromDate);
        if (getAvailableFile.length > 0) {
          for (let i = 0; i < getAvailableFile.length; i++) {
            formData.append("file", getAvailableFile[i]);
          }
        }

        if (getComplianceStatus === "") {
          var res = await storeNotAvailabilityData(formData);
        } else {
          var res = await updateExecuterData(formData);
        }

        if (res.status === 200) {
          toast.success("Success");
          setSubmitLoading(false);
          navigate("/compliance");
        } else {
          setSubmitLoading(false);
          toast.error(res.message);
        }
      } catch (err) {
        setSubmitLoading(false);
        toast.error(err);
      }
    }
  };

  // edit data handle
  const editHandleAvailable = async () => {
    if (getComplileType === "cannot do" && !getRemark) {
      toast.error("Remark Field is mandatory");
    } else {
      try {
        setEditLoading(true);
        let formData = new FormData();
        formData.append("type", 1);
        formData.append("companyComplianceId", id);
        formData.append("remarks", getRemark);
        formData.append("complianceStartDate", getFromDate);
        formData.append("complianceEndDate", getToDate);
        formData.append("complianceDate", getComplianceDate);
        formData.append("penalityIntrest", getPenaltyInterest);
        formData.append("complied", getComplileType);
        formData.append("deleteImage", JSON.stringify(removeApplicableImage));
        formData.append(
          "remarkId",
          managerRemarks[managerRemarks.length - 1]?._id
        );
        let tempFile = getAvailableFile.filter((file) => !file?._id);
        if (tempFile.length > 0) {
          for (let i = 0; i < tempFile.length; i++) {
            formData.append("file", tempFile[i]);
          }
        }

        var res = await editAvailabilityData(formData);

        if (res.status === 200) {
          toast.success("Success");
          setEditLoading(false);
          navigate("/compliance");
        } else {
          setEditLoading(false);
          toast.error(res.message);
        }
      } catch (err) {
        setEditLoading(false);
        toast.error(err);
      }
    }
  };

  // edit data handle not available
  const editHandleNotAvailable = async () => {
    if (!getRemark) {
      toast.error("Remark Field is mandatory");
    } else {
      try {
        setEditLoading(true);
        let formData = new FormData();
        formData.append("type", 2);
        formData.append("companyComplianceId", id);
        formData.append("remarks", getRemark);
        formData.append("deleteImage", removeNotApplicableImage);
        formData.append(
          "remarkId",
          managerRemarks[managerRemarks.length - 1]?._id
        );

        let tempFile = getNotAvailableFile.filter((file) => !file?._id);
        if (tempFile.length > 0) {
          for (let i = 0; i < tempFile.length; i++) {
            formData.append("file", tempFile[i]);
          }
        }

        var res = await editAvailabilityData(formData);

        if (res.status === 200) {
          toast.success("Success");
          setEditLoading(false);
          navigate("/compliance");
        } else {
          setEditLoading(false);
          toast.error(res.message);
        }
      } catch (err) {
        setEditLoading(false);
        toast.error(err);
      }
    }
  };

  const handleNotAvailable = async () => {
    setNotSubmitLoading(true);
    let formData = new FormData();
    formData.append("type", 1);
    formData.append("remarks", getRemark);
    formData.append("location", location);
    formData.append("companyComplianceId", id);
    formData.append("executerComplianceStatusId", executerComplianceStatusId);
    formData.append("uin", uin);
    if (getNotAvailableFile.length > 0) {
      for (let i = 0; i < getNotAvailableFile.length; i++) {
        formData.append("file", getNotAvailableFile[i]);
      }
    }

    if (getComplianceStatus === "") {
      var res = await storeNotAvailabilityData(formData);
    } else {
      var res = await updateExecuterData(formData);
    }

    if (res.status === 200) {
      toast.success("Success");
      setNotSubmitLoading(false);
      navigate("/compliance");
    } else {
      toast.error(res.message);
      setNotSubmitLoading(false);
    }
  };

  const clearFilterHandler = () => {
    if (filterStartDate || filterEndDate) {
      comlianceDetail();
      setFilterStartDate("");
      setFilterEndDate("");
    }
  };

  useEffect(() => {
    comlianceDetail();
  }, []);

  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      comlianceDetail(filterStartDate, filterEndDate);
    }
  }, [filterStartDate, filterEndDate]);

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_applicable">
        <div className="container">
          {getComplianceData?.act ? (
            <div className="wrap_viewacts">
              <div className="wrapper_back_date">
                <div className="backward_styling">
                  <Link to={`/compliance`}>
                    <span className="icon">
                      <IoIosArrowBack />
                    </span>
                  </Link>
                </div>
                {/* <div className="form_field">
                <select className="input">
                  <option>Select Month</option>
                  <option>Select Industry</option>
                </select>
              </div> */}
              </div>
              <div className="content_wrapper_acts">
                <ul>
                  <li>
                    <span>UIN</span>
                    <div className="content_wrap">
                      <h4>{getComplianceData?.uin}</h4>
                    </div>
                  </li>
                  <li>
                    <span>Act/Rule Name</span>
                    <div className="content_wrap">
                      <h4>{getComplianceData?.act}</h4>
                    </div>
                  </li>
                  <li>
                    <span>Applicability</span>
                    <div className="content_wrap">
                      <h4>{getComplianceData?.ruleName}</h4>
                      <p>{getComplianceData?.remarkForRule}</p>
                    </div>
                  </li>
                  <li>
                    <span>Penal Provision</span>
                    <div className="content_wrap">
                      <h4>{getComplianceData?.sectionRule}</h4>
                      <p>{getComplianceData?.remarkForSectionRule}</p>
                    </div>
                  </li>
                  <li>
                    <span>Risk analysis</span>
                    <div className="content_wrap">
                      <h4>{getComplianceData?.riskAnalysis}</h4>
                    </div>
                  </li>
                  <li>
                    <span>Other Information</span>
                    <div className="content_wrap">
                      <p>{getComplianceData?.remarkForRiskAnalysis}</p>
                    </div>
                  </li>
                </ul>
              </div>

              {/* if compliance status true then this section will display */}
              {getComplianceData.isActive && (
                <>
                  {isLoggedIn().role !== "Head" ? (
                    getExecutorData?.isEditable ||
                    getExecutorData?.managerComplianceStatus === "rejected" ||
                    getExecutorData?.executercomplianceStatus === false ||
                    !getButtonStatus ? (
                      !getButtonStatus || isLoggedIn().role !== "Head" ? (
                        <div className="applicable_not">
                          <button
                            type="button"
                            className={`not_app applicable ${
                              notApplicable === true ? "active" : ""
                            }`}
                            // to="/executor/compliance/view-acts/not-applicable"
                            onClick={setNotApplicable}
                          >
                            Not Applicable
                          </button>
                          <button
                            className={`not_app ${
                              applicable === true ? "active" : ""
                            }`}
                            // to="/executor/compliance/view-acts/applicaple"
                            onClick={setApplicables}
                          >
                            Applicable
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="applicable_not">
                            <button
                              type="button"
                              className={`not_app applicable active`}
                              // to="/executor/compliance/view-acts/not-applicable"
                            >
                              Not Applicable
                            </button>
                            <button
                              className={`not_app active `}
                              // to="/executor/compliance/view-acts/applicaple"
                            >
                              Applicable
                            </button>
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        <div className="applicable_not">
                          <button
                            type="button"
                            className={`not_app applicable active`}
                            // to="/executor/compliance/view-acts/not-applicable"
                          >
                            Not Applicable
                          </button>
                          <button
                            className={`not_app active `}
                            // to="/executor/compliance/view-acts/applicaple"
                          >
                            Applicable
                          </button>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <div className="applicable_not">
                        <button
                          type="button"
                          className={`not_app applicable active`}
                          // to="/executor/compliance/view-acts/not-applicable"
                        >
                          Not Applicable
                        </button>
                        <button
                          className={`not_app active `}
                          // to="/executor/compliance/view-acts/applicaple"
                        >
                          Applicable
                        </button>
                      </div>
                    </>
                  )}

                  {/* Not Applicable */}
                  {notApplicable ? (
                    <>
                      <form id="not-applicable" ref={notApplicableRef}>
                        <div className="content_wrapper_acts">
                          <div className="upload_document">
                            <div className="form_control_message">
                              <span>Remarks</span>
                              <textarea
                                className="form-control"
                                id="form6Example7"
                                rows="4"
                                value={getRemark}
                                onChange={(e) => handleRemark(e)}
                                placeholder="Write something here…"
                              ></textarea>
                            </div>
                            {getExecutorData?.isEditable ||
                            getExecutorData?.managerComplianceStatus ===
                              "rejected" ||
                            !getExecutorData?.isAlreadyFilled ? (
                              <>
                                <div className="heads_text py-3">
                                  <h4>Upload Documents</h4>
                                  <span>(Optional)</span>
                                </div>
                                {getNotAvailableFile &&
                                  getNotAvailableFile.map((value, i) => (
                                    <div className="input_upload_file" key={i}>
                                      <label>
                                        <input
                                          className="input"
                                          type="file"
                                          onChange={(e) =>
                                            setNotAvailableImage(e, i, value)
                                          }
                                        />
                                        <div className="wrapper_upload_div">
                                          <span
                                            className="upload_div1"
                                            id={`image_${i}`}
                                          >
                                            file name {value.images}
                                          </span>
                                          <span className="Upload-file">
                                            + Upload
                                          </span>
                                        </div>
                                      </label>

                                      {i === 0 ? (
                                        <span
                                          onClick={(e) => {
                                            handelAddmoreNotApplicableFile(e);
                                          }}
                                        >
                                          + Add more
                                        </span>
                                      ) : (
                                        <span
                                          onClick={(e) =>
                                            removeNotAvailableFile(e, i)
                                          }
                                        >
                                          + Remove
                                        </span>
                                      )}
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <>
                                <div className="wrapper_compliance py-4">
                                  <table className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr className="custom_tr">
                                        <th>Documents</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {getNotAvailableFile?.length > 0 ? (
                                        getNotAvailableFile.map((file) => {
                                          return (
                                            <tr
                                              className="cstm_tr"
                                              key={file._id}
                                            >
                                              <td>
                                                <div className="d-flex justify-content-between">
                                                  <span>{file.images}</span>
                                                  <button
                                                    className="custom_btn btn btn-primary"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      window.open(
                                                        `${imageUrl}${file.images}`
                                                      );
                                                    }}
                                                  >
                                                    Download
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td>No Documents Attached</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}
                            {notApplicable && managerRemarks?.length > 0 && (
                              <div className="form_control_message">
                                <h4>Manager Remark</h4>
                                {
                                  managerRemarks[managerRemarks?.length - 1]
                                    ?.remark
                                }
                              </div>
                            )}
                          </div>
                          {isLoggedIn().role !== "Head" ? (
                            getExecutorData?.isEditable ||
                            getExecutorData?.managerComplianceStatus ===
                              "rejected" ? (
                              <div className="submit_form_btn">
                                <button
                                  type="button"
                                  onClick={editHandleNotAvailable}
                                  className="custom_button"
                                  ref={editButtonElement}
                                >
                                  Update
                                </button>
                              </div>
                            ) : !getButtonStatus ? (
                              <div className="submit_form_btn">
                                <button
                                  type="button"
                                  className="custom_button"
                                  onClick={handleNotAvailable}
                                  ref={notSubmitButtonElement}
                                >
                                  Submit
                                </button>
                              </div>
                            ) : (
                              ""
                            )
                          ) : null}
                        </div>
                      </form>
                    </>
                  ) : (
                    ""
                  )}

                  {/* Applicable */}
                  {applicable ? (
                    <>
                      <form id="applicable" ref={applicableRef}>
                        <div className="content_wrapper_acts">
                          <div className="compliance_name">
                            <ul>
                              <li>
                                <span>Select Complied type</span>
                                <div className="wrapper_radio">
                                  <div className="radio_wrap_">
                                    <label htmlFor="red">
                                      <input
                                        type="radio"
                                        name="colors"
                                        id="red"
                                        checked={
                                          getComplileType === "complied"
                                            ? true
                                            : false
                                        }
                                        value="complied"
                                        onChange={(e) => handelComplileType(e)}
                                        required
                                      />
                                      <span>Complied</span>
                                    </label>
                                  </div>
                                  <div className="radio_wrap_">
                                    <label htmlFor="blue">
                                      <input
                                        type="radio"
                                        name="colors"
                                        value="cannot do"
                                        checked={
                                          getComplileType === "cannot do"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => handelComplileType(e)}
                                        id="blue"
                                        required
                                      />
                                      <span>Cannot do</span>
                                    </label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <span>Compliance name</span>
                                <h4>{getComplianceData?.compliance}</h4>
                              </li>
                              <li>
                                <span>
                                  Compliance period
                                  {getComplileType === "cannot do" &&
                                    "(Optional)"}
                                </span>
                                From :{" "}
                                <input
                                  className="input"
                                  placeholder="From .."
                                  type="date"
                                  // ref={ref}
                                  value={getFromDate}
                                  onChange={(e) => handelFromDate(e)}
                                  // onFocus={() => (ref.current.type = "date")}
                                  // onBlur={() => (ref.current.type = "text")}
                                />
                                To :{" "}
                                <input
                                  className="input"
                                  placeholder="To .."
                                  type="date"
                                  onChange={(e) => handelToDate(e)}
                                  value={getToDate}
                                  // ref={ref}
                                  // onFocus={() => (ref.current.type = "date")}
                                  // onBlur={() => (ref.current.type = "text")}
                                />
                              </li>
                              <li>
                                <div className="row">
                                  <div className="col-5">
                                    <span>
                                      Compliance Date
                                      {getComplileType === "cannot do" &&
                                        "(Optional)"}
                                    </span>
                                    <input
                                      className="input mw-100"
                                      placeholder="Filter by date"
                                      type="date"
                                      value={getComplianceDate}
                                      onChange={(e) => handelComplianceDate(e)}
                                      // ref={ref}
                                      // onFocus={() => (ref.current.type = "date")}
                                      // onBlur={() => (ref.current.type = "text")}
                                    />
                                  </div>
                                  <div className="col-5">
                                    <li>
                                      <span>Penalty/ Interest (Optional)</span>
                                      <input
                                        type="number"
                                        className="input input_2 mw-100"
                                        placeholder="Enter amount"
                                        onChange={(e) =>
                                          handelPenaltyInterest(e)
                                        }
                                        value={getPenaltyInterest}
                                        required
                                      ></input>
                                    </li>
                                  </div>
                                </div>
                              </li>

                              {/* <li>
                            <span>
                              Compliance Upload Date{" "}
                              {getComplileType === "cannot do" && "(Optional)"}
                            </span>
                            <h6>
                              {moment(getComplianceData.createdAt).format(
                                "DD MMM YYYY"
                              )}
                            </h6>
                          </li> */}
                            </ul>
                          </div>
                          <div className="upload_document">
                            {getExecutorData?.isEditable ||
                            getExecutorData?.managerComplianceStatus ===
                              "rejected" ||
                            !getExecutorData?.isAlreadyFilled ? (
                              <>
                                <div className="heads_text">
                                  <h4>
                                    Upload Documents <span>(Optional)</span>
                                  </h4>
                                </div>
                                {getAvailableFile &&
                                  getAvailableFile.map((value, j) => (
                                    <div className="input_upload_file" key={j}>
                                      <label>
                                        <input
                                          className="input"
                                          type="file"
                                          // value={value}
                                          onChange={(e) =>
                                            setAvailableImage(e, j, value)
                                          }
                                          // onChange={(e) => kyMbrImg(e, j)}
                                        />
                                        <div className="wrapper_upload_div">
                                          <span
                                            className="upload_div1"
                                            id={`image_${j}`}
                                          >
                                            file name {value.images}
                                          </span>
                                          <span className="Upload-file">
                                            + Upload
                                          </span>
                                        </div>
                                      </label>

                                      {j === 0 ? (
                                        <span
                                          onClick={(e) => {
                                            handelAddmoreApplicableFile(e);
                                          }}
                                        >
                                          + Add more
                                        </span>
                                      ) : (
                                        <span
                                          onClick={(e) =>
                                            removeAvailableFile(e, j)
                                          }
                                        >
                                          + Remove
                                        </span>
                                      )}
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <>
                                <div className="wrapper_compliance">
                                  <table className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr className="custom_tr">
                                        <th>Documents</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {getAvailableFile?.length > 0 ? (
                                        getAvailableFile.map((file) => {
                                          return (
                                            <tr
                                              className="cstm_tr"
                                              key={file._id}
                                            >
                                              <td>
                                                <div className="d-flex justify-content-between">
                                                  <span>{file.images}</span>
                                                  <button
                                                    className="custom_btn btn btn-primary"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      window.open(
                                                        `${imageUrl}${file.images}`
                                                      );
                                                    }}
                                                  >
                                                    Download
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td>No Documents Attached</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}

                            <div className="form_control_message">
                              <span>Remarks</span>
                              {getComplileType === "complied" && (
                                <span>(Optional)</span>
                              )}

                              <textarea
                                className="form-control"
                                id="form6Example7"
                                rows="4"
                                value={getRemark}
                                onChange={(e) => handleRemark(e)}
                                placeholder="Write something here…"
                              ></textarea>
                            </div>
                            {applicable && managerRemarks?.length > 0 && (
                              <div className="form_control_message">
                                <h4>Manager Remark</h4>
                                {
                                  managerRemarks[managerRemarks?.length - 1]
                                    ?.remark
                                }
                              </div>
                            )}
                          </div>

                          {isLoggedIn()?.role !== "Head" ? (
                            getExecutorData?.isEditable ||
                            getExecutorData?.managerComplianceStatus ===
                              "rejected" ? (
                              <div className="submit_form_btn">
                                <button
                                  type="button"
                                  onClick={editHandleAvailable}
                                  className="custom_button"
                                  ref={editButtonElement}
                                >
                                  Update
                                </button>
                              </div>
                            ) : !getButtonStatus ? (
                              <div className="submit_form_btn">
                                <button
                                  type="button"
                                  onClick={handleAvailable}
                                  className="custom_button"
                                  ref={submitButtonElement}
                                >
                                  Submit
                                </button>
                              </div>
                            ) : (
                              ""
                            )
                          ) : null}
                        </div>
                      </form>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}

              {/* compliance history */}
              <div className="row">
                <div className="col-7">
                  <h3>Compliance History</h3>
                </div>
                <div className="col-2">
                  <input
                    type="date"
                    className="form-control"
                    value={filterStartDate}
                    onChange={(e) => setFilterStartDate(e.target.value)}
                    name=""
                    id=""
                  />
                </div>
                <div className="col-2">
                  <input
                    type="date"
                    className="form-control"
                    value={filterEndDate}
                    onChange={(e) => setFilterEndDate(e.target.value)}
                    name=""
                    id=""
                  />
                </div>
                <div className="col-1">
                  <div className="">
                    <button
                      className="not_app applicable"
                      onClick={clearFilterHandler}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <div className="my-3">
                <Accordion className="accordion_wrap" defaultActiveKey="1">
                  {executorHistory?.length > 0 ? (
                    <>
                      {executorHistory?.map((data, i) => {
                        return (
                          <Accordion.Item eventKey={i} key={i}>
                            <Accordion.Header>
                              <h6 className="text-capitalize m-0">
                                {`${++i}.${getComplianceData.compliance} - (${
                                  data.complianceStartDate || "N/A"
                                } - ${data.complianceEndDate || "N/A"}) (${
                                  data.isExecuterApplicable
                                })`}
                              </h6>
                            </Accordion.Header>
                            <Accordion.Body>
                              <ComplianceHistory
                                key={i}
                                isApplicable={
                                  data.isExecuterApplicable === "applicable"
                                    ? true
                                    : false
                                }
                                compileType={data.complied}
                                documents={data.imageArray}
                                remark={data.remarks}
                                managerRemark={data.remarkFromManager}
                                executorData={data}
                                complianceName={getComplianceData.compliance}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </>
                  ) : (
                    <div className="text-center">
                      <h5>History Not Available</h5>
                    </div>
                  )}
                </Accordion>
              </div>
            </div>
          ) : (
            <div className="text-center">{loader}</div>
          )}
        </div>
      </section>
    </>
  );
};

export default ViewActs;
