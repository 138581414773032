import React, { useState, useEffect } from "react";
import "./Library.css";
// import Header from "../header/Header";
import Header from "../Layout/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";
import { BsDownload } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import { useRef } from "react";
import { FaEye } from "react-icons/fa";
import { FaFile } from "react-icons/fa";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-js-pagination";
import {
  librayListing,
  notificationListing,
  stateListing,
} from "../../controller/LibraryController";
import { toast } from "react-toastify";
import { stateData } from "../../controller/ComplianceController";
import { Url } from "../../config/config";
import { dateFormat } from "../../utils";
import NotFound from "../../components/NotFound/NotFound";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { FiRefreshCcw } from "react-icons/fi";

function Library() {
  const [show, setShow] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const handleClose = () => setShow(false);
  const [value, setValue] = useState(0);
  const handleShow = (value) => {
    setValue(value);
    setShow(true);
  };

  const [states, setStates] = useState([]);
  const [stateModal, setStateModal] = useState(false);

  const companyId = JSON.parse(localStorage.getItem("userLogin"))?.companyId;

  const [setBeforeDetail, getBeforeDetail] = useState("");
  const [setAfterDetail, getAfterDetail] = useState("");
  const handleNotificationDetailModel = (
    beforeText = setBeforeDetail,
    afterText = setAfterDetail
  ) => {
    handleShow();
    getBeforeDetail(beforeText);
    getAfterDetail(afterText);
  };

  //
  const [selectedOptions, setSelectedOptions] = useState();
  const [getLibraryActiveCount, setLibraryActiveCount] = useState(1);
  const [getNotificationActiveCount, setNotificationActiveCount] = useState(1);
  const [getLibraryTotal, setLibraryTotal] = useState(0);
  const [getLibrary, setLibrary] = useState([]);
  // const [getState,setState]= useState([]);

  // Array of all options
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const handlePageChange = (pageNo) => {
    setLibraryActiveCount(pageNo);
    libraryListing(getSeletctedTab, pageNo, getInputSearch2, stateValue);
  };

  const libraryListing = async (
    type = getSeletctedTab,
    page = getLibraryActiveCount,
    search = getInputSearch2,
    state = stateValue
  ) => {
    var data = {
      type: type,
      search: search,
      state: state,
      page: page,
    };
    showLoader();
    let res = await librayListing(data);
    if (res.status === 200) {
      setLibrary(res.data.actListing);
      setLibraryTotal(res.data.total);
      hideLoader();
    } else {
      toast.error(res.message);
      hideLoader();
    }
  };

  const [getNotification, setNotification] = useState([]);
  const [getNotificationTotal, setNotificationTotal] = useState(0);
  const [notificationStateValue, setNotificationStateValue] = useState("");

  const getNotificationListings = async (
    type = getSeletctedTab,
    page = getLibraryActiveCount,
    search = getInputSearch,
    state = notificationStateValue,
    effectiveDate = getDateSelect
  ) => {
    var data = {
      notificationDate: "",
      search: search,
      state: state,
      effectiveDate: effectiveDate,
      page: page,
    };
    showLoader();
    let notificationListingResponse = await notificationListing(data);
    var res = notificationListingResponse;
    if (res.status === 200) {
      setNotification(res.data.notificationListing);
      setNotificationTotal(res.data.total);
      console.log(getNotificationTotal, "getNotification");
      hideLoader();
    } else {
      toast.error(res.message);
      hideLoader();
    }
  };

  // filter reset handler
  const resetHandler = () => {
    setDateSelect("");
    setInputSearch("");
    setNotificationStateValue("");
    getNotificationListings(getSeletctedTab, getLibraryActiveCount, "", "", "");
  };

  const [getSeletctedTab, setSeletctedTab] = useState("act");
  const getLibraryClick = async (key) => {
    setInputSearch2("");
    setStateValue("");
    setSeletctedTab(key);
    if (key == "notification") {
      getNotificationListings();
    } else {
      libraryListing(
        key,
        getLibraryActiveCount,
        getInputSearch,
        getStateSelect
      );
    }
  };

  const [getInputSearch, setInputSearch] = useState("");
  const [getInputSearch2, setInputSearch2] = useState("");
  const handleInputSearch = (e) => {
    setInputSearch(e.target.value);
    getNotificationListings(
      getSeletctedTab,
      getLibraryActiveCount,
      e.target.value,
      getStateSelect
    );
  };

  const handleInputSearch2 = (e) => {
    setInputSearch2(e.target.value);
    libraryListing(
      getSeletctedTab,
      getLibraryActiveCount,
      e.target.value,
      getStateSelect
    );
  };

  const [getStateSelect, setStateSelect] = useState("");

  const [getDateSelect, setDateSelect] = useState("");
  const handleDateSelect = (e) => {
    setDateSelect(e.target.value);
    getNotificationListings(
      getSeletctedTab,
      getLibraryActiveCount,
      getInputSearch,
      notificationStateValue,
      e.target.value
    );
  };

  const [getState, setState] = useState([]);
  const [stateValue, setStateValue] = useState("");
  const fetchStateList = async () => {
    var res = await stateListing();
    console.log(res.data);
    if (res.status === 200) {
      setState(res.data || []);
      // console.log(getData,"getData");
    } else {
      toast.error(res.messa);
    }
  };
  const handelStateSelect = (e) => {
    setStateValue(e.target.value);
    libraryListing(
      getSeletctedTab,
      getLibraryActiveCount,
      getInputSearch2,
      e.target.value
    );
  };

  const handleStateNotification = (e) => {
    setNotificationStateValue(e.target.value);
    // console.log('', getLibraryActiveCount,getInputSearch, e.target.value,getDateSelect)
    getNotificationListings(
      "",
      getLibraryActiveCount,
      getInputSearch,
      e.target.value,
      getDateSelect
    );
  };

  const handleNotificationPageChange = (pageNo) => {
    setNotificationActiveCount(pageNo);
    getNotificationListings(getSeletctedTab, pageNo);
  };

  const ref = useRef();

  useEffect(() => {
    libraryListing();
    fetchStateList();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_library">
        <div className="container">
          <div className="wrap_viewacts">
            <Tabs
              onSelect={getLibraryClick}
              defaultActiveKey={"act"}
              id="uncontrolled-tab-example"
              //   className="mb-3"
            >
              <Tab eventKey="act" title="Acts">
                <div className="custom_search remarks_tempo">
                  <div className="form_tempo">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by Act Name"
                      onChange={(e) => handleInputSearch2(e)}
                    />

                    {/* <Select
                      options={optionList}
                      placeholder="Search by act name, rule name etc…"
                      value={selectedOptions}
                      onChange={handleSelect}
                      isSearchable={true}
                    // isMulti
                    /> */}
                  </div>
                  <div className="form_field_wrap mx-3">
                    {/* Search By State -----------------------------------------------------------------*/}

                    <div className="form_field">
                      <select
                        className="input"
                        onChange={(e) => handelStateSelect(e)}
                        value={stateValue}
                      >
                        <option value="">All States</option>
                        {getState &&
                          getState.length > 0 &&
                          getState.map(
                            ({ _id, stateName, countryId }, index) => {
                              return (
                                <option key={index} value={stateName}>
                                  {stateName}
                                </option>
                              );
                            }
                          )}
                      </select>
                    </div>
                  </div>
                  {/* End Search By State ------------------------------------------------------- */}
                  <div className="form_field_wrap justify-content-end">
                    <div className="form_field">
                      <button
                        className="download-excel"
                        onClick={() =>
                          window.open(
                            `${Url}/libraryAct/downloadActRule?type=${getSeletctedTab}&search=${getInputSearch2}&state=${getStateSelect}&companyId=${companyId}`
                          )
                        }
                      >
                        <span>
                          <BsDownload />
                        </span>
                        Download excel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="data_table">
                  <Table striped bordered hover>
                    <thead>
                      <tr className="custom_tr">
                        <th>S.N0.</th>
                        <th>ACT NAME</th>
                        <th>STATE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getLibrary && getLibrary.length > 0 ? (
                        getLibrary.map((value, i) => (
                          <tr key={i} className="cstm_tr">
                            <td>{++i + (getLibraryActiveCount - 1) * 10}</td>
                            <td>
                              <p className="tooltips-effect">
                                {value.name.slice(0, 40)}
                                {value.name.length > 40 ? "..." : ""}
                                <span className="tooltiptext">
                                  {value.name}
                                </span>
                              </p>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  setStateModal(true);
                                  setStates(value.state);
                                }}
                              >
                                View State
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() =>
                                  window.open(value.uploadFile, "_blank")
                                }
                              >
                                <span>
                                  <BsDownload />
                                </span>
                                Download
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NotFound />
                      )}
                    </tbody>
                  </Table>
                  <div className="w-100 d-flex justify-content-center">
                    {loader}
                  </div>

                  <div className="Pagination">
                    <Pagination
                      activePage={getLibraryActiveCount}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={10}
                      totalItemsCount={getLibraryTotal}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="rule" title="Rules">
                <div className="custom_search remarks_tempo">
                  <div className="form_tempo">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by Rule Name"
                      onChange={(e) => handleInputSearch2(e)}
                    />
                    {/* <Select
                      options={optionList}
                      placeholder="Search by act name, rule name etc…"
                      value={selectedOptions}
                      onChange={handleSelect}
                      isSearchable={true}
                    // isMulti
                    /> */}
                  </div>
                  <div className="form_field_wrap mx-3">
                    {/* Search By State -----------------------------------------------------------------*/}

                    <div className="form_field">
                      <select
                        className="input"
                        onChange={(e) => handelStateSelect(e)}
                        value={stateValue}
                      >
                        <option value="">All States</option>
                        {getState &&
                          getState.length > 0 &&
                          getState.map(
                            ({ _id, stateName, countryId }, index) => {
                              return (
                                <option key={index} value={stateName}>
                                  {stateName}
                                </option>
                              );
                            }
                          )}
                      </select>
                    </div>
                  </div>
                  <div className="form_field_wrap justify-content-end">
                    <div className="form_field">
                      <button
                        className="download-excel"
                        onClick={() =>
                          window.open(
                            `${Url}/libraryAct/downloadActRule?type=${getSeletctedTab}&search=${getInputSearch2}&state=${getStateSelect}&companyId=${companyId}`
                          )
                        }
                      >
                        <span>
                          <BsDownload />
                        </span>
                        Download excel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="data_table">
                  <Table striped bordered hover>
                    <thead>
                      <tr className="custom_tr">
                        <th>S.N0.</th>
                        <th>RULE NAME</th>
                        <th>STATE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getLibrary && getLibrary.length > 0 ? (
                        getLibrary.map((value, i) => (
                          <tr className="cstm_tr" key={i}>
                            <td>{++i + (getLibraryActiveCount - 1) * 10}</td>
                            <td>
                              <p className="tooltips-effect">
                                {value.name.slice(0, 40)}
                                {value.name.length > 40 ? "..." : ""}
                                <span className="tooltiptext">
                                  {value.name}
                                </span>
                              </p>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  setStateModal(true);
                                  setStates(value.state);
                                }}
                              >
                                View State
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() =>
                                  window.open(value.uploadFile, "_blank")
                                }
                              >
                                <span>
                                  <BsDownload />
                                </span>
                                Download
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NotFound />
                      )}
                    </tbody>
                  </Table>
                  <div className="w-100 d-flex justify-content-center">
                    {loader}
                  </div>
                  <div className="Pagination">
                    <Pagination
                      activePage={getLibraryActiveCount}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={10}
                      totalItemsCount={getLibraryTotal}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="notification" title="Notifications">
                <div className="custom_search remarks_tempo">
                  <div className="form_tempo d-flex">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by Notification Title"
                      value={getInputSearch}
                      onChange={(e) => handleInputSearch(e)}
                    />
                    {/* ---------------filter By State in Notification -------------------------------------------------- */}

                    <div className="form_field_wrap mx-3 ">
                      <div className="form_field mx-3">
                        <select
                          className="input"
                          onChange={(e) => handleStateNotification(e)}
                          value={notificationStateValue}
                        >
                          <option value="">All States</option>
                          {getState &&
                            getState.length > 0 &&
                            getState.map(
                              ({ _id, stateName, countryId }, index) => {
                                return (
                                  <option key={index} value={stateName}>
                                    {stateName}
                                  </option>
                                );
                              }
                            )}
                        </select>
                      </div>
                    </div>

                    {/*----- End Filter By State in Notification ------------------------------------------------------- */}
                    {/* <Select
                      options={optionList}
                      placeholder="Search by act name, rule name etc…"
                      value={selectedOptions}
                      onChange={handleSelect}
                      isSearchable={true}
                    // isMulti
                    /> */}
                  </div>
                  <div className="form_field_wrap justify-content-end">
                    <div className="form_field">
                      <input
                        className="input"
                        placeholder="Filter by date"
                        type="date"
                        ref={ref}
                        value={getDateSelect}
                        onChange={(e) => handleDateSelect(e)}
                      />
                    </div>
                    <div className="form_field">
                      <button className="download-excel" onClick={resetHandler}>
                        <span>
                          <FiRefreshCcw />
                        </span>
                        Reset
                      </button>
                    </div>

                    <div className="form_field">
                      <button
                        className="download-excel"
                        onClick={() =>
                          window.open(
                            `${Url}/libraryAct/notificationExcel?notificationDate=&search=${getInputSearch}&state=${getStateSelect}&effectiveDate=${getDateSelect}&companyId=${companyId}`
                          )
                        }
                      >
                        <span>
                          <BsDownload />
                        </span>
                        Download excel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="data_table">
                  <Table striped bordered hover>
                    <thead>
                      <tr className="custom_tr">
                        {/* <th>NOTIFICATION NO.</th> */}
                        <th>NOTIFICATION TITTLE</th>
                        <th>NOTIFICATION DATE</th>
                        <th>EFFECTIVE DATE</th>
                        <th>LAW AREA</th>
                        <th>ACT</th>
                        <th>STATE</th>
                        <th>ATTACHMENT</th>
                        <th>IMPACT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getNotification && getNotification.length > 0 ? (
                        getNotification.map((value, i) => (
                          <tr key={i} className="cstm_tr">
                            {/* <td>22/02/2022</td> */}
                            <td>
                              {" "}
                              <p className="tooltips-effect">
                                {value.notificationTitle.slice(0, 15)}
                                {value.notificationTitle.length > 15
                                  ? "..."
                                  : ""}
                                <span className="tooltiptext">
                                  {value.notificationTitle}
                                </span>
                              </p>
                            </td>
                            <td>{dateFormat(value.notificationDate)}</td>
                            <td>{dateFormat(value.effectiveDate)}</td>

                            <td>
                              {" "}
                              <p className="tooltips-effect">
                                {value.lawArea.slice(0, 15)}
                                {value.lawArea.length > 15 ? "..." : ""}
                                <span className="tooltiptext">
                                  {value.lawArea}
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="tooltips-effect">
                                {value.actName.slice(0, 15)}
                                {value.actName.length > 15 ? "..." : ""}
                                <span className="tooltiptext">
                                  {value.actName}
                                </span>
                              </p>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  setStateModal(true);
                                  setStates(value.state);
                                }}
                              >
                                View State
                              </button>
                            </td>
                            <td>
                              <Link
                                to=""
                                onClick={() =>
                                  window.open(value.uploadFile, "_blank")
                                }
                              >
                                <span className="icon">
                                  <FaFile />
                                </span>
                              </Link>
                            </td>

                            <td>
                              {value.beforeText || value.afterText ? (
                                <Link
                                  to=""
                                  onClick={(e) =>
                                    handleNotificationDetailModel(
                                      value.beforeText,
                                      value.afterText
                                    )
                                  }
                                >
                                  <span className="icon">
                                    <FaEye />
                                  </span>
                                </Link>
                              ) : (
                                <span>N/A</span>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NotFound col={9} />
                      )}
                    </tbody>
                  </Table>
                  <div className="w-100 d-flex justify-content-center">
                    {loader}
                  </div>
                  <div className="Pagination">
                    <Pagination
                      activePage={getNotificationActiveCount}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={10}
                      totalItemsCount={getNotificationTotal}
                      pageRangeDisplayed={5}
                      onChange={handleNotificationPageChange}
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>

          {/* State Modal */}
          <Modal
            show={stateModal}
            onHide={() => setStateModal(false)}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design"
          >
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body className="py-0">
              <div className="data_table p-0" style={{ height: "17rem" }}>
                <Table striped bordered hover style={{ minWidth: "100%" }}>
                  <thead>
                    <tr className="custom_tr">
                      <th>State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {states && states.length > 0 ? (
                      states.map((value, i) => (
                        <tr key={++i} className="cstm_tr">
                          <td>{value}</td>
                        </tr>
                      ))
                    ) : (
                      <NotFound col={1} />
                    )}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design modal_cstm_design2 modal_cstm_design_6"
          >
            <span className="modalclose" onClick={handleClose}>
              {" "}
              X
            </span>
            <Modal.Body>
              <div className="modal_after_before">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="custom_col_modl">
                      <h6>Before</h6>
                      <div className="content_before_txt">
                        <p>{setBeforeDetail}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="custom_col_modl">
                      <h6>After</h6>
                      <div className="content_before_txt">
                        <p>{setAfterDetail}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  );
}

export default Library;
