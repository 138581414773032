import { api, AuthToken } from "./AuthController";

export async function companyData() {
  var token = await AuthToken();

  var res = await api.get(`register/companyListing`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function registrationList(data) {
  var token = await AuthToken();
  var res = await api.get(
    `/register/registrationListing?company=${data.company}&state=${
      data.state
    }&location&search=${data.location}&registeredBy=${
      data.registeredBy
    }&registrationType=${data.registrationType}&consultantStatus=${
      data.status || ""
    }&currentDate=${data.expiry || ""}&resubmitted=${
      data.resubmitted || ""
    }&page=${data.page}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res.data;
}

export async function getRegistrationDetail(id, page, startDate, endDate) {
  var token = await AuthToken();
  var res = await api.get(
    `/register/registrationDataById?registrationId=${id}&page=${page}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function getNormalRegistrationDetail(id) {
  var token = await AuthToken();

  var res = await api.get(
    `/register/particularRegistrationDetails?registrationId=${id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res.data;
}

export async function consultantList(companyId, search) {
  var token = await AuthToken();
  var res = await api.get(
    `/register/consultantList?companyId=${companyId}&search=${search}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function stateData(data) {
  var token = await AuthToken();

  var res = await api.get(
    `manage/locationListing?companyId=${data.companyId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function locationData(data) {
  var token = await AuthToken();

  var res = await api.get(
    `register/locations?companyId=${data.companyId}&state=${data.stateId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function addRegistration(data) {
  var token = await AuthToken();
  var res = await api.post(`/register/registraion`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function updateRegistration(data) {
  var token = await AuthToken();

  var res = await api.post(`/register/editRegistrationDetails`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function deleteRegistration(id) {
  var token = await AuthToken();

  var res = await api.get(`/register/deleteRegistration?registrationId=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function statusRegistration(data) {
  var token = await AuthToken();

  var res = await api.post(`/register/change_status`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function updateRegistrationStatus(data) {
  var token = await AuthToken();

  var res = await api.post(`/register/filledByConsultant`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function documentList(data) {
  var token = await AuthToken();

  var res = await api.get(
    `/register/registrationDocsData?registrationTypeId=${data.registrationTypeId}&state=${data.state}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function registrationType() {
  var token = await AuthToken();

  var res = await api.get(`/register/registartionTypeListing`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function remarkFromConsultant() {
  var token = await AuthToken();

  var res = await api.get(`register/remarksLisitng`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}
