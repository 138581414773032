import React, { useEffect, useState } from "react";
import "./Header.css";
import { FaBell, FaEye, FaEyeSlash } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import Logo from "../../assets/image/logo.png";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { isLoggedIn } from "../../utils";
import { notificationList } from "../../controller/NotificationService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { changePassword } from "../../controller/AuthController";
import useButtonLoader from "../../hooks/useButtonLoader";

function Header() {
  const history = useNavigate();
  const [allNotifications, setAllNotifications] = useState([]);
  const [passwordModal, setPasswordModal] = useState(false);
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Update Password",
    "Please wait..."
  );

  const [type, setType] = useState({
    old: "password",
    new: "password",
    confirm: "password",
  });

  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handelLogout = () => {
    // localStorage.removeItem("userLogin");
    localStorage.clear();
    history("/login");
  };

  const getAllNotification = async () => {
    try {
      const response = await notificationList(0);

      if (response.status === 200) {
        setAllNotifications(response.data.notificationListing || []);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err?.response?.err?.message);
    }
  };

  // password onchange handler
  const passwordOnchange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  // change password function
  const changePasswordHandler = async () => {
    if (!passwords["oldPassword"]) toast.error("Old Password is mandatory");
    else if (!passwords["newPassword"])
      toast.error("New Password is mandatory");
    else if (!passwords["confirmPassword"])
      toast.error("Confirm Password is mandatory");
    else if (passwords["confirmPassword"] !== passwords["newPassword"])
      toast.error("Confirm and New password must be the same");
    else {
      try {
        setSubmitLoading(true);
        const { oldPassword, newPassword } = passwords;
        const res = await changePassword({ oldPassword, newPassword });
        if (res.status === 200) {
          toast.success(res.message);
          setSubmitLoading(false);
          setPasswordModal(false);
          history("/login");
          localStorage.removeItem("userLogin");
        } else {
          toast.error(res.message);
          setSubmitLoading(false);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("userLogin")) {
      history("/login");
    }
    getAllNotification();
  }, []);

  return (
    <>
      <header className="header">
        <Navbar bg="light" expand="lg">
          <div className="container">
            <div className="menu_wrapper">
              <Navbar.Brand href="#home">
                <div className="header_content">
                  <div className="header_logo">
                    <Link
                      to={`${
                        isLoggedIn().role === "Consultant"
                          ? "/registration"
                          : "/"
                      }`}
                    >
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                </div>
              </Navbar.Brand>
              <div className="header_menu">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    {isLoggedIn().role !== "Consultant" ? (
                      <>
                        <NavLink to="/">Dashboard</NavLink>
                        <NavLink to="/compliance">Compliance</NavLink>
                        <NavLink to="/notices">Litigations</NavLink>
                        <NavLink to="/registration">Registration</NavLink>
                        <NavLink to="/legal-updates">Library</NavLink>
                        <NavLink to="/manage" className={"mx-3"}>
                          Manage
                        </NavLink>
                      </>
                    ) : (
                      <>
                        <NavLink to="/registration" className={"mx-3"}>
                          Registration
                        </NavLink>
                      </>
                    )}

                    {/* <NavLink to="#">{isLoggedIn().role}</NavLink> */}

                    <Dropdown className=" position-relative">
                      <Link
                        className="bg-transparent border-0"
                        to="/all-notification"
                      >
                        <span className="icon custom_btn2 p-1">
                          <FaBell />
                        </span>
                        {allNotifications.length > 0 && (
                          <span
                            class="badge rounded-pill badge-notification bg-danger position-absolute"
                            style={{ left: "6px", top: "-6px" }}
                          >
                            {allNotifications.length > 99
                              ? `${allNotifications.splice(0, 99).length}+`
                              : allNotifications.length}
                          </span>
                        )}
                      </Link>

                      <span className="user_roll">{isLoggedIn().role}</span>

                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {isLoggedIn().name}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="_dropdwon">
                        <Dropdown.Item onClick={() => setPasswordModal(true)}>
                          <span className="icon">
                            <FaLock />
                          </span>
                          Change Password
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) => {
                            handelLogout(e);
                          }}
                        >
                          <span className="icon">
                            <FaSignOutAlt />
                          </span>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </div>
        </Navbar>
      </header>
      {/* Modal */}
      <Modal
        show={passwordModal}
        onHide={() => {
          setPasswordModal(false);
          setPasswords({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title></Modal.Title>

        <Modal.Body>
          <div className="form_wrapper">
            <div className="wrapper-modal-div">
              <div className="">
                <div className="modal_exce w-100">
                  <p className="m-0 fw-bold">Old Password</p>
                  <div className="form_field position-relative">
                    <input
                      className="input"
                      type={type["old"]}
                      placeholder="Old Password"
                      name="oldPassword"
                      onChange={passwordOnchange}
                      value={passwords["oldPassword"]}
                      maxLength={30}
                    />
                    <span
                      role="button"
                      className="position-absolute"
                      style={{ top: "20%", right: "5%" }}
                      onClick={() =>
                        setType({
                          ...type,
                          old: type["old"] === "password" ? "text" : "password",
                        })
                      }
                    >
                      {type["old"] === "password" ? (
                        <i className="fa_eye">
                          <FaEyeSlash />
                        </i>
                      ) : (
                        <i className="fa_eye">
                          <FaEye />
                        </i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="modelpopup">
                <div className="modal_exce w-100">
                  <p className="m-0 fw-bold">New Password</p>
                  <div className="position-relative">
                    <input
                      className="input"
                      type={type["new"]}
                      placeholder="New Password"
                      name="newPassword"
                      onChange={passwordOnchange}
                      value={passwords["newPassword"]}
                      maxLength={30}
                    />
                    <span
                      role="button"
                      onClick={() =>
                        setType({
                          ...type,
                          new: type["new"] === "password" ? "text" : "password",
                        })
                      }
                      className="position-absolute"
                      style={{ top: "20%", right: "5%" }}
                    >
                      {type["new"] === "password" ? (
                        <i className="fa_eye">
                          <FaEyeSlash />
                        </i>
                      ) : (
                        <i className="fa_eye">
                          <FaEye />
                        </i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className=" modelpopup">
                <div className="modal_exce w-100">
                  <p className="m-0 fw-bold">Confirm Password</p>
                  <div className="position-relative">
                    <input
                      className="input"
                      type={type["confirm"]}
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={passwordOnchange}
                      value={passwords["confirmPassword"]}
                      maxLength={30}
                    />
                    <span
                      role="button"
                      className="position-absolute"
                      style={{ top: "20%", right: "5%" }}
                      onClick={() =>
                        setType({
                          ...type,
                          confirm:
                            type["confirm"] === "password"
                              ? "text"
                              : "password",
                        })
                      }
                    >
                      {type["confirm"] === "password" ? (
                        <i className="fa_eye">
                          <FaEyeSlash />
                        </i>
                      ) : (
                        <i className="fa_eye">
                          <FaEye />
                        </i>
                      )}
                    </span>
                  </div>
                </div>
                <button
                  ref={submitButtonElement}
                  className={`custom_btn2 my-3 w-100 ${
                    (!passwords["oldPassword"] ||
                      !passwords["newPassword"] ||
                      !passwords["confirmPassword"]) &&
                    "bg-secondary border border-transparent"
                  }`}
                  onClick={changePasswordHandler}
                  disabled={
                    !passwords["oldPassword"] ||
                    !passwords["newPassword"] ||
                    !passwords["confirmPassword"]
                  }
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;
