import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./Notices.css";
import { IoIosArrowBack } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { letigationDetailById } from "../../../controller/LetigationController";
import { toast } from "react-toastify";
import Header from "../../Layout/Header";
import { dateFormat } from "../../../utils";
import Modal from "react-bootstrap/Modal";
// import NotFound from "../../components/NotFound/NotFound";
import Table from "react-bootstrap/Table";

function NoticeDetails() {
  useEffect(() => {
    letigationDetail();
  }, []);

  const { id } = useParams();

  const [getLetigationDetail, setLetigationDetail] = useState({});
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState([]);

  const [getNewDocs, setNewDocs] = useState([]);
  const letigationDetail = async () => {
    var data = {
      id: id,
    };
    var res = await letigationDetailById(data);

    const myArrayOfDocs = [];
    if (res.status == 200) {
      console.log(res, "response");
      setLetigationDetail(res.data[0]);
      for (let i = 0; i < res.data[0].document.length; i++) {
        const myArray = res.data[0].document[i].split("/");
        var lastItem = myArray.pop();
        const myNameArray = lastItem.split(".");
        myArrayOfDocs.push({
          name: myNameArray[0],
          path: res.data[0].document[i],
        });
      }
      setNewDocs(myArrayOfDocs);
      console.log(myArrayOfDocs, "myArrayOfDOcs");
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_add_task wrapper_Notice_details">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/notices">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Noticed details</h5>
              </div>
            </div>
            <div className="wrap_row_cstm">
              <div className="row">
                <div className="col-lg-8 col-md-4">
                  <div className="col_cstm">
                    <div className="ul_list">
                      <ul type="none">
                        <li>
                          <span>Company name</span>
                          <b>
                            {getLetigationDetail
                              ? getLetigationDetail.company
                              : ""}
                          </b>
                        </li>
                        <li>
                          <span>Court/Authority/Council name</span>

                          <p className="tooltips-effect">
                            {getLetigationDetail.court?.length > 20
                              ? getLetigationDetail.court?.slice(0, 20) + "..."
                              : getLetigationDetail.court}
                            <span className="tooltiptext text-black">
                              {getLetigationDetail.court}
                            </span>
                          </p>
                        </li>
                        <li>
                          <span>Party name1</span>
                          <p className="tooltips-effect">
                            {getLetigationDetail.partyNameOne?.length > 20
                              ? getLetigationDetail.partyNameOne?.slice(0, 20) +
                                "..."
                              : getLetigationDetail.partyNameOne}
                            <span className="tooltiptext text-black">
                              {getLetigationDetail.partyNameOne}
                            </span>
                          </p>
                        </li>
                        <li>
                          <span>Hearing date</span>
                          <b>
                            {getLetigationDetail
                              ? dateFormat(getLetigationDetail.hearingDate)
                              : ""}
                          </b>
                        </li>
                        <li>
                          <span>Liability</span>
                          <b>
                            {getLetigationDetail
                              ? getLetigationDetail.liability
                              : ""}
                          </b>
                        </li>
                        <li>
                          <span>Authorized Represntative</span>

                          <p className="tooltips-effect">
                            {getLetigationDetail.authorizedRepresentative
                              ?.length > 20
                              ? getLetigationDetail.authorizedRepresentative?.slice(
                                  0,
                                  20
                                ) + "..."
                              : getLetigationDetail.authorizedRepresentative}
                            <span className="tooltiptext text-black">
                              {getLetigationDetail.authorizedRepresentative}
                            </span>
                          </p>
                        </li>
                        <li>
                          <span>Manager Emails</span>
                          <b>
                            {getLetigationDetail ? (
                              <button
                                className="custom_btn_2"
                                onClick={() => {
                                  setEmailModal(true);
                                  setEmail(getLetigationDetail.managerEmails);
                                }}
                              >
                                Manager Emails
                              </button>
                            ) : (
                              ""
                            )}
                          </b>
                        </li>
                        <li>
                          <span>Head Emails</span>
                          <b>
                            {getLetigationDetail ? (
                              <button
                                className="custom_btn_2"
                                onClick={() => {
                                  setEmailModal(true);
                                  setEmail(getLetigationDetail.headEmails);
                                }}
                              >
                                Head Emails
                              </button>
                            ) : (
                              ""
                            )}
                          </b>
                        </li>
                      </ul>
                    </div>
                    <div className="ul_list">
                      <ul type="none">
                        <li>
                          <span>Location</span>
                          <b>
                            {getLetigationDetail
                              ? getLetigationDetail.location
                              : ""}
                          </b>
                        </li>

                        {/* <li>
                          <span>Council Name</span>
                          <b>
                            {getLetigationDetail
                              ? getLetigationDetail.councilName
                              : ""}
                          </b>
                        </li> */}
                        <li>
                          <span>Party name2</span>

                          <p className="tooltips-effect">
                            {getLetigationDetail.partyNameTwo?.length > 20
                              ? getLetigationDetail.partyNameTwo?.slice(0, 20) +
                                "..."
                              : getLetigationDetail.partyNameTwo}
                            <span className="tooltiptext text-black">
                              {getLetigationDetail.partyNameTwo}
                            </span>
                          </p>
                        </li>
                        {/* <li>
                          <span>Next Hearing date</span>
                          <b>{getLetigationDetail ? getLetigationDetail.company : ''}</b>
                        </li> */}
                        <li>
                          <span>Status</span>
                          <b>
                            {getLetigationDetail
                              ? getLetigationDetail.letigtionStatus
                              : ""}
                          </b>
                        </li>
                        {/* <li>
                          <span>Executor Email</span>
                          <b>{getLetigationDetail ? getLetigationDetail.company : ''}</b>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col_Hearing">
                    <div className="ul_listing">
                      <ul type="none">
                        <li>
                          <span>Minutes of Hearing</span>
                          <b>
                            {getLetigationDetail
                              ? getLetigationDetail.minutesofHearingRemark
                              : ""}
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="col_cstm2">
                    <div className="data_table">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr className="custom_tr">
                            <th>S.No.</th>
                            <th>File Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getNewDocs && getNewDocs.length > 0
                            ? getNewDocs.map((value, i) => (
                                <tr className="cstm_tr">
                                  <td>{++i}</td>
                                  <td>{value.name}</td>
                                  <td>
                                    <a href={value.path} download>
                                      <span className="icon">
                                        <FiDownload />
                                      </span>
                                    </a>
                                    {/* <span className="Bs_download">
                                  <FiDownload />
                                </span> */}
                                  </td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Manager Email Modal  */}
          <Modal
            show={emailModal}
            onHide={() => setEmailModal(false)}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design"
          >
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body className="py-0">
              <div className="data_table p-0" style={{ height: "17rem" }}>
                <Table striped bordered hover style={{ minWidth: "100%" }}>
                  <thead>
                    <tr className="custom_tr">
                      <th>Emails</th>
                    </tr>
                  </thead>
                  <tbody>
                    {email && email.length > 0
                      ? email.map(({ email }, i) => (
                          <tr key={++i} className="cstm_tr">
                            <td>{email}</td>
                          </tr>
                        ))
                      : "Not Found"}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal>

          {/* End  */}

          {/* Head Emais  */}
          <Modal
            show={emailModal}
            onHide={() => setEmailModal(false)}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design"
          >
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body className="py-0">
              <div className="data_table p-0" style={{ height: "17rem" }}>
                <Table striped bordered hover style={{ minWidth: "100%" }}>
                  <thead>
                    <tr className="custom_tr">
                      <th>Emails</th>
                    </tr>
                  </thead>
                  <tbody>
                    {email && email.length > 0
                      ? email.map(({ email }, i) => (
                          <tr key={++i} className="cstm_tr">
                            <td>{email}</td>
                          </tr>
                        ))
                      : "Not Found"}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal>

          {/* End  */}
        </div>
      </section>
    </>
  );
}

export default NoticeDetails;
