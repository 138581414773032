import React from "react";
import { Routes, Route } from "react-router-dom";
// import Home from "../page/consultant/Home-page/Home";
// import Home from "../page/executor/Home-page/Home";
// import Home from "../page/manager/Home-page/Home";
import Login from "../page/Authentication/Login";
import Forgot from "../page/Authentication/Forgot";
import Succesful from "../page/Authentication/Succesful";
import ChangePassword from "../page/Authentication/ChangePassword";
import Dashboard from "../page/executor/Home-page/Home";
import ManagerDashboard from "../page/manager/Home-page/Home";
// import Compliance from "../page/executor/compliance/Compliance";
import ManagerCompliance from "../page/manager/compliance/Compliance";
// import Compliances from "../page/Compliance/Complaince";
import ManagerComplianceDetail from "../page/manager/compliance/ComplianceDetailPage";
import Remarks from "../page/manager/compliance/Remarks";
import Notices from "../page/executor/notices/Notices";
import ManagerNotice from "../page/manager/notices/Notices";
import ManagerAddNotices from "../page/manager/notices/AddNotices";
import ManagerNoticeDetail from "../page/manager/notices/NoticeDetails";
import AddNotices from "../page/executor/notices/AddNotices";
// import Registration from "../page/executor/resigtration/Registration";
import Registration from "../page/Registration/Registration";
import ManagerRegistration from "../page/manager/resigtration/Registration";
import Library from "../page/executor/library/Library";
import ManagerLibrary from "../page/manager/library/Library";
import LegalUpdates from "../page/Library/Library";
import ManagerManage from "../page/manager/manage/Manage";
import ManagerAddTask from "../page/manager/manage/AddTask";
import AddTask from "../page/executor/manage/AddTask";
// import ViewActs from "../page/executor/compliance/ViewActs";
import ViewActs from "../page/Compliance/ViewActs";
import Applicaple from "../page/executor/compliance/Applicaple";
import NotApplicable from "../page/executor/compliance/NotApplicable";
import Notification from "../page/executor/notification/Notification";
import ManagerNotification from "../page/manager/notification/Notification";
import RegistrationDetail from "../page/executor/resigtration/RegistrationDetail";
import NoticeDetail from "../page/Registration/NoticeDetail";
import Upload from "../page/executor/resigtration/Upload";
import RegistrationDetails from "../page/consultant/resigtration/RegistrationDetails";
import UploadStatus from "../page/consultant/resigtration/UploadStatus";
import RegistrationFinal from "../page/consultant/resigtration/RegistrationFinal";
import Home from "../page/Home/index";
import Compliance from "../page/Compliance/Compliance";
import NoticeDetails from "../page/executor/notices/NoticeDetails";
import EditNotices from "../page/executor/notices/EditNotices";
import Manage from "../page/manager/manage/Manage";
import ViewTask from "../page/executor/manage/ViewTask";
import MultiNoticeDetails from "../page/executor/notices/MultiNoticeDetail";
import EditTask from "../page/executor/manage/EditTask";
import { isLoggedIn } from "../utils";
import EditRegistration from "../page/Registration/EditRegistration";
import RenewRegistration from "../page/Registration/RenewRegistration";

function Routing() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/succesful" element={<Succesful />} />
        <Route path="/reset-password/:token" element={<ChangePassword />} />

        {/*--------------------executor------------------------------*/}
        <Route path="/executor/dashboard" element={<Dashboard />} />
        {/* <Route path="/executor/compliance" element={<Compliance />} /> */}
        {/* <Route path="/executor/compliance/view-acts" element={<ViewActs />} /> */}
        <Route
          path="/compliance/view-acts/:location/:uin/:id"
          element={<ViewActs />}
        />
        <Route
          path="/executor/compliance/view-acts/applicaple"
          element={<Applicaple />}
        />
        <Route
          path="/executor/compliance/view-acts/not-applicable"
          element={<NotApplicable />}
        />

        <Route path="/notices/:type?" element={<Notices />} />
        <Route path="/notices/add-notices" element={<AddNotices />} />
        <Route path="/notices/notice-details/:id" element={<NoticeDetails />} />
        <Route
          path="/notices/multi-notice-details/:id"
          element={<MultiNoticeDetails />}
        />
        <Route path="/notices/edit-notice/:id" element={<EditNotices />} />
        <Route path="/registration/:type?" element={<Registration />} />
        <Route
          path="/registration/registration_detail/:id"
          element={<RegistrationDetail />}
        />
        <Route path="/registration/notice_detail" element={<NoticeDetail />} />
        <Route path="/registration/edit/:id" element={<EditRegistration />} />
        <Route path="/registration/renew/:id" element={<RenewRegistration />} />
        <Route path="/executor/registration/Upload" element={<Upload />} />

        <Route path="/executor/library" element={<Library />} />
        <Route path="/manage" element={<Manage />} />
        <Route path="/manage/add-task" element={<AddTask />} />
        <Route path="/manage/edit-task/:id" element={<EditTask />} />
        <Route path="/manage/view-task/:id" element={<ViewTask />} />
        <Route path="/all-notification" element={<Notification />} />

        {/*-----------------------Consultant----------------------------------*/}
        <Route
          path="/consultant/registration/registration_detail"
          element={<RegistrationDetails />}
        />
        <Route
          path="/consultant/registration/registration-detail-final"
          element={<RegistrationFinal />}
        />
        <Route
          path="/consultant/registration/registration_detail/upload-status"
          element={<UploadStatus />}
        />
        {/*--------------------------Manager-----------------------------*/}

        <Route path="/manager/dashboard" element={<ManagerDashboard />} />
        <Route path="/manager/compliance" element={<ManagerCompliance />} />
        <Route path="/compliance/:type?" element={<Compliance />} />
        <Route path="/manager/notices" element={<ManagerNotice />} />
        <Route
          path="/manager/notices/add-notices"
          element={<ManagerAddNotices />}
        />
        <Route
          path="/manager/notices/notice-details"
          element={<ManagerNoticeDetail />}
        />
        <Route path="/manager/legal-updates" element={<ManagerLibrary />} />
        <Route path="/legal-updates" element={<LegalUpdates />} />
        <Route
          path="/manager/compliance/compliance-detail-page/:location/:uin/:id"
          element={<ManagerComplianceDetail />}
        />
        <Route
          path="/manager/compliance/compliance-detail-page/Remarks"
          element={<Remarks />}
        />
        <Route path="/manager/registration" element={<ManagerRegistration />} />
        <Route path="/manager/manage" element={<ManagerManage />} />
        <Route path="/manager/manage/add-task" element={<ManagerAddTask />} />
        <Route path="/manager/notification" element={<ManagerNotification />} />
      </Routes>
    </>
  );
}

export default Routing;
