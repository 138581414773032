import React from "react";
import { Pie } from "react-chartjs-2";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ chartData }) => {
  // const [series, setSeries] = useState([0, 0, 0]);
  const data = {
    labels: ["High", "Moderate", "Low"],
    datasets: [
      {
        label: "Risk Analysis",
        data: [chartData?.highRisk, chartData?.modrateRisk, chartData?.lowRisk],
        backgroundColor: ["#e74c3c", "#ffb127", "#2ecc71"],
        borderColor: ["#e74c3c", "#ffb127", "#2ecc71"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
