import React from "react";
import "./Header.css";
import { FaBell } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import Logo from "../../../assets/image/logo.png";
import { Link, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { isLoggedIn } from "../../../utils";
import { useNavigate } from "react-router-dom";

function Header() {
  const history = useNavigate();

  if (!localStorage.getItem("userLogin")) {
    history("/login");
  }
  const handelLogout = () => {
    localStorage.removeItem("userLogin");
    history("/login");
  };
  return (
    <Navbar bg="light" expand="lg">
      <div className="container">
        <div className="menu_wrapper">
          <Navbar.Brand href="#home">
            <div className="header_content">
              <div className="header_logo">
                <Link to="/">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
            </div>
          </Navbar.Brand>
          <div className="header_menu">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              {
                
              }
                <NavLink to="/dashboard">Dashboard</NavLink>
                <NavLink to="/compliance">Compliance</NavLink>
                <NavLink to="/notices">Litigations</NavLink>
                <NavLink to="/registration">Registration</NavLink>
                <NavLink to="/library">Library</NavLink>
                <NavLink to="/manage">Manage</NavLink>

                <Dropdown>
                  <Link to="/notification">
                    <span className="icon">
                      <FaBell />
                    </span>
                  </Link>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {isLoggedIn().name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <span className="icon">
                        <FaLock />
                      </span>
                      Change Password
                    </Dropdown.Item>

                    <Dropdown.Item
                      href=""
                      onClick={(e) => {
                        handelLogout(e);
                      }}
                    >
                      <span className="icon">
                        <FaSignOutAlt />
                      </span>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;
