import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Notices.css";
import Pagination from "react-js-pagination";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaEye } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import Header from "../../Layout/Header";
// import Header from "../header/Header";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAllLetigations,
  getCompany,
} from "../../../controller/LetigationController";
import { toast } from "react-toastify";
import moment from "moment";
import { countArray, dateFormat, isLoggedIn } from "../../../utils";
import { getLitigationDashboards } from "../../../controller/DashboradController";
import { FiLoader, FiRefreshCcw } from "react-icons/fi";
import { Url } from "../../../config/config";
import CardSkeleton from "../../../components/skeleton/CardSkeleton";
import Tooltip from "../../../components/Tooltip/Tooltip";

function Notices() {
  const [startDates, setStartDates] = useState();
  const { type } = useParams();

  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [litigationData, setLitigationData] = useState({});

  const [selectedOptions, setSelectedOptions] = useState();
  const [dateSearch, setDateSearch] = useState("");
  const [dateSearch2, setDateSearch2] = useState("");
  const [dashboardLoading, setDashboardLoading] = useState(false);

  // Array of all options
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const [getLetigations, setLetigations] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [getSlectedStatus, setSlectedStatus] = useState("");
  const navigate = useNavigate();

  const letigations = async (
    page = activePage,
    company = companySearch,
    search = getSearch,
    date = dateSearch,
    toDate = dateSearch2,
    statusData = getSlectedStatus
  ) => {
    try {
      const statusSchema = {
        open: "open",
        close: "close",
        "no-action-required": "noActionRequired",
        "due-today": "dueToday",
        "upcoming-hearing": "upcomingHearing",
      };
      var data =
        statusSchema[type] === "dueToday"
          ? {
              company: company,
              location: "",
              search: search,
              page: page,
              dueToday: moment().format("YYYY-MM-DD"),
            }
          : {
              company: company,
              location: "",
              search: search,
              page: page,
              status:
                statusData !== "upcomingHearing"
                  ? statusData === ""
                    ? statusSchema[type]
                    : statusData
                  : "",
              currentDate:
                statusData === "upcomingHearing"
                  ? moment().format("YYYY-MM-DD")
                  : "",
            };

      if (dateSearch && dateSearch2) {
        data.hearingDate = moment(date).format("YYYY-MM-DD");
        data.toDate = moment(toDate).format("YYYY-MM-DD");
      }

      var res = await getAllLetigations(data);

      if (res.status == 200) {
        setLetigations(res.data.letigationListing);
        setTotal(res.data.total);
      } else {
        toast.error(res.message);
      }
    } catch (error) {}
  };

  const [getCompanies, setCompany] = useState([]);
  const getCompanyList = async () => {
    var res = await getCompany();
    if (res.status == 200) {
      setCompany(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const [companySearch, setCompanySearch] = useState("");
  const [getSearch, setSearch] = useState("");

  const handleSearch = (name, e) => {
    if (name == "company") {
      setCompanySearch(e.target.value);
      letigations(activePage, e.target.value, getSearch, dateSearch);
    } else if (name == "search") {
      setSearch(e.target.value);
      letigations(activePage, companySearch, e.target.value, dateSearch);
    } else if (name == "date") {
      setDateSearch(e);
    } else if (name == "date2") {
      setDateSearch2(e);
    }
  };

  const handelStatusSelect = (e) => {
    setSlectedStatus(e.target.value);
    if (e.target.value === "") {
      navigate("/notices");
      window.location.reload();
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    letigations(page);
  };

  useEffect(() => {
    getCompanyList();
    letigations();
  }, []);

  // route filter
  useEffect(() => {
    getCompanyList();

    // route filter
    const statusSchema = {
      open: "open",
      close: "close",
      "no-action-required": "noActionRequired",
      "due-today": "dueToday",
      "upcoming-hearing": "upcomingHearing",
    };
    if (type) {
      setSlectedStatus(statusSchema[type]);
    }
    letigations();

    // eslint-disable-next-line
  }, []);

  // *********get dashboard details**********
  useEffect(() => {
    const getLitigationDashboard = async () => {
      try {
        setDashboardLoading(true);
        var res = await getLitigationDashboards();
        if (res.status === 200) {
          setLitigationData(res.data);
          setDashboardLoading(false);
        } else {
          toast.error(res.message);
          setDashboardLoading(false);
        }
      } catch (err) {
        toast.error(err);
        setDashboardLoading(false);
      }
    };
    getLitigationDashboard();
  }, []);

  useEffect(() => {
    if (dateSearch && dateSearch2) {
      letigations();
    }
    // eslint-disable-next-line
  }, [dateSearch, dateSearch2]);

  useEffect(() => {
    if (getSlectedStatus) {
      letigations();
    }
  }, [getSlectedStatus]);

  // reset filter
  const resetFilter = () => {
    setCompanySearch("");
    setSearch("");
    setDateSearch("");
    setDateSearch2("");
    setSlectedStatus("");
    setFlag(!flag);
    navigate("/notices");
    window.location.reload();
  };

  useEffect(() => {
    const statusSchema = {
      open: "open",
      close: "close",
      "no-action-required": "noActionRequired",
      "due-today": "dueToday",
      "upcoming-hearing": "upcomingHearing",
    };
    letigations(
      activePage,
      companySearch,
      getSearch,
      dateSearch,
      dateSearch2,
      statusSchema[type]
    );
    // eslint-disable-next-line
  }, [flag]);

  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_compliance wrapper_Notices">
      <Header />
      <div className="container">
        <div className="wrapper_cards_consultant">
          <div className="cards_consult">
            <div className="cstm_cards_cols">
              {/* loading skeleton */}
              {dashboardLoading && (
                <>
                  {countArray(10).map((data) => {
                    return (
                      <div
                        className="small-box custom_div_styling p-0"
                        key={data}
                      >
                        <CardSkeleton />
                      </div>
                    );
                  })}
                </>
              )}
              {/* main component */}
              {!dashboardLoading && (
                <>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/notices");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{litigationData.totalLetigation || 0}</h3>
                      <p>Total</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/notices/open");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{litigationData.totalOpenLetigation || 0}</h3>
                      <p>Open</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/notices/close");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{litigationData.totalCloseLetigation || 0}</h3>
                      <p>Closed</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/notices/no-action-required");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{litigationData.totalNoActionLetigation || 0}</h3>
                      <p>No Action Required</p>
                    </div>
                  </a>

                  <a
                    role="button"
                    onClick={() => {
                      navigate("/notices/due-today");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{litigationData.countTodayDue || 0}</h3>
                      <p>Due Today</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/notices/upcoming-hearing");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{litigationData.totalUpcomingLetigation || 0}</h3>
                      <p>Upcoming Hearing</p>
                    </div>
                  </a>
                </>
              )}
            </div>
          </div>
          <div className="wrapper_custom_search">
            <div className="custom_search">
              <div className="form_field_wrap">
                <div className="form_field">
                  <select
                    className="input"
                    onChange={(e) => handleSearch("company", e)}
                    value={companySearch}
                  >
                    <option value="">Choose company</option>
                    {getCompanies && getCompanies.length > 0
                      ? getCompanies.map((value, i) => (
                          <option value={value.companyName}>
                            {value.companyName}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>

              <div className="form_field_wrap">
                <div className="form_tempo">
                  <input
                    type="search"
                    className="input"
                    placeholder="Search"
                    onChange={(e) => handleSearch("search", e)}
                    value={getSearch}
                  />
                </div>
              </div>

              <div className="form_field">
                <div className="flex_direction_cstm">
                  <DatePicker
                    selected={dateSearch}
                    onChange={(e) => handleSearch("date", e)}
                    className="input input1"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  />
                </div>
              </div>

              <div className="form_field">
                <div className="flex_direction_cstm">
                  <DatePicker
                    selected={dateSearch2}
                    className="input input1"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    onChange={(e) => handleSearch("date2", e)}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  />
                </div>
              </div>

              <div className="form_field_wrap">
                <div className="form_field">
                  <select
                    className="input"
                    onChange={(e) => handelStatusSelect(e)}
                    value={getSlectedStatus}
                  >
                    <option value="">All Status</option>
                    <option value={"open"}>Open</option>
                    <option value={"close"}>Close</option>
                    <option value={"upcomingHearing"}>Upcoming Hearing</option>
                    <option value={"noActionRequired"}>
                      No Action Required
                    </option>
                    <option value={"dueToday"}>Due Today</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="custom_search p-0">
              <div className="form_field_wrap data_date">
                <div className="form_field">
                  <button
                    className="download-excel"
                    onClick={() =>
                      window.open(
                        `${Url}/letigation/downloadLetigationExcel?search=${getSearch}&company=${companySearch}&location=&hearingDate=${
                          dateSearch && moment(dateSearch).format("YYYY-MM-DD")
                        }&toDate=${
                          dateSearch2 && moment(dateSearch).format("YYYY-MM-DD")
                        }&status&email=${
                          localStorage.getItem("userLogin") &&
                          JSON.parse(localStorage.getItem("userLogin")).email
                        }&role=${
                          localStorage.getItem("userLogin") &&
                          JSON.parse(localStorage.getItem("userLogin")).role
                        }`
                      )
                    }
                  >
                    <span>
                      <BsDownload />
                    </span>
                    Download
                  </button>
                </div>
              </div>

              <div className="textbar d-flex">
                <div className="form_field">
                  <button className="download-excel" onClick={resetFilter}>
                    <FiRefreshCcw /> Reset
                  </button>
                </div>
                {isLoggedIn().role === "Executor" && (
                  <Link
                    to="/notices/add-notices"
                    className="custom_btn2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    + Add Litigation
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="wrapper_custom_search">
            <div className="custom_search p-0"></div>
          </div>
          <div className="data_table overflow-hidden">
            <Table striped bordered hover>
              <thead>
                <tr className="custom_tr">
                  <th>S.NO.</th>
                  <th className="text-center">COURT/AUTHORITY/COUNCIL NAME</th>
                  <th>Party Name 1</th>
                  <th>Party Name 2</th>
                  <th className="text-center">Authorized Represntative</th>
                  <th className="text-center">NEXT DATE OF HEARING</th>
                  <th>LIABILITY</th>
                  <th>LOCATION</th>
                  <th>STATUS</th>
                  {/* <th>MORE</th> */}
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {getLetigations && getLetigations.length > 0 ? (
                  getLetigations.map((value, i) => (
                    <tr className="cstm_tr">
                      <td>{++i + (activePage - 1) * 10}</td>
                      <td>
                       
                        <Tooltip limit={25}>{value.court}</Tooltip>
                      </td>
                      <td>
                        <Tooltip limit={25}>{value.partyName1}</Tooltip>
                      </td>
                      <td>
                        <Tooltip limit={25}>{value.partyName2}</Tooltip>
                      </td>
                      <td>
                       
                        <Tooltip limit={25}>{value.authRepresntative}</Tooltip>
                      </td>
                      <td>{dateFormat(value.hearingDate)}</td>
                      <td>
                        <p className="tooltips-effect">
                          {value.liability?.length > 20
                            ? value.liability?.slice(0, 20) + "..."
                            : value.liability}
                          <span className="tooltiptext">{value.liability}</span>
                        </p>
                      </td>
                      <td>
                       
                        <Tooltip limit={25}>{value.location}</Tooltip>
                      </td>
                      <td>{value.letigtionStatus}</td>
                      {/* <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        More
                      </Button>
                    </td> */}
                      <td className="d-flex">
                        <Link
                          to={`/notices/multi-notice-details/${value.letigationId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="icon">
                            <FaEye />
                          </span>
                        </Link>
                        {isLoggedIn().role == "Executor" ? (
                          <Link
                            to={`/notices/edit-notice/${value.letigationId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="icon">
                              <FaRegEdit />
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="cstm_tr">
                    <td colSpan="10">
                      <div className="text-center">No Data Found</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          </div>
          {/* Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Title></Modal.Title>

            <Modal.Body>
              <div className="wrapper-modal-div">
                <div className="modal_uin">
                  <div className="modal_exce">
                    <p>UIN Number</p>
                    <span>34224232</span>
                  </div>
                  <div className="modal_exce">
                    <p>Executor</p>
                    <span>kapil@gmail.com</span>
                  </div>
                </div>
                <div className="modal_uin modelpopup">
                  <div className="modal_exce">
                    <p>Manager</p>
                    <span>sourabh@gmail.com</span>
                  </div>
                  <div className="modal_exce">
                    <p>Periodicity</p>
                    <span>Annualy</span>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </section>
  );
}

export default Notices;
