import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Compliance.css";
import moment from "moment";
// import Header from "../header/Header";
import file from "../../../assets/image/file.png";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  managerComplianceDetails,
  storeManagerAcceptRejectComp,
} from "../../../controller/ComplianceController";
import Header from "../../Layout/Header";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { imageUrl } from "../../../config/config";
import ManagerComplianceHistory from "../../../components/ComplianceHistory/ManagerComplianceHistory";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { dateFormat } from "../../../utils";
import { Accordion } from "react-bootstrap";

function ComplianceDetailPage() {
  const navigate = useNavigate();
  const { uin, id, location } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // full page loader
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [getManagerComplianceDetail, setManagerComplianceDetail] = useState();
  const [managerComplianceHistory, setManagerComplianceHistory] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Approve",
    "Please wait..."
  );

  const [editButtonElem, setEditButtonLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  const [rejectButtonElem, setRejectButtonLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  const managerComplianceDetailList = async (startDate = "", endDate = "") => {
    var data = {
      id: id,
      uin: uin,
      startDate: startDate,
      endDate: endDate,
    };
    showLoader();
    let res = await managerComplianceDetails(data);
    hideLoader();
    if (res.status === 200) {
      setManagerComplianceDetail(res.data[0]);
      setManagerComplianceHistory(res.data.length > 1 ? res.data.slice(1) : []);
      setDocuments(res.data[0].imageArray || []);
    } else {
      toast.error(res.message);
    }
  };

  const [getRemark, setRemark] = useState("");

  const [editRemark, setEditRemark] = useState("");
  const [editRemarkModal, setEditRemarkModal] = useState(false);

  const editSubmit = async () => {
    setEditButtonLoading(true);
    if (!editRemark) {
      toast.error("Please Enter Remark");
      setEditButtonLoading(false);
    } else {
      var data = {
        type: 3,
        companyComplianceId: id,
        location: location,
        executerStatusId: getManagerComplianceDetail._id,
        remarkFromManager: [editRemark],
      };

      var res = await storeManagerAcceptRejectComp(data);
      if (res.status === 200) {
        toast.success("Success");
        setEditButtonLoading(false);
        setEditRemark("");
        navigate("/compliance");
      } else {
        setEditButtonLoading(false);
        toast.error(res.message);
      }
    }
  };

  const rejectSubmit = async () => {
    setRejectButtonLoading(true);
    if (!getRemark) {
      toast.error("Please Enter Remark");
      setRejectButtonLoading(false);
    } else {
      var data = {
        type: 1,
        companyComplianceId: id,
        location: location,
        executerStatusId: getManagerComplianceDetail._id,
        remarkFromManager: [getRemark],
      };

      var res = await storeManagerAcceptRejectComp(data);

      if (res.status === 200) {
        toast.success("Success");
        setRejectButtonLoading(false);
        setRemark("");
        navigate("/compliance");
      } else {
        setRejectButtonLoading(false);
        toast.error(res.message);
      }
    }
  };

  const handleApprove = async () => {
    setSubmitLoading(true);
    var data = {
      type: 2,
      companyComplianceId: id,
      location: location,
      executerStatusId: getManagerComplianceDetail._id,
    };

    var res = await storeManagerAcceptRejectComp(data);

    if (res.status === 200) {
      toast.success("Success");
      managerComplianceDetailList();
      setSubmitLoading(false);
    } else {
      toast.error(res.message);
      setSubmitLoading(false);
    }
  };

  const clearFilterHandler = () => {
    if (filterStartDate || filterEndDate) {
      managerComplianceDetailList();
      setFilterStartDate("");
      setFilterEndDate("");
    }
  };

  useEffect(() => {
    managerComplianceDetailList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      managerComplianceDetailList(filterStartDate, filterEndDate);
    }
  }, [filterStartDate, filterEndDate]);

  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_complaince_detail_page">
      <Header />
      <div>
        <div className="container">
          <div className="Managercompliance_details">
            {getManagerComplianceDetail?.complianceName && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="form_filed">
                    <ul>
                      <li>
                        <span>Compliance name</span>
                        <h5>
                          {getManagerComplianceDetail
                            ? getManagerComplianceDetail.complianceName
                            : ""}
                        </h5>
                      </li>
                      <li>
                        <span>Compliance Date</span>
                        <h5>
                          {getManagerComplianceDetail
                            ? dateFormat(
                                getManagerComplianceDetail.complianceDate
                              )
                            : ""}
                        </h5>
                      </li>
                      <li>
                        <div className="row">
                          <div className="col-6">
                            <span>Compliance Start Date</span>
                            <h5>
                              {getManagerComplianceDetail
                                ? dateFormat(
                                    getManagerComplianceDetail.complianceStartDate
                                  )
                                : ""}
                            </h5>
                          </div>
                          <div className="col-6">
                            <span>Compliance End Date</span>
                            <h5>
                              {getManagerComplianceDetail
                                ? dateFormat(
                                    getManagerComplianceDetail.complianceEndDate
                                  )
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </li>
                      {/* <li>
                        <span>Compliance Upload Date</span>
                        <h5>
                          {getManagerComplianceDetail
                            ? dateFormat(
                                getManagerComplianceDetail.complianceUploadedDate
                              )
                            : ""}
                        </h5>
                      </li> */}
                      <li>
                        <span>Penalty Interest</span>
                        <h5>
                          {getManagerComplianceDetail
                            ? getManagerComplianceDetail.penalityIntrest
                            : ""}
                        </h5>
                      </li>
                      <li>
                        <span className="radio_copybtn"></span>
                        <h5>
                          {getManagerComplianceDetail?.complied === "--"
                            ? "Not Applicable"
                            : getManagerComplianceDetail?.complied}
                        </h5>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form_filed">
                    <div className="heads_text">
                      <h4>Attachments</h4>
                    </div>
                    <div className="wrapper_compliance">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr className="custom_tr">
                            <th>Documents</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.length > 0 ? (
                            documents.map((file) => {
                              return (
                                <tr className="cstm_tr" key={file._id}>
                                  <td>
                                    <div className="d-flex justify-content-between">
                                      <span>{file.images}</span>
                                      <button
                                        className="custom_btn btn btn-primary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.open(
                                            `${imageUrl}${file.images}`
                                          );
                                        }}
                                      >
                                        Download
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>No Documents Attached</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="remarks_manager_detail">
                      <div className="heads_text">
                        <h4>Remarks</h4>
                      </div>
                      <p>
                        {getManagerComplianceDetail
                          ? getManagerComplianceDetail.remarks
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                {(getManagerComplianceDetail &&
                  getManagerComplianceDetail.managerComplianceStatus ===
                    "complied") ||
                getManagerComplianceDetail?.managerComplianceStatus ===
                  "success" ? (
                  <div className="manager_compliance_btn">
                    <button
                      className="btn-primary5"
                      type="button"
                      onClick={() => setEditRemarkModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                ) : getManagerComplianceDetail?.managerComplianceStatus ===
                  "rejected" ? (
                  <div className="manager_compliance_btn">
                    <button className="btn-primary5" disabled>
                      Rejected
                    </button>
                  </div>
                ) : (
                  <div className="manager_compliance_btn">
                    <button
                      className="btn-primary5"
                      type="button"
                      onClick={handleApprove}
                      ref={submitButtonElement}
                    >
                      Approve
                    </button>
                    <button className="btn-primary5" onClick={handleShow}>
                      Reject
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* manager compliance history */}
          <div className="row">
            <div className="col-7">
              <h3>Compliance History</h3>
            </div>
            <div className="col-2 my-auto">
              <input
                type="date"
                className="form-control"
                value={filterStartDate}
                onChange={(e) => setFilterStartDate(e.target.value)}
                name=""
                id=""
              />
            </div>
            <div className="col-2 my-auto">
              <input
                type="date"
                className="form-control"
                value={filterEndDate}
                onChange={(e) => setFilterEndDate(e.target.value)}
                name=""
                id=""
              />
            </div>
            <div className="col-1">
              <div className="applicable_not">
                <button
                  className="btn-primary rounded-3"
                  onClick={clearFilterHandler}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          {managerComplianceHistory.length > 0 ? (
            <>
              <div className="container-fluid manager-compliance-history py-3">
                <Accordion className="accordion_wrap" defaultActiveKey="1">
                  {managerComplianceHistory &&
                    managerComplianceHistory.map((compliance, i) => {
                      return (
                        <Accordion.Item eventKey={i} key={i}>
                          <Accordion.Header>
                            <h6 className="text-capitalize m-0">
                              {`${++i}.${compliance.complianceName} `}
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ManagerComplianceHistory compliance={compliance} />
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              </div>
            </>
          ) : (
            <div className="text-center">
              <h5>History Not Available</h5>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design7"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title></Modal.Title>

        <Modal.Body>
          <div className="wrapper-modal-div">
            <textarea
              rows="3"
              //   cols="100"
              maxlength="250"
              placeholder="Enter Remarks"
              value={getRemark}
              onChange={(e) => setRemark(e.target.value)}
            ></textarea>
          </div>
          <div className="footer_sub_btn wrapper_praans_consultant_dashboard ">
            {/* <Link
              to="/manager/compliance/compliance-detail-page/Remarks"
              className="link_style_btn"
            >
              Submit
            </Link> */}
            <button
              type="button"
              className="btn-primary rounded-3"
              onClick={rejectSubmit}
              ref={rejectButtonElem}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editRemarkModal}
        onHide={() => setEditRemarkModal(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design7"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title></Modal.Title>

        <Modal.Body>
          <div className="wrapper-modal-div">
            <textarea
              rows="3"
              //   cols="100"
              maxlength="250"
              placeholder="Enter Remarks"
              value={editRemark}
              onChange={(e) => setEditRemark(e.target.value)}
            ></textarea>
          </div>
          <div className="footer_sub_btn wrapper_praans_consultant_dashboard ">
            <button
              type="button"
              className="btn-primary rounded-3"
              onClick={editSubmit}
              ref={editButtonElem}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default ComplianceDetailPage;
