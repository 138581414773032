import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Login.css";
import Logo from "../../assets/image/logo12.png";
import { toast } from "react-toastify";
import { Url } from "../../config/config";
import useButtonLoader from "../../hooks/useButtonLoader";
import axios from "axios";

function ChangePassword() {
  const history = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setError] = useState({ newPassword: "", confirmPassword: "" });
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Update Password",
    "Please wait..."
  );
  //*************Handel Validation************* */
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!newPassword && newPassword.length === 0) {
      formIsValid = false;
      errors["newPassword"] = "Please enter new password";
    }
    if (newPassword && newPassword.length < 8) {
      formIsValid = false;
      errors["newPassword"] =
        "Password must be greater than or equal to 8 character";
    }
    if (!confirmPassword) {
      formIsValid = false;
      setConfirmPassword("");
      errors["confirmPassword"] = "Please enter confirm password";
    }

    if (confirmPassword !== newPassword) {
      formIsValid = false;
      setConfirmPassword("");
      errors["confirmPassword"] =
        "Confirm password must be equal to new password";
    }
    setError(errors);
    return formIsValid;
  };

  //***********Update Password***************/
  const UpdatePassword = async (e) => {
    e.preventDefault();
    try {
      if (handleValidation()) {
        setSubmitLoading(true);
        const res = await axios({
          url: `${Url}/reset`,
          method: "POST",
          data: {
            token: token,
            password: newPassword,
            //newPassword,
          },
        });
        setSubmitLoading(false);
        if (res.data.status === 200) {
          localStorage.removeItem("emailId");
          toast.success(res.data.message);
          history("/login");
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (err) {
      console.log("err", err);
      toast.error("Something wents wrong ,please try again");
    }
  };
  return (
    <section className="wrapper_log_in_page">
      <title>Praans | Forgot Password</title>
      <div className="log_in_page">
        <div className="text_governance">
          <div className="text_governance_flow">
            <div className="button_wrap">
              <span>Governance</span>
              <span>Risk</span>
            </div>
            <div className="button_wrap">
              <span>Compliance</span>
            </div>
          </div>
          <div className="company_bottom_text">
            <p>A company without any rule is no company at all</p>
          </div>
        </div>
        <div className="log_in_form">
          <div className="praans_logo">
            <figure className="image_set">
              <img src={Logo} alt="praans" />
            </figure>
            <div className="form_wrapper">
              <form>
                <h3>Change Password</h3>
                <div className="form_field">
                  <input
                    className="input"
                    type="password"
                    placeholder=" Enter New Password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors.newPassword}</span>
                </div>
                <div className="form_field">
                  <input
                    className="input"
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors.confirmPassword}</span>
                </div>

                <div className="button_wrap">
                  <button
                    className="custom_button"
                    onClick={UpdatePassword}
                    ref={submitButtonElement}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChangePassword;
