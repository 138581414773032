import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./Registration.css";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import { getRegistrationDetail } from "../../../controller/RegistrationController";
import moment from "moment";
import Header from "../../Layout/Header";
import { dateFormat, isLoggedIn, stringTrimmer } from "../../../utils";
import { Accordion } from "react-bootstrap";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
// import NotFound from "../../components/NotFound/NotFound";
import Table from "react-bootstrap/Table";

import UpdateRegistrationStatus from "../../../components/Registration/UpdateRegistrationStatus";
import Pagination from "react-js-pagination";

function RegistrationDetail() {
  const { id } = useParams();
  const [proceedModal, setProceedModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [consultantDoc, setConsultantDoc] = useState([]);

  // For modal
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState([]);

  // full page loader
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [getRegistrationData, setRegistrationData] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [registrationDetail, setRegistrationDetail] = useState({
    registrationAccepted: true,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getRegistrationDetails = async (id, page = 1, startDate, endDate) => {
    try {
      let mainStartDate = "";
      let mainEndDate = "";
      if (startDate && endDate) {
        mainStartDate = moment(startDate).format("DD-MM-YYYY");
        mainEndDate = moment(endDate).format("DD-MM-YYYY");
      }

      showLoader();
      var res = await getRegistrationDetail(
        id,
        page,
        mainStartDate,
        mainEndDate
      );
      if (res.status === 200) {
        setRegistrationDetail(res.data.letestData[0]);
        console.log(res.data.letestData[0], "Letigation Detail");
        setRegistrationData(res.data.registrationData);
        console.log(res.data.registrationData, "Registration Data");
        setTotal(res.data.total);
        setFiles(res.data.letestData[0].document || []);
        setConsultantDoc(res.data.letestData[0].consultantDocument || []);
        hideLoader();
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      hideLoader();
      toast.error(err);
    }
  };

  useEffect(() => {
    getRegistrationDetails(id, activePage);
    // eslint-disable-next-line
  }, [id, activePage]);

  useEffect(() => {
    if (startDate && endDate) {
      getRegistrationDetails(id, activePage, startDate, endDate);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <>
      <Header />
      {loader ? (
        <div className="text-center">{loader}</div>
      ) : (
        <>
          <section className="wrapper_executor-viewacts wrapper_registration_detail registration_details_custome">
            <div className="container">
              <div className="wrap_viewacts">
                <div className="wrapper_back_date">
                  <div className="backward_styling">
                    <Link to="/registration">
                      <span className="icon">
                        <IoIosArrowBack />
                      </span>
                    </Link>
                  </div>
                  <div className="form_field">
                    <h5>Registration details</h5>
                  </div>
                </div>
                <div className="content_wrapper_acts">
                  <div className="wrapper_details_section">
                    <div className="detail_section">
                      <span>Company Name</span>
                      <b>{registrationDetail?.company}</b>
                    </div>
                    <div className="detail_section">
                      <span>Location</span>
                      <b>{registrationDetail?.location}</b>
                    </div>
                    <div className="detail_section">
                      <span>State</span>
                      <b>{registrationDetail?.state}</b>
                    </div>

                    <div className="detail_section text-break">
                      <span>Manager Emails</span>
                      {/* <b>{registrationDetail?.managerEmail}</b> */}
                      {registrationDetail ? (
                        <button
                          className="custom_btn_2"
                          onClick={() => {
                            setEmailModal(true);
                            setEmail(registrationDetail.managerEmails);
                          }}
                        >
                          Manager Emails
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="detail_section">
                      <span>Registration type</span>
                      <b>{registrationDetail?.registrationType}</b>
                    </div>
                    {registrationDetail?.consultantStatus && (
                      <div className="detail_section">
                        <span>Status</span>
                        <b>{registrationDetail?.consultantStatus}</b>
                      </div>
                    )}
                    {registrationDetail?.executerStatus && (
                      <div className="detail_section">
                        <span>Executor Status</span>
                        <b>{registrationDetail.executerStatus}</b>
                      </div>
                    )}
                  </div>
                </div>

                <div className="content_wrapper_acts">
                  <div className="wrapper_details_section">
                    <div className="detail_section">
                      <span>Registration no.</span>
                      <b>{registrationDetail?.registrationNumber}</b>
                    </div>
                    <div className="detail_section">
                      <span>Issue Date</span>
                      <b>
                        {registrationDetail?.issueDate
                          ? dateFormat(registrationDetail.issueDate)
                          : "-- -- ----"}
                      </b>
                    </div>
                    <div className="detail_section">
                      <span>Expiry Date</span>
                      <b>
                        {registrationDetail?.expireDate
                          ? dateFormat(registrationDetail.expireDate)
                          : "-- -- ----"}
                      </b>
                    </div>
                    <div className="detail_section">
                      <span>Uploaded</span>
                      <b>{registrationDetail.filledBy}</b>
                    </div>
                    {registrationDetail.consultantEmail && (
                      <div className="detail_section text-break">
                        <span>Consultant Mail</span>
                        <b>{registrationDetail.consultantEmail}</b>
                      </div>
                    )}
                    {registrationDetail.createdAt && (
                      <div className="detail_section">
                        <span>Executor Filled Date</span>
                        <b>{dateFormat(registrationDetail.createdAt)}</b>
                      </div>
                    )}
                    {registrationDetail.submittedDateByConsultant && (
                      <div className="detail_section">
                        <span>Consultant Filled Date</span>
                        <b>
                          {dateFormat(
                            registrationDetail.submittedDateByConsultant
                          )}
                        </b>
                      </div>
                    )}
                    <div className="detail_section">
                      <span>Head Emails</span>
                      {/* <b>{registrationDetail.headEmail}</b> */}

                      {registrationDetail ? (
                        <button
                          className="custom_btn_2"
                          onClick={() => {
                            setEmailModal(true);
                            setEmail(registrationDetail.headEmails);
                          }}
                        >
                          Head Emails
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                {registrationDetail.remarks?.length > 0 && (
                  <div className="content_wrapper_acts">
                    <div className="wrapper_details_section">
                      <div className="detail_section mw-100">
                        <span>Remark</span>
                        <b>
                          {registrationDetail.remarks.length > 0 &&
                            registrationDetail.remarks.map((data, i) => {
                              return (
                                <>
                                  <b>{`${++i}.${data.remark}`}</b>
                                  <br />
                                </>
                              );
                            })}
                        </b>
                      </div>
                    </div>
                  </div>
                )}

                {/* executer uploaded documentss */}
                <div className="content_wrapper_acts">
                  <div className="wrapper_compliance py-4">
                    <table className="table table-striped table-bordered table-hover">
                      <thead>
                        <tr className="custom_tr">
                          <th>Documents Uploaded By Executor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {files.length > 0 ? (
                          files.map((file) => {
                            return (
                              <tr className="cstm_tr" key={file}>
                                <td>
                                  <div className="d-flex justify-content-between">
                                    <span>{stringTrimmer(file)}</span>
                                    <button
                                      className="custom_btn btn btn-primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`${file}`);
                                      }}
                                    >
                                      Download
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>No Documents Attached</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* consultant uploaded documents */}
                {consultantDoc.length > 0 && (
                  <div className="content_wrapper_acts">
                    <div className="wrapper_compliance py-4">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr className="custom_tr">
                            <th>Documents Uploaded By Consultant</th>
                          </tr>
                        </thead>
                        <tbody>
                          {consultantDoc.length > 0 ? (
                            consultantDoc.map((file) => {
                              return (
                                <tr className="cstm_tr" key={file}>
                                  <td>
                                    <div className="d-flex justify-content-between">
                                      <span>{stringTrimmer(file)}</span>
                                      <button
                                        className="custom_btn btn btn-primary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.open(`${file}`);
                                        }}
                                      >
                                        Download
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>No Documents Attached</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {!registrationDetail.registrationAccepted && (
                  <div className="applicable_not">
                    {isLoggedIn().role === "Consultant" && (
                      <button onClick={() => setProceedModal(true)}>
                        Update Status
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>

          {/* --------------------------------------Date format--------------------------------------------- */}
          <div className="wrap_viewacts">
            <div className="content_wrapper_acts">
              <div className="wrapper_details_section">
                <div className="container">
                  <div className="row my-4">
                    <div className="col-6">
                      <h4>History</h4>
                    </div>
                    <div className="col-3">
                      <div className="form_field date-picker">
                        <DatePicker
                          placeholderText="Start Date"
                          className="input"
                          selected={startDate}
                          value={startDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) => setStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form_field date-picker">
                        <DatePicker
                          placeholderText="End Date"
                          className="input"
                          selected={endDate}
                          value={endDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          onChange={(date) => setEndDate(date)}
                          minDate={startDate}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*-------------------------------------- End Date Format------------------------------------------------ */}

          {/* -------------------------------Accordian------------------------------------------------------------------ */}
          <div className="container">
            <div className="wrap_viewacts">
              <div className="content_wrapper_acts">
                <div className="wrapper_details_section">
                  <Accordion className="accordion_wrap" defaultActiveKey="1">
                    <>
                      {getRegistrationData?.length > 0 &&
                        getRegistrationData?.map((data, i) => {
                          return (
                            <Accordion.Item eventKey={i} key={++i}>
                              <Accordion.Header>
                                <div className="wrap_viewacts">
                                  <div className="wrap_viewacts">
                                    <div className="wrapper_back_date">
                                      <div>
                                        <h6>{`${
                                          ++i + (activePage - 1) * 10
                                        }.  ${data.company}  ${dateFormat(
                                          data.issueDate
                                        )} - ${dateFormat(
                                          data.expireDate
                                        )}`}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <section className="wrapper_executor-viewacts wrapper_registration_detail registration_details_custome">
                                  <div className="container">
                                    <div className="wrap_viewacts">
                                      <div className="wrapper_back_date">
                                        <div className="backward_styling">
                                          <Link to="/registration">
                                            <span className="icon">
                                              <IoIosArrowBack />
                                            </span>
                                          </Link>
                                        </div>
                                        <div className="form_field">
                                          <h5>Registration details</h5>
                                        </div>
                                      </div>
                                      <div className="content_wrapper_acts">
                                        <div className="wrapper_details_section">
                                          <div className="detail_section">
                                            <span>Company Name</span>
                                            <b>{data?.company}</b>
                                          </div>
                                          <div className="detail_section">
                                            <span>Location</span>
                                            <b>{data?.location}</b>
                                          </div>
                                          <div className="detail_section">
                                            <span>State</span>
                                            <b>{data?.state}</b>
                                          </div>

                                          <div className="detail_section text-break">
                                            <span>Manager Emails</span>
                                            {/* <b>{data?.managerEmail}</b> */}

                                            {data ? (
                                              <button
                                                className="custom_btn_2"
                                                onClick={() => {
                                                  setEmailModal(true);
                                                  setEmail(data.managerEmails);
                                                }}
                                              >
                                                Manager Emails
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className="detail_section">
                                            <span>Registration type</span>
                                            <b>{data?.registrationType}</b>
                                          </div>
                                          {data?.consultantStatus && (
                                            <div className="detail_section">
                                              <span>Status</span>
                                              <b>{data?.consultantStatus}</b>
                                            </div>
                                          )}
                                          {data?.executerStatus && (
                                            <div className="detail_section">
                                              <span>Executor Status</span>
                                              <b>{data.executerStatus}</b>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="content_wrapper_acts">
                                        <div className="wrapper_details_section">
                                          <div className="detail_section">
                                            <span>Registration no.</span>
                                            <b>{data?.registrationNumber}</b>
                                          </div>
                                          <div className="detail_section">
                                            <span>Issue Date</span>
                                            <b>
                                              {data?.issueDate
                                                ? dateFormat(data.issueDate)
                                                : "-- -- ----"}
                                            </b>
                                          </div>
                                          <div className="detail_section">
                                            <span>Expiry Date</span>
                                            <b>
                                              {data?.expireDate
                                                ? dateFormat(data.expireDate)
                                                : "-- -- ----"}
                                            </b>
                                          </div>
                                          <div className="detail_section">
                                            <span>Uploaded</span>
                                            <b>{data.filledBy}</b>
                                          </div>
                                          {registrationDetail.consultantEmail && (
                                            <div className="detail_section text-break">
                                              <span>Consultant Mail</span>
                                              <b>{data.consultantEmail}</b>
                                            </div>
                                          )}
                                          {data.createdAt && (
                                            <div className="detail_section">
                                              <span>Executor Filled Date</span>
                                              <b>
                                                {dateFormat(data.createdAt)}
                                              </b>
                                            </div>
                                          )}
                                          {data.submittedDateByConsultant && (
                                            <div className="detail_section">
                                              <span>
                                                Consultant Filled Date
                                              </span>
                                              <b>
                                                {dateFormat(
                                                  data.submittedDateByConsultant
                                                )}
                                              </b>
                                            </div>
                                          )}
                                          <div className="detail_section">
                                            <span>Head Emails</span>
                                            {/* <b>{data.headEmail}</b> */}
                                            {data ? (
                                              <button
                                                className="custom_btn_2"
                                                onClick={() => {
                                                  setEmailModal(true);
                                                  setEmail(data.headEmails);
                                                }}
                                              >
                                                Head Emails
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      {registrationDetail.remarks?.length >
                                        0 && (
                                        <div className="content_wrapper_acts">
                                          <div className="wrapper_details_section">
                                            <div className="detail_section mw-100">
                                              <span>Remark</span>
                                              <b>
                                                {data.remarks.length > 0 &&
                                                  data.remarks.map(
                                                    (data, i) => {
                                                      return (
                                                        <>
                                                          <b>{`${++i}.${
                                                            data.remark
                                                          }`}</b>
                                                          <br />
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </b>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {/* executer uploaded documentss */}
                                      <div className="content_wrapper_acts">
                                        <div className="wrapper_compliance py-4">
                                          <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                              <tr className="custom_tr">
                                                <th>
                                                  Documents Uploaded By Executor
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {data?.document.length > 0 ? (
                                                data?.document.map((file) => {
                                                  return (
                                                    <tr
                                                      className="cstm_tr"
                                                      key={file}
                                                    >
                                                      <td>
                                                        <div className="d-flex justify-content-between">
                                                          <span>
                                                            {stringTrimmer(
                                                              file
                                                            )}
                                                          </span>
                                                          <button
                                                            className="custom_btn btn btn-primary"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              window.open(
                                                                `${file}`
                                                              );
                                                            }}
                                                          >
                                                            Download
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                              ) : (
                                                <tr>
                                                  <td>No Documents Attached</td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>

                                      {/* consultant uploaded documents */}
                                      {data?.consultantDocument.length > 0 && (
                                        <div className="content_wrapper_acts">
                                          <div className="wrapper_compliance py-4">
                                            <table className="table table-striped table-bordered table-hover">
                                              <thead>
                                                <tr className="custom_tr">
                                                  <th>
                                                    Documents Uploaded By
                                                    Consultant
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {data?.consultantDocument
                                                  ?.length > 0 ? (
                                                  data?.consultantDocument?.map(
                                                    (file) => {
                                                      return (
                                                        <tr
                                                          className="cstm_tr"
                                                          key={file}
                                                        >
                                                          <td>
                                                            <div className="d-flex justify-content-between">
                                                              <span>
                                                                {stringTrimmer(
                                                                  file
                                                                )}
                                                              </span>
                                                              <button
                                                                className="custom_btn btn btn-primary"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  window.open(
                                                                    `${file}`
                                                                  );
                                                                }}
                                                              >
                                                                Download
                                                              </button>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td>
                                                      No Documents Attached
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      )}

                                      {/* {!registrationDetail.registrationAccepted && (
              <div className="applicable_not">
                {isLoggedIn().role === "Consultant" && (
                  <button onClick={() => setProceedModal(true)}>
                    Update Status
                  </button>
                )}
              </div>
            )} */}
                                    </div>
                                  </div>
                                </section>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                    </>
                    {/* ) : (
                    <div className="text-center">
                      <h5>History Not Available</h5>
                    </div>
                  )} */}
                  </Accordion>
                </div>
              </div>
            </div>
            {getRegistrationData?.length > 0 && (
              <div className="Pagination my-3">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={(page) => setActivePage(page)}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* ------------------------------------------------------------------------------------------------------------ */}
      {/* Update Registration Status Modal */}
      <UpdateRegistrationStatus
        proceedModal={proceedModal}
        onClose={() => setProceedModal(false)}
        registrationId={id}
        getRegistrationDetails={() => getRegistrationDetails(id)}
        registrationActionId={registrationDetail?.registrationActionId}
        registrationDetails={registrationDetail}
      />

      {/* Manager Modal  */}
      <Modal
        show={emailModal}
        onHide={() => setEmailModal(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="py-0">
          <div className="data_table p-0" style={{ height: "17rem" }}>
            <Table striped bordered hover style={{ minWidth: "100%" }}>
              <thead>
                <tr className="custom_tr">
                  <th>Emails</th>
                </tr>
              </thead>
              <tbody>
                {email && email.length > 0
                  ? email.map(({ email }, i) => (
                      <tr key={++i} className="cstm_tr">
                        <td>{email}</td>
                      </tr>
                    ))
                  : "Not Found"}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      {/* End  */}

      {/* Head Email Modal  */}
      <Modal
        show={emailModal}
        onHide={() => setEmailModal(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="py-0">
          <div className="data_table p-0" style={{ height: "17rem" }}>
            <Table striped bordered hover style={{ minWidth: "100%" }}>
              <thead>
                <tr className="custom_tr">
                  <th>Emails</th>
                </tr>
              </thead>
              <tbody>
                {email && email.length > 0
                  ? email.map(({ email }, i) => (
                      <tr key={++i} className="cstm_tr">
                        <td>{email}</td>
                      </tr>
                    ))
                  : "Not Found"}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      {/* End  */}

      {/* Accordian data  */}
      <Modal
        show={emailModal}
        onHide={() => setEmailModal(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="py-0">
          <div className="data_table p-0" style={{ height: "17rem" }}>
            <Table striped bordered hover style={{ minWidth: "100%" }}>
              <thead>
                <tr className="custom_tr">
                  <th>Emails</th>
                </tr>
              </thead>
              <tbody>
                {email && email.length > 0
                  ? email.map(({ email }, i) => (
                      <tr key={++i} className="cstm_tr">
                        <td>{email}</td>
                      </tr>
                    ))
                  : "Not Found"}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegistrationDetail;
