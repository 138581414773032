import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Notices.css";
import Button from "react-bootstrap/Button";
import { FaEye } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Header from "../../Layout/Header";
// import Header from "../Layout/Header";
import { IoIosArrowBack } from "react-icons/io";
import { useRef } from "react";
import {
  getCompany,
  getLocationLists,
  getManager,
  storeExecutorLetigation,
  updateExecutorLetigation,
  noticeDetailsList,
  getHead,
  deleteLitigation,
} from "../../../controller/LetigationController";
import { toast } from "react-toastify";
import { FaTrash } from "react-icons/fa";
import moment from "moment";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { dateFormat } from "../../../utils";
import { Modal } from "react-bootstrap";
import NotFound from "../../../components/NotFound/NotFound";
import Pagination from "react-js-pagination";
import Select from "react-select";

function EditNotices() {
  var { id } = useParams();
  var navigate = useNavigate();
  const [fileName, SetFileName] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [latestData, setLatestData] = useState(false);

  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Save",
    "Please wait..."
  );

  const [submitDeleteElement, setDeleteLoading] = useButtonLoader(
    "Yes",
    "Please wait..."
  );

  const [getCompanies, setCompany] = useState([]);
  const getCompanyList = async () => {
    var res = await getCompany();
    if (res.status == 200) {
      setCompany(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const [getNoticeDetailList, setNoticeDetailList] = useState([]);
  const [getNotice, setNotice] = useState({});
  const [getNoticeHistory, setNoticeHistory] = useState([]);
  const noticeDetails = async () => {
    var data = {
      id: id,
      page: activePage,
    };

    var res = await noticeDetailsList(data);

    if (res.status == 200) {
      if (!res.data.letest) {
        navigate("/notices");
      }
      getManagerList(res.data.letest.companyId);
      getHeadList(res.data.letest.companyId);
      getLocationList(res.data.letest.companyId);
      setNoticeDetailList(res.data.details);
      setNotice(res.data.letest);
      setNoticeHistory(res.data.details);
      console.log(res.data);
      setTotal(res.data.total);

      setCompanyId(res.data.letest.companyId);
      setLocation(res.data.letest.location);
      setCourt(res.data.letest.court);
      setCouncilName(res.data.letest.councilName);
      setPartyNameOne(res.data.letest.partyNameOne);
      setPartyNameTwo(res.data.letest.partyNameTwo);
      setHearingDate(res.data.letest.hearingDate);
      setLiability(res.data.letest.liability);
      setLetigtionStatus(res.data.letest.letigtionStatus);
      setAuthorizedRepresentative(res.data.letest.authorizedRepresentative);
      setManagerEmail(res.data.letest.managerEmails.map((value)=>{
        return {label:value.email,value:value.email}
      }))
      setHeadEmail(res.data.letest.headEmails.map((value)=>{
        return {label:value.email,value:value.email}
      }))
      setMinutesofHearingRemark(res.data.letest.minutesofHearingRemark);
      // setDoc(res.data.details[0].document);
      // if (res.data.details[0].document.length > 0) {
      // } else {
      //   setDoc([
      //     ...getDoc,
      //     '',
      //   ]);
      // }
    } else {
      toast.error(res.message);
    }
  };

  const [getManagers, setManagers] = useState([]);
  const getManagerList = async (id = companyId) => {
    var data = {
      companyId: id,
    };

    var res = await getManager(data);

    if (res.status == 200) {
      setManagers(
        res.data.map((data) => {
          return {
            label: data.email,
            value: data.email,
          };
        })
      );
    } else {
      toast.error(res.message);
    }
  };

  const [getHeads, setHeads] = useState([]);
  const getHeadList = async (id = companyId) => {
    var data = {
      companyId: id,
    };

    var res = await getHead(data);

    if (res.status == 200) {
      setHeads(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const [getLocations, setLocations] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const getLocationList = async (id = companyId) => {
    var data = {
      companyId: id,
    };
    var res = await getLocationLists(data);
    if (res.status == 200) {
      setLocations(res.data);
    } else {
      toast.error(res.message);
    }
  };

  // const kyMbrImg = (e, i) => {
  //   // SetFileName(e.target.files[0].name);
  //   document.getElementById("image_").innerHTML = e.target.files[0].name;
  // };

  const [getLocation, setLocation] = useState("");
  const [getCourt, setCourt] = useState("");
  const [getCouncilName, setCouncilName] = useState("");
  const [getPartyNameOne, setPartyNameOne] = useState("");
  const [getPartyNameTwo, setPartyNameTwo] = useState("");
  const [getHearingDate, setHearingDate] = useState("");
  const [getNextHearingDate, setNextHearingDate] = useState("");
  const [getLiability, setLiability] = useState("");
  const [getLetigtionStatus, setLetigtionStatus] = useState("");
  const [getAuthorizedRepresentative, setAuthorizedRepresentative] =
    useState("");
  const [getManagerEmail, setManagerEmail] = useState([]);
  const [getHeadEmail, setHeadEmail] = useState([]);
  const [getMinutesofHearingRemark, setMinutesofHearingRemark] = useState("");
  const handleInput = (name, value) => {
    if (name == "company") {
      setCompanyId(value);
      getManagerList(value);
      getLocationList(value);
    } else if (name == "location") {
      setLocation(value);
    } else if (name == "court") {
      setCourt(value);
    } else if (name == "councilName") {
      setCouncilName(value);
    } else if (name == "partyNameOne") {
      setPartyNameOne(value);
    } else if (name == "partyNameTwo") {
      setPartyNameTwo(value);
    } else if (name == "hearingDate") {
      setHearingDate(value);
    } else if (name == "nextHearingDate") {
      setNextHearingDate(value);
    } else if (name == "liability") {
      setLiability(value);
    } else if (name == "letigtionStatus") {
      setLetigtionStatus(value);
    } else if (name == "authorizedRepresentative") {
      setAuthorizedRepresentative(value);
    } else if (name == "managerEmail") {
      setManagerEmail(value);
    } else if (name == "headEmail") {
      setHeadEmail(value);
    } else if (name == "minutesofHearingRemark") {
      setMinutesofHearingRemark(value);
    }
  };

  const handleFinalSubmit = async () => {
    var data = {
      companyId: companyId,
      getLocation: getLocation,
      getCourt: getCourt,
      getCouncilName: getCouncilName,
      getPartyNameOne: getPartyNameOne,
      getPartyNameTwo: getPartyNameTwo,
      getHearingDate: getHearingDate,
      getLiability: getLiability,
      getLetigtionStatus: getLetigtionStatus,
      getManagerEmail: getManagerEmail,
      getHeadEmail: getHeadEmail,
      getAuthorizedRepresentative: getAuthorizedRepresentative,
      getMinutesofHearingRemark: getMinutesofHearingRemark,
      file: getDoc,
    };

    // let formData = new FormData();
    // formData.append('_id', id);
    // formData.append('companyId', companyId);
    // formData.append('location', getLocation);
    // formData.append('court', getCourt);
    // formData.append('councilName', getCouncilName);
    // formData.append('partyNameOne', getPartyNameOne);
    // formData.append('partyNameTwo', getPartyNameTwo);
    // formData.append('hearingDate', getHearingDate);
    // formData.append('liability', getLiability);
    // formData.append('letigtionStatus', getLetigtionStatus);
    // formData.append('managerEmail', getManagerEmail);
    // formData.append('authorizedRepresentative', getAuthorizedRepresentative);
    // formData.append('minutesofHearingRemark', getMinutesofHearingRemark);

    setSubmitLoading(true);
    let formData = new FormData();
    formData.append("letigationId", id);
    formData.append("companyId", companyId);
    formData.append("location", getLocation);
    formData.append("court", getCourt);
    formData.append("councilName", getCouncilName);
    formData.append("partyNameOne", getPartyNameOne);
    formData.append("partyNameTwo", getPartyNameTwo);
    formData.append("hearingDate", getHearingDate);
    formData.append("liability", getLiability);
    formData.append("letigtionStatus", getLetigtionStatus);
    getManagerEmail.forEach(element => {
      formData.append("managerEmail", element.value);
    });

    getHeadEmail.forEach(element => {
      formData.append("headEmail", element.value);
    });
    formData.append("authorizedRepresentative", getAuthorizedRepresentative);
    formData.append("minutesofHearingRemark", getMinutesofHearingRemark);

    if (getDoc.length > 0) {
      for (let i = 0; i < getDoc.length; i++) {
        formData.append("file", getDoc[i]);
      }
    }

    var res = await storeExecutorLetigation(formData);

    if (res.status === 200) {
      toast.success("Success");
      navigate("/notices");
      setSubmitLoading(false);
    } else {
      toast.error(res.message);
      setSubmitLoading(false);
    }
  };

  const setAddMoreDoc = (e, index) => {
    const image = [...getDoc];
    const file = e.target.files[0].type;
    if (
      file === "application/pdf" ||
      file === "image/png" ||
      file === "image/jpeg" ||
      file ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      image[index] = e.target.files[0];
      SetFileName(e.target.files[0].name);
      setDoc(image);
    } else {
      toast.error("Please upload valid file");
    }
  };
  const [getDoc, setDoc] = useState([""]);
  const handleAddMoreDoc = () => {
    setDoc([...getDoc, ""]);
  };

  const handleRemoveMoreDoc = (e, index) => {
    e.preventDefault();
    const docs = [...getDoc];
    docs.splice(index, 1);
    setDoc(docs);
  };

  const ref = useRef();

  useEffect(() => {
    noticeDetails();
    getCompanyList();
  }, []);

  const deleteHandler = async () => {
    if (selectedId) {
      try {
        setDeleteLoading(true);
        const res = await deleteLitigation(selectedId);
        if (res.status === 200) {
          toast.success(res.message);
          noticeDetails();
          setDeleteModal(false);
          setDeleteLoading(false);
        } else {
          toast.error(res.message);
          setDeleteLoading(false);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
        setDeleteLoading(false);
      }
    }
  };

  useEffect(() => {
    noticeDetails();
  }, [activePage]);

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_add_notices  wrapper_add_task">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/notices">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Edit Notice</h5>
              </div>
            </div>
            <form>
              <div className="content_wrapper_acts">
                <div className="left_side_form">
                  <div className="form_field_wrap">
                    <div className="form_field">
                      {/* <input
                        type="text"
                        className="input"
                        placeholder="Company name"
                      /> */}
                      <select
                        className="input"
                        onChange={(e) => handleInput("company", e.target.value)}
                      >
                        <option value="">Select company</option>
                        {getCompanies && getCompanies.length > 0
                          ? getCompanies.map((value, i) => (
                              <option
                                value={value._id}
                                selected={companyId == value._id}
                              >
                                {value.companyName}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="form_field">
                      {/* <input
                        type="text"
                        className="input"
                        placeholder="Location"
                      /> */}
                      <select
                        className="input"
                        onChange={(e) =>
                          handleInput("location", e.target.value)
                        }
                      >
                        <option value="">Select Location</option>
                        {getLocations && getLocations.length > 0
                          ? getLocations.map((value, i) => (
                              <option
                                value={value.location}
                                selected={value.location == getNotice.location}
                              >
                                {value.location}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        onChange={(e) => handleInput("court", e.target.value)}
                        value={getCourt}
                        placeholder="Court/Authority/Council Name"
                      />
                    </div>
                    <div className="form_field">
                      {/* <input
                        type="text"
                        className="input"
                        placeholder="Manager email"
                      /> */}
                      {/* <select
                        className="input"
                        value={getManagerEmail}
                        onChange={(e) =>
                          handleInput("managerEmail", e.target.value)
                        }
                      >
                        <option value="">Select Manager</option>
                        {getManagers && getManagers.length > 0
                          ? getManagers.map((value, i) => (
                              <option
                                value={value.email}
                                selected={value.email == getManagerEmail}
                              >
                                {value.email}
                              </option>
                            ))
                          : ""}
                      </select> */}
                      <Select
                        value={getManagerEmail}
                        onChange={setManagerEmail}
                        options={getManagers}
                        isMulti
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        value={getPartyNameOne}
                        onChange={(e) =>
                          handleInput("partyNameOne", e.target.value)
                        }
                        placeholder="Party name1"
                      />
                    </div>
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        value={getPartyNameTwo}
                        onChange={(e) =>
                          handleInput("partyNameTwo", e.target.value)
                        }
                        placeholder="Party name2"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="date"
                        className="input"
                        value={moment(getHearingDate).format("YYYY-MM-DD")}
                        onChange={(e) =>
                          handleInput("hearingDate", e.target.value)
                        }
                        placeholder="Hearing date"
                      />
                    </div>
                    <div className="form_field">
                      {/* <select
                        className="input"
                        value={getHeadEmail}
                        onChange={(e) =>
                          handleInput("headEmail", e.target.value)
                        }
                      >
                        <option value="">Select HOD</option>
                        {getHeads && getHeads.length > 0
                          ? getHeads.map((value, i) => (
                              <option value={value.email}>{value.email}</option>
                            ))
                          : ""}
                      </select> */}
                                            <Select
                        value={getHeadEmail}
                        onChange={setHeadEmail}
                        options={getHeads}
                        isMulti
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        value={getLiability}
                        onChange={(e) =>
                          handleInput("liability", e.target.value)
                        }
                        placeholder="Liability"
                      />
                    </div>
                    <div className="form_field">
                      <select
                        className="input"
                        onChange={(e) =>
                          handleInput("letigtionStatus", e.target.value)
                        }
                      >
                        <option value="">Status</option>
                        <option
                          selected={getLetigtionStatus == "open"}
                          value="open"
                        >
                          Open
                        </option>
                        <option
                          selected={getLetigtionStatus == "noActionRequired"}
                          value="noActionRequired"
                        >
                          No Action Required
                        </option>
                        <option
                          selected={getLetigtionStatus == "close"}
                          value="close"
                        >
                          Close
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="form_field_wrap">
                    {/* <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Executor email"
                      />
                    </div> */}
                    <div className="form_field">
                      <input
                        type="text"
                        className="input"
                        value={getAuthorizedRepresentative}
                        onChange={(e) =>
                          handleInput(
                            "authorizedRepresentative",
                            e.target.value
                          )
                        }
                        placeholder="Authrozided Represnative"
                      />
                    </div>
                    <div className="form_field"></div>
                    {/* <div className="form_field">
                      <select
                        className="input"
                        onChange={(e) =>
                          handleInput("managerEmail", e.target.value)
                        }
                      >
                        <option value="">Select Manager</option>
                        {getManagers && getManagers.length > 0
                          ? getManagers.map((value, i) => (
                              <option
                                value={value.email}
                                selected={value.email == getManagerEmail}
                              >
                                {value.email}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div> */}
                  </div>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <textarea
                        className="input"
                        value={getMinutesofHearingRemark}
                        cols="30"
                        placeholder="Minutes of hearing.."
                        rows="5"
                        onChange={(e) =>
                          handleInput("minutesofHearingRemark", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="right_side_form">
                  <div className="heads_text">
                    <h4>Upload Documents</h4>
                  </div>
                  {getDoc && getDoc.length > 0 ? (
                    getDoc.map((value, i) => (
                      <div className="input_upload_file">
                        <label>
                          <input
                            className="input"
                            type="file"
                            onChange={(e) => setAddMoreDoc(e, i)}
                          />
                          <div className="wrapper_upload_div">
                            <span className="upload_div1" id="image_">
                              file name {fileName}
                            </span>
                            <span className="Upload-file">+ Upload</span>
                          </div>
                        </label>
                        {i == 0 ? (
                          <span
                            className="add_span_style"
                            onClick={handleAddMoreDoc}
                          >
                            +
                          </span>
                        ) : (
                          <span
                            className="add_span_style delete_span_style"
                            onClick={(e) => handleRemoveMoreDoc(e, i)}
                          >
                            <FaTrash />
                          </span>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="input_upload_file">
                      <label>
                        <input
                          className="input"
                          type="file"
                          onChange={(e) => setAddMoreDoc(e, 100)}
                        />
                        <div className="wrapper_upload_div">
                          <span className="upload_div1" id="image_">
                            file name {fileName}
                          </span>
                          <span className="Upload-file">+ Upload</span>
                        </div>
                      </label>
                      <span
                        className="add_span_style"
                        onClick={handleAddMoreDoc}
                      >
                        +
                      </span>
                    </div>
                  )}
                </div>

                <div className="applicable_not">
                  <Link to="/notices">Cancel</Link>
                  <button
                    className="custom_btn2"
                    onClick={handleFinalSubmit}
                    ref={submitButtonElement}
                    type="button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="data_table">
          <Table striped bordered hover>
            <thead>
              <tr className="custom_tr">
                <th>SNo.</th>
                <th>Status</th>
                <th>Hearing Date</th>
                <th>Minutes Of Hearing</th>
                <th>Party One</th>
                <th>Party Two</th>
                {/* <th>ACTION</th> */}
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {getNoticeHistory && getNoticeHistory.length > 0 ? (
                getNoticeHistory.map((value, i) => (
                  <tr className="cstm_tr">
                    <td>{++i}</td>
                    <td>{value.letigtionStatus}</td>
                    <td>{dateFormat(value.hearingDate)}</td>
                    <td>
                      <p className="tooltips-effect">
                        {value.minutesofHearingRemark?.length > 20
                          ? value.minutesofHearingRemark?.slice(0, 20) + "..."
                          : value.minutesofHearingRemark}
                        <span className="tooltiptext">
                          {value.minutesofHearingRemark}
                        </span>
                      </p>
                    </td>
                    <td>
                      <p className="tooltips-effect">
                        {value.partyNameOne?.length > 20
                          ? value.partyNameOne?.slice(0, 20) + "..."
                          : value.partyNameOne}
                        <span className="tooltiptext">
                          {value.partyNameOne}
                        </span>
                      </p>
                    </td>
                    <td>
                      <p className="tooltips-effect">
                        {value.partyNameTwo?.length > 20
                          ? value.partyNameTwo?.slice(0, 20) + "..."
                          : value.partyNameTwo}
                        <span className="tooltiptext">
                          {value.partyNameTwo}
                        </span>
                      </p>
                    </td>
                    {/* <td>
                    <Button
                      className="custom_btn"
                      variant="primary"
                      onClick={handleShow}
                    >
                      More
                    </Button>
                  </td> */}
                    <td>
                      <Link to={`/notices/notice-details/${value._id}`}>
                        <span className="icon">
                          <FaEye />
                        </span>
                      </Link>
                      <button
                        className="bg-transparent border border-0"
                        onClick={() => {
                          setSelectedId(value._id);
                          setLatestData(value.isLetest);
                          setDeleteModal(true);
                        }}
                      >
                        <span className="icon">
                          <FaTrash />
                        </span>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <NotFound col={7} />
              )}
            </tbody>
          </Table>
          <div className="Pagination">
            <Pagination
              activePage={activePage}
              previousLabel={"previous"}
              nextLabel={"next"}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={(page) => setActivePage(page)}
            />
          </div>
        </div>
      </section>

      {/* Modal */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>
          <h5 className="text-center">Delete Litigation</h5>
        </Modal.Title>
        <Modal.Body>
          <div className="wrapper-modal-div text-center">
            {latestData && (
              <p className="text-danger">
                Note* : By deleting this Main data all the history will be
                deleted.
              </p>
            )}
            <p>Are you sure you want to delete litigation?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={() => setDeleteModal(false)} className="custom_btn2">
            No
          </button>
          <button
            className="custom_btn2"
            ref={submitDeleteElement}
            onClick={deleteHandler}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditNotices;
