import React from "react";
import { FiDownload } from "react-icons/fi";
import { dateFormat, stringTrimmer } from "../../../../utils";

const CommonListing = ({ litigation }) => {
  return (
    <>
      <div className="row my-4">
        <h4>Litigation Details</h4>
        <div className="">
          <div className="col_cstm">
            <div className="ul_list">
              <ul type="none">
                <li>
                  <span>Hearing date</span>
                  <b>{litigation ? dateFormat(litigation.hearingDate) : ""}</b>
                </li>

                <li>
                  <span>Status</span>
                  <b>{litigation ? litigation.letigtionStatus : ""}</b>
                </li>

                <li>
                  <span>Minutes of Hearing</span>
                  <b>{litigation ? litigation.minutesofHearingRemark : ""}</b>
                </li>
              </ul>
            </div>

            <div className="data_table">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr className="custom_tr">
                    <th>S.No.</th>
                    <th>File Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {litigation && litigation.document.length > 0 ? (
                    litigation.document.map((value, i) => (
                      <tr className="cstm_tr">
                        <td>{++i}</td>
                        <td>{stringTrimmer(value)}</td>
                        <td>
                          <a href={value} download>
                            <span className="icon">
                              <FiDownload />
                            </span>
                          </a>
                          {/* <span className="Bs_download">
              <FiDownload />
            </span> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="cstm_tr">
                      <td colSpan={3} className=" text-center">
                        No Documents Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonListing;
