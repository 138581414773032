import React, { useState } from "react";
import { FiDownload } from "react-icons/fi";
import { dateFormat, stringTrimmer } from "../../../../utils";
import Modal from "react-bootstrap/Modal";
// import NotFound from "../../components/NotFound/NotFound";
import Table from "react-bootstrap/Table";

const MainListing = ({ litigation }) => {
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState([]);
  return (
    <>
      <div className="row my-4">
        <h4>Litigation Details</h4>
        <div className="">
          <div className="col_cstm">
            <div className="ul_list">
              <ul type="none">
                <li>
                  <span>Company name</span>
                  <b>{litigation ? litigation.company : ""}</b>
                </li>
                <li>
                  <span>Council/Court/Authority name</span>
                  <p className="tooltips-effect">
                    {litigation.court?.length > 20
                      ? litigation.court?.slice(0, 20) + "..."
                      : litigation.court}
                    <span className="tooltiptext text-black">
                      {litigation.court}
                    </span>
                  </p>
                </li>
                <li>
                  <span>Party name1</span>
                  <p className="tooltips-effect">
                    {litigation.partyNameOne?.length > 20
                      ? litigation.partyNameOne?.slice(0, 20) + "..."
                      : litigation.partyNameOne}
                    <span className="tooltiptext text-black">
                      {litigation.partyNameOne}
                    </span>
                  </p>
                </li>
                <li>
                  <span>Hearing date</span>
                  <b>{litigation ? dateFormat(litigation.hearingDate) : ""}</b>
                </li>
                <li>
                  <span>Liability</span>
                  <p className="tooltips-effect">
                    {litigation.liability?.length > 20
                      ? litigation.liability?.slice(0, 20) + "..."
                      : litigation.liability}
                    <span className="tooltiptext text-black">
                      {litigation.liability}
                    </span>
                  </p>
                </li>
                <li>
                  <span>Authorized Represntative</span>
                  <p className="tooltips-effect">
                    {litigation.authorizedRepresentative?.length > 20
                      ? litigation.authorizedRepresentative?.slice(0, 20) +
                        "..."
                      : litigation.authorizedRepresentative}
                    <span className="tooltiptext text-black">
                      {litigation.authorizedRepresentative}
                    </span>
                  </p>
                </li>
                <li>
                  <span>Manager Emails</span>
                  <b>
                    {litigation ? (
                      <button
                        className="custom_btn_2"
                        onClick={() => {
                          setEmailModal(true);
                          setEmail(litigation.managerEmails);
                        }}
                      >
                        Manager Emails
                      </button>
                    ) : (
                      ""
                    )}
                  </b>
                </li>
                <li>
                  <span>Head Emails</span>
                  <b>
                    {" "}
                    {litigation ? (
                      <button
                        className="custom_btn_2"
                        onClick={() => {
                          setEmailModal(true);
                          setEmail(litigation.headEmails);
                        }}
                      >
                        Head Emails
                      </button>
                    ) : (
                      ""
                    )}
                  </b>
                </li>
                <li>
                  <span>Location</span>
                  <b>{litigation ? litigation.location : ""}</b>
                </li>

                <li>
                  <span>Party name2</span>
                  <p className="tooltips-effect">
                    {litigation.partyNameTwo?.length > 20
                      ? litigation.partyNameTwo?.slice(0, 20) + "..."
                      : litigation.partyNameTwo}
                    <span className="tooltiptext text-black">
                      {litigation.partyNameTwo}
                    </span>
                  </p>
                </li>

                <li>
                  <span>Status</span>
                  <b>{litigation ? litigation.letigtionStatus : ""}</b>
                </li>

                <li>
                  <span>Minutes of Hearing</span>
                  <p className="tooltips-effect">
                    {litigation.minutesofHearingRemark?.length > 30
                      ? litigation.minutesofHearingRemark?.slice(0, 30) + "..."
                      : litigation.minutesofHearingRemark}
                    <span className="tooltiptext text-black">
                      {litigation.minutesofHearingRemark}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="data_table">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr className="custom_tr">
                    <th>S.No.</th>
                    <th>File Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {litigation && litigation.document.length > 0 ? (
                    litigation.document.map((value, i) => (
                      <tr className="cstm_tr">
                        <td>{++i}</td>
                        <td>{stringTrimmer(value)}</td>
                        <td>
                          <a href={value} download>
                            <span className="icon">
                              <FiDownload />
                            </span>
                          </a>
                          {/* <span className="Bs_download">
              <FiDownload />
            </span> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="cstm_tr">
                      <td colSpan={3} className=" text-center">
                        No Documents Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Manager Modal  */}
        <Modal
          show={emailModal}
          onHide={() => setEmailModal(false)}
          // backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal_cstm_design"
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="py-0">
            <div className="data_table p-0" style={{ height: "17rem" }}>
              <Table striped bordered hover style={{ minWidth: "100%" }}>
                <thead>
                  <tr className="custom_tr">
                    <th>Emails</th>
                  </tr>
                </thead>
                <tbody>
                  {email && email.length > 0
                    ? email.map(({ email }, i) => (
                        <tr key={++i} className="cstm_tr">
                          <td>{email}</td>
                        </tr>
                      ))
                    : "Not Found"}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
        {/* End  */}

        {/* Head Emails  */}
        {/* Manager Modal  */}
        <Modal
          show={emailModal}
          onHide={() => setEmailModal(false)}
          // backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal_cstm_design"
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="py-0">
            <div className="data_table p-0" style={{ height: "17rem" }}>
              <Table striped bordered hover style={{ minWidth: "100%" }}>
                <thead>
                  <tr className="custom_tr">
                    <th>Emails</th>
                  </tr>
                </thead>
                <tbody>
                  {email && email.length > 0
                    ? email.map(({ email }, i) => (
                        <tr key={++i} className="cstm_tr">
                          <td>{email}</td>
                        </tr>
                      ))
                    : "Not Found"}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
        {/* End  */}

        {/* End  */}
      </div>
    </>
  );
};

export default MainListing;
