import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Compliance.css";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import Pagination from "react-js-pagination";
import Button from "react-bootstrap/Button";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { FaRegEdit } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";

import Header from "../Layout/Header";
import {
  companyData,
  stateData,
  riskData,
  complianceData,
  remarkFromPartnerData,
  locationData,
} from "../../controller/ComplianceController";
import { countArray, dateFormat, isLoggedIn } from "../../utils";
import moment from "moment";
import { Url } from "../../config/config";
import { AuthToken } from "../../controller/AuthController";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import NotFound from "../../components/NotFound/NotFound";
import {
  getDashboardDetails,
  getDashboardDetailsByCompany,
} from "../../controller/DashboradController";
import { GrPowerReset } from "react-icons/gr";
import CardSkeleton from "../../components/skeleton/CardSkeleton";
import Tooltip from "../../components/Tooltip/Tooltip";
import useDebounce from "../../hooks/useDebounce";
// import Calendar from "../../../assets/image/calendar.png";

function Compliance() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dashboardData, setDashboardData] = useState({});
  const [flag, setFlag] = useState(false);

  // full page loader
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [dashboardLoading, setDashboardLoading] = useState(false);

  // remarks state
  const [remarkShow, setRemarkShow] = useState(false);
  const navigate = useNavigate();

  // show Email modal and setting email...
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState([]);
  // end

  const [getDetailUin, setDetailUin] = useState("");
  const [getDepartment, setDepartment] = useState("");
  const [getExecutorEmail, setExecutorEmail] = useState([]);
  const [getManagerEmail, setManagerEmail] = useState([]);
  //Head Email..
  const [getHeadEmail, setHeadEmail] = useState([]);
  const [getPeriodicity, setPeriodicity] = useState("");
  // const onClickShowMoreData = (
  //   executerEmails,
  //   managerEmails,

  //   //head Email
  //   headEmails,
  //   periodicity,
  //   uin,
  //   department
  // ) => {
  //   setDetailUin(uin);
  //   setDepartment(department);
  //   setExecutorEmail(executerEmails.map((item) => item.email));
  //   setManagerEmail(managerEmails.map((item) => item.email));
  //   //set Head Email..
  //   setHeadEmail(headEmails.map((item) => item.email));
  //   setPeriodicity(periodicity);
  //   handleShow();
  // };

  const [selectedOptions, setSelectedOptions] = useState();
  const { type } = useParams();

  // Array of all options
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const [getCompany, setCompany] = useState([]);

  const companyList = async () => {
    var res = await companyData();
    console.log(res, "response");
    if (res.status == 200) {
      setCompany(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const handelLocationSelect = (e) => {
    setSelectedLocation(e.target.value);
    comlianceList(
      activePage,
      getSlectedCompany,
      getSlectedState,
      getSlectedStatus,
      getSlectedRisk,
      getSlectedDate,
      debounceValue,
      getSelectRemarkFromManager,
      getSelectDueCompliance,
      e.target.value
    );
  };

  const locationList = async (id, stateName) => {
    var res = await locationData(id, stateName);
    if (res.status == 200) {
      setLocation(res.data);
    } else {
      toast.error(res.messa);
    }
  };

  const [getState, setState] = useState([]);
  const stateList = async (id) => {
    var res = await stateData(id);
    console.log(res, "stateList");
    if (res.status === 200) {
      setState(res.data);
    } else {
      toast.error(res.messa);
    }
  };

  const [getRisk, setRisk] = useState([]);
  const [getStatus, setStatus] = useState([]);
  const riskList = async () => {
    var res = await riskData();
    console.log(res, "riskList");
    if (res.status == 200) {
      setRisk(res.data.risk);
      setStatus(res.data.status);
    } else {
      toast.error(res.messa);
    }
  };

  const [getCompliance, setCompliance] = useState([]);
  const [getComplianceCount, setComplianceCount] = useState([]);
  const comlianceList = async (
    page = activePage,
    company = getSlectedCompany,
    state = getSlectedState,
    status = getSlectedStatus,
    risk = getSlectedRisk,
    date = getSlectedDate,
    search = debounceValue,
    remarkFromManager = getSelectRemarkFromManager,
    dueCompliance = getSelectDueCompliance,
    loc = selectedLocation,
    currentDate = moment(new Date()).format("YYYY-MM-DD")
  ) => {
    try {
      setCompliance([]);
      showLoader();
      const statusSchema = {
        overdue: "2",
        complied: "3",
        cantdo: "6",
        "pending-for-compliance": "0",
        "pending-for-approval": "1",
        "not-applicable": "4",
        "reject-by-manage": "5",
        "one-time": "7",
        "due-today": "8",
      };
      var data = {
        state: state,
        company: company,
        status: status === "" ? statusSchema[type] : status,
        isExecuterApplicable: "",
        isCompiled: "",
        risk: risk,
        search: search,
        remarkManager: remarkFromManager,
        currentDate: currentDate,
        executerStatus: status === "" ? statusSchema[type] : status,
        page: page,
        location: loc,
        from_date: getSlectedDate,
        to_date: getSlectedDate2,
      };

      var res = await complianceData(data);
      if (res.status === 200) {
        setCompliance(res.data.listing);
        console.log(res.data.listing, "listing response");
        setComplianceCount(res.data.total);
        hideLoader();
      } else {
        toast.error(res.message);
        hideLoader();
      }
    } catch (err) {
      hideLoader();
      toast.error(err);
    }
  };

  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (page) => {
    setActivePage(page);
    setCompliance([]);
    comlianceList(
      page,
      getSlectedCompany,
      getSlectedState,
      getSlectedStatus,
      getSlectedRisk,
      getSlectedDate,
      debounceValue,
      getSelectRemarkFromManager,
      getSelectDueCompliance,
      selectedLocation
    );
  };

  const [getSlectedCompany, setSlectedCompany] = useState("");
  const [getSlectedCompanyName, setSlectedCompanyName] = useState("");
  const handelCompanySelect = (e) => {
    setSlectedCompany(e.value);
    setSlectedCompanyName(e.label);
    comlianceList(
      activePage,
      e.value,
      getSlectedState,
      getSlectedStatus,
      getSlectedRisk,
      getSlectedDate,
      debounceValue,
      getSelectRemarkFromManager,
      getSelectDueCompliance,
      selectedLocation
    );
  };

  const [getSlectedState, setSlectedState] = useState("");
  const handelStateSelect = (e) => {
    setSlectedState(e.target.value);
    comlianceList(
      activePage,
      getSlectedCompany,
      e.target.value,
      getSlectedStatus,
      getSlectedRisk,
      getSlectedDate,
      debounceValue,
      getSelectRemarkFromManager,
      getSelectDueCompliance,
      selectedLocation
    );
  };

  const [getSlectedStatus, setSlectedStatus] = useState("");
  const handelStatusSelect = (e) => {
    setSlectedStatus(e.target.value);
    if (e.target.value === "") {
      navigate("/compliance");
      window.location.reload();
    }
    comlianceList(
      activePage,
      getSlectedCompany,
      getSlectedState,
      e.target.value,
      getSlectedRisk,
      getSlectedDate,
      debounceValue,
      getSelectRemarkFromManager,
      getSelectDueCompliance,
      selectedLocation
    );
  };

  const [getSlectedRisk, setSlectedRisk] = useState("");
  const handelRiskSelect = (e) => {
    setSlectedRisk(e.target.value);
    comlianceList(
      activePage,
      getSlectedCompany,
      getSlectedState,
      getSlectedStatus,
      e.target.value,
      getSlectedDate,
      debounceValue,
      getSelectRemarkFromManager,
      getSelectDueCompliance,
      selectedLocation
    );
  };

  const [getSlectedDate, setSlectedDate] = useState("");
  const [getSlectedDate2, setSlectedDate2] = useState("");
  const handelDateSelect = (e) => {
    const { name, value } = e.target;
    if (name === "from") {
      setSlectedDate(value);
    } else {
      setSlectedDate2(value);
    }
  };

  const [getSlectedSearch, setSlectedSearch] = useState("");
  // debounce hook
  const debounceValue = useDebounce(getSlectedSearch, 500);

  const [getSelectRemarkFromManager, setSelectRemarkFromManager] = useState("");

  // remark handle
  const [remarkData, setRemarkData] = useState([]);
  const [totalRemarks, setTotalRemarks] = useState("");
  const handleSelectRemarkFromManager = async (e) => {
    try {
      var res = await remarkFromPartnerData();
      if (res.status === 200) {
        setRemarkData(res.data.remark);
        setTotalRemarks(res.data.total);
      } else {
        toast.error(res.messa);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const [getSelectDueCompliance, setSelectDueCompliance] = useState("");
  const handleSelectDueCompliance = (e) => {
    if (getSelectDueCompliance == "") {
      setSelectDueCompliance(moment().format("YYYY/MM/DD"));
      comlianceList(
        activePage,
        getSlectedCompany,
        getSlectedState,
        getSlectedStatus,
        getSlectedRisk,
        getSlectedDate,
        debounceValue,
        getSelectRemarkFromManager,
        moment().format("YYYY/MM/DD"),
        selectedLocation
      );
    } else {
      setSelectDueCompliance("");
      comlianceList(
        activePage,
        getSlectedCompany,
        getSlectedState,
        getSlectedStatus,
        getSlectedRisk,
        getSlectedDate,
        debounceValue,
        getSelectRemarkFromManager,
        "",
        selectedLocation
      );
    }
  };

  const [getUserToken, setUserToken] = useState("");
  const getAuthToken = async () => {
    var token = await AuthToken();
    setUserToken(token);
  };

  //   reset filter
  const resetFilter = () => {
    setSlectedCompany("");
    setSlectedState("");
    setSlectedStatus("");
    setSlectedRisk("");
    setSlectedDate("");
    setSlectedDate2("");
    setSlectedSearch("");
    setSelectedLocation("");
    setSelectRemarkFromManager("");
    setFlag(!flag);
    navigate("/compliance");
    window.location.reload();
  };

  // route filter
  useEffect(() => {
    riskList();
    companyList();
    getAuthToken();
    handleSelectRemarkFromManager();

    // route filter
    const statusSchema = {
      overdue: "2",
      complied: "3",
      cantdo: "6",
      "pending-for-compliance": "0",
      "pending-for-approval": "1",
      "not-applicable": "4",
      "reject-by-manage": "5",
      "one-time": "7",
      "due-today": "8",
    };
    if (type) {
      setSlectedStatus(statusSchema[type]);
    } else {
      setSlectedStatus("");
    }
    // comlianceList();

    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    comlianceList();
  }, [getSlectedStatus, debounceValue]);

  useEffect(() => {
    stateList(getSlectedCompany);
  }, [getSlectedCompany]);

  useEffect(() => {
    locationList(getSlectedCompany, getSlectedState);
    // eslint-disable-next-line
  }, [getSlectedState]);

  useEffect(() => {
    if (getSlectedDate && getSlectedDate2) {
      comlianceList();
    }
    if (!getSlectedDate && !getSlectedDate2) {
      comlianceList();
    }
    // eslint-disable-next-line
  }, [getSlectedDate, getSlectedDate2]);

  // *********get dynamic dashboard details**********

  const getDynamicDashboardDetail = async (id) => {
    try {
      setDashboardLoading(true);
      var res = await getDashboardDetailsByCompany(id);

      if (res.status === 200) {
        setDashboardData(res.data);
        setDashboardLoading(false);
      } else {
        toast.error(res.message);
        setDashboardLoading(false);
      }
    } catch (err) {
      setDashboardLoading(false);
      toast.error(err);
    }
  };

  //   reset data
  useEffect(() => {
    comlianceList(activePage);
    // eslint-disable-next-line
  }, [flag]);

  useEffect(() => {
    comlianceList();
    riskList();
    companyList();
    getAuthToken();
    handleSelectRemarkFromManager();
    // eslint-disable-next-line
  }, [type]);

  // *********get dashboard details**********
  // useEffect(() => {
  //   const getDashboardDetail = async () => {
  //     try {
  //       var res = await getDashboardDetails();
  //       if (res.status === 200) {
  //         setDashboardData(res.data);
  //       } else {
  //         toast.error(res.message);
  //       }
  //     } catch (err) {
  //       toast.error(err);
  //     }
  //   };
  //   getDashboardDetail();
  // }, []);

  // get dynamic dashboard
  useEffect(() => {
    getDynamicDashboardDetail(getSlectedCompany);
  }, [getSlectedCompany]);

  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_compliance">
      <Header />
      <div className="container">
        <div className="wrapper_cards_consultant">
          <div className="cards_consult">
            <div className="cstm_cards_cols">
              {/* loading skeleton */}
              {dashboardLoading && (
                <>
                  {countArray(10).map((data) => {
                    return (
                      <div
                        className="small-box custom_div_styling p-0"
                        key={data}
                      >
                        <CardSkeleton />
                      </div>
                    );
                  })}
                </>
              )}
              {/* main component */}
              {!dashboardLoading && (
                <>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance");
                      // window.location.reload();
                    }}
                    className="small-box  custom_div_styling text-dark"
                  >
                    <div className="inner">
                      <h3>{dashboardData.totalCompliances || 0}</h3>
                      <p>Total Compliance</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/complied");
                      // window.location.reload();
                    }}
                    className="small-box text-dark  custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{dashboardData.totalComplied || 0}</h3>
                      <p>Complied</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/overdue");
                      // window.location.reload();
                    }}
                    className="small-box  custom_div_styling text-dark"
                  >
                    <div className="inner">
                      <h3>{dashboardData.totalOverDue || 0}</h3>
                      <p>Over Due</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/due-today");
                      // window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{dashboardData.dueToday || 0}</h3>
                      <p>Due Today</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/pending-for-compliance");
                      // window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{dashboardData.totalPending || 0}</h3>
                      <p>Pending For Compliance</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/pending-for-approval");
                      // window.location.reload();
                    }}
                    className="small-box custom_div_styling text-dark"
                  >
                    <div className="inner">
                      <h3>{dashboardData.totalPendingForApproval || 0}</h3>
                      <p>Pending for approval</p>
                    </div>
                  </a>
                  {/* <a
                role="button"
                onClick={() => {
                  navigate("/compliance/one-time");
                  // window.location.reload();
                }}
                className="small-box  custom_div_styling text-dark"
              >
                <div className="inner">
                  <h3>{dashboardData.lifeTime}</h3>
                  <p>One Time</p>
                </div>
              </a> */}
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/cantdo");
                      // window.location.reload();
                    }}
                    className="small-box  custom_div_styling text-dark"
                  >
                    <div className="inner">
                      <h3>{dashboardData.cantDo || 0}</h3>
                      <p>Can't Do</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/reject-by-manage");
                      // window.location.reload();
                    }}
                    className="small-box  custom_div_styling text-dark"
                  >
                    <div className="inner">
                      <h3>{dashboardData.totalRejectedByManager || 0}</h3>
                      <p>Rejected By Manager</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/not-applicable");
                      // window.location.reload();
                    }}
                    className="small-box  custom_div_styling text-dark"
                  >
                    <div className="inner">
                      <h3>{dashboardData.totalNotApplicable || 0}</h3>
                      <p>Not Applicable</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/compliance/one-time");
                      // window.location.reload();
                    }}
                    className="small-box  custom_div_styling text-dark"
                  >
                    <div className="inner">
                      <h3>{dashboardData.lifeTime || 0}</h3>
                      <p>One Time</p>
                    </div>
                  </a>
                </>
              )}
            </div>
          </div>
          <div className="custom_search">
            <div className="form_field_wrap">
              <div className="form_field">
                {/* <select
                  className="input"
                  onChange={(e) => handelCompanySelect(e)}
                  value={getSlectedCompany}
                >
                  <option value="">All Companies</option>
                  {getCompany && getCompany.length > 0
                    ? getCompany.map((value, i) => (
                        <option value={value.companyId}>
                          {value.companyName}
                        </option>
                      ))
                    : ""}
                </select> */}

                <Select
                  className="py-1"
                  options={[
                    { label: "All Companies", value: "" },
                    ...getCompany.map((data) => {
                      return { label: data.companyName, value: data._id };
                    }),
                  ]}
                  value={{
                    value: getSlectedCompany,
                    label: getSlectedCompanyName || "All Companies",
                  }}
                  onChange={handelCompanySelect}
                  isFocused
                  isSearchable={false}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "white" : "white",
                      color: state.isSelected ? "black" : "black",
                    }),
                  }}
                />
              </div>
            </div>

            <div className="form_field_wrap">
              <div className="form_field">
                <select
                  className="input"
                  onChange={(e) => handelStateSelect(e)}
                  value={getSlectedState}
                >
                  <option value="">All States</option>
                  {getState && getState.length > 0
                    ? getState.map((value, i) => (
                        <option value={value}>{value}</option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="form_field_wrap">
              <div className="form_field">
                <select
                  className="input"
                  onChange={(e) => handelLocationSelect(e)}
                  value={selectedLocation}
                >
                  <option value="">All Locations</option>
                  {location && location.length > 0
                    ? location.map((value, i) => (
                        <option value={value}>{value}</option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            {/* <div className="form_field_wrap">
              <div className="form_field">
                <select className="input">
                  <option>Select location</option>
                  <option>Active</option>
                  <option>InActive</option>
                </select>
              </div>
            </div> */}
            <div className="form_field_wrap">
              <div className="form_field">
                <select
                  className="input"
                  onChange={(e) => handelStatusSelect(e)}
                  value={getSlectedStatus}
                >
                  <option value="">All Status</option>
                  <option value={0}>Pending For Compliance</option>
                  <option value={1}>Pending For Approval</option>
                  <option value={2}>Overdue</option>
                  <option value={6}>Can't do</option>
                  <option value={3}>Complied</option>
                  <option value={4}>Not Applicable</option>
                  <option value={5}>Rejected By Manager</option>
                  <option value={7}>One Time</option>
                  <option value={8}>Due Today</option>
                </select>
              </div>
            </div>
            <div className="form_field_wrap">
              <div className="form_field">
                <select
                  className="input"
                  onChange={(e) => handelRiskSelect(e)}
                  value={getSlectedRisk}
                >
                  <option value="">All Risks</option>
                  {getRisk && getRisk.length > 0
                    ? getRisk.map((value, i) => (
                        <option value={value}>{value}</option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
          </div>
          <div className="custom_search remarks_tempo">
            <div className="form_field_wrap remark_tempo">
              <div className="form_tempo">
                <input
                  type="search"
                  className="input"
                  placeholder="Search by uin,law area,act/rule"
                  value={getSlectedSearch}
                  onChange={(e) => setSlectedSearch(e.target.value)}
                />
                {/* <Select
                  options={optionList}
                  placeholder="Search by act name, rule name etc…"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                // isMulti
                /> */}
              </div>
            </div>

            {/* <div className="form_field_wrap remarks">
              <Button
                variant="primary"
                onClick={(e) => handleSelectDueCompliance(e)}
              >
                Due Compliance <Badge bg="secondary"></Badge>
                <span className="visually-hidden">unread messages</span>
              </Button>
            </div> */}
            <div className="form_field_wrap data_date w-25">
              <div className="form_field mx-2">
                <input
                  className="input"
                  name="from"
                  onChange={handelDateSelect}
                  value={getSlectedDate}
                  placeholder="From Date"
                  type="date"
                />
              </div>
            </div>
            <div className="form_field_wrap data_date px-2 w-25">
              <div className="form_field">
                <input
                  className="input"
                  name="to"
                  value={getSlectedDate2}
                  onChange={handelDateSelect}
                  placeholder="To Date"
                  type="date"
                />
              </div>
            </div>

            {isLoggedIn().role == "Executor" && (
              <div className="form_field_wrap remarks">
                <button
                  type="button"
                  className="btn btn-primary position-relative mx-2"
                  onClick={(e) => setRemarkShow(true)}
                >
                  Remark from manager
                  {totalRemarks && (
                    <span className="position-absolute top-0 start-75 translate-middle badge rounded-pill badge-danger">
                      {totalRemarks}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  )}
                </button>
              </div>
            )}

            <div className="form_field_wrap">
              <button className="download-excel px-2" onClick={resetFilter}>
                {/* <span>
                  <GrPowerReset color="white" />
                </span> */}
                Reset
              </button>
            </div>
            <div className="form_field_wrap">
              {/* <a onClick={handleExcelDownload}> */}
              <button
                className="download-excel"
                onClick={() =>
                  window.open(
                    `${Url}/compliance/downloadExcel?state=${getSlectedState}&company=${getSlectedCompany}&executerStatus=${getSlectedStatus}&risk=${getSlectedRisk}&isExecuterApplicable=&isCompiled=&currentDate=${getSelectDueCompliance}&from_date=${getSlectedDate}&to_date=${getSlectedDate2}&location=${selectedLocation}&managerStatus=&email=${
                      localStorage.getItem("userLogin") &&
                      JSON.parse(localStorage.getItem("userLogin")).email
                    }&role=${
                      localStorage.getItem("userLogin") &&
                      JSON.parse(localStorage.getItem("userLogin")).role
                    }`
                  )
                }
              >
                <span>
                  <BsDownload />
                </span>
                Download excel
              </button>
            </div>
          </div>
          <div className="data_table">
            <Table striped bordered hover>
              <thead>
                <tr className="custom_tr">
                  <th>UIN</th>
                  <th>State</th>
                  <th>LOCATION</th>
                  <th>LAW AREA</th>
                  <th>ACT/RULE</th>
                  <th>COMPLIANCE</th>
                  <th>STATUS</th>
                  <th>DUE DATE</th>
                  <th>RISK</th>
                  <th>MORE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {getCompliance && getCompliance.length > 0 ? (
                  getCompliance.map((value, i) => (
                    <tr className="cstm_tr">
                      <td>{value.uin}</td>
                      <td>
                        <div className="state_name">{value.state}</div>
                      </td>
                      <td>
                        <div>
                          <Tooltip limit={35}>{value.location}</Tooltip>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Tooltip limit={45}>{value.lawArea}</Tooltip>
                        </div>
                      </td>
                      <td>
                        <div>
                          {/* <p className="tooltips-effect">
                            {`${value.act.slice(0, 25)} ${
                              value.act.length > 25 ? "..." : ""
                            }`}
                            <span className="tooltiptext">{value.act}</span>
                          </p> */}
                          <Tooltip limit={45}>{value.act}</Tooltip>
                        </div>
                      </td>
                      <td className="text-capitalize">
                        <div>
                          <Tooltip limit={45}>{value.compliance}</Tooltip>
                        </div>
                      </td>
                      {/* {/ <td>{value.executercomplianceStatus ? (value.status ? 'Accepted' : 'Pending') : 'Due'}</td> /} */}
                      <td className="text-capitalize">
                        <div>
                          {value.managerStatus !== "pending for compliance"
                            ? value.managerStatus
                            : new Date() < new Date(value.dueDate)
                            ? "Pending For Compliance"
                            : moment().format("YYYY-MM-DD") ==
                              moment(value.dueDate).format("YYYY-MM-DD")
                            ? "Due Today"
                            : "Overdue"}
                        </div>
                      </td>
                      <td>{dateFormat(value.dueDate)}</td>
                      <td>{value.risk}</td>
                      <td>
                        {/* <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={() =>
                          // onClickShowMoreData(
                            // value.executerEmails,
                            // value.managerEmails,
                            // value.periodicity,
                            // // value Head Email..
                            // value.headEmails,
                            // value.uin,
                            // value.department,
                            setExecutorEmail(value.executerEmails.map((e) => e.email)),
                            setManagerEmail(value.managerEmails.map((e) => e.email)),
                            setHeadEmail(value.headEmails.map((e) => e.email)),
                            setPeriodicity(value.periodicity),
                            setDetailUin(value.uin),
                            setDepartment(value.department),
                            handleShow()

                          
                        }
                      >
                        More
                      </Button> */}
                        <Button
                          className="custom_btn"
                          variant="primary"
                          onClick={() => {
                            // Uncomment the following lines
                            setExecutorEmail(
                              value.executerEmails.map((e) => e.email)
                            );
                            setManagerEmail(
                              value.managerEmails.map((e) => e.email)
                            );
                            setHeadEmail(value.headEmails.map((e) => e.email));
                            setPeriodicity(value.periodicity);
                            setDetailUin(value.uin);
                            setDepartment(value.department);
                            handleShow();
                          }}
                        >
                          More
                        </Button>
                      </td>
                      <td>
                        {isLoggedIn().role == "Executor" ||
                        isLoggedIn().role == "Head" ? (
                          value.complianceStatus ? (
                            <Link
                              to={`/compliance/view-acts/${value.location}/${value.uin}/${value._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon">
                                <FaEye />
                              </span>
                            </Link>
                          ) : (
                            <Link
                              to={`/compliance/view-acts/${value.location}/${value.uin}/${value._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon">
                                <FaEye />
                              </span>
                            </Link>
                          )
                        ) : isLoggedIn().role === "Manager" ? (
                          (value.managerStatus
                            ? value.managerStatus
                            : moment().format("YYYY/MM/DD") <
                              moment(value.dueDate).format("YYYY-MM-DD")
                            ? "pending for compliance"
                            : "Overdue") === "pending for compliance" ||
                          (value.managerStatus
                            ? value.managerStatus
                            : moment().format("YYYY/MM/DD") <
                              moment(value.dueDate).format("YYYY-MM-DD")
                            ? "Pending For Compliance"
                            : "overdue") === "overdue" ||
                          !value.executercomplianceStatus ? (
                            <Link
                              to={`/manager/compliance/compliance-detail-page/${value.location}/${value.uin}/${value._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon">
                                <FaEye />
                              </span>
                            </Link>
                          ) : value.complianceStatus ? (
                            <Link
                              to={`/manager/compliance/compliance-detail-page/${value.location}/${value.uin}/${value._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon">
                                <FaEye />
                              </span>
                            </Link>
                          ) : (
                            <Link
                              to={`/manager/compliance/compliance-detail-page/${value.location}/${value.uin}/${value._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon">
                                <FaEye />
                              </span>
                            </Link>
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <NotFound col={11} />
                )}
              </tbody>
            </Table>
            <div className="w-100 d-flex justify-content-center">{loader}</div>
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={getComplianceCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          </div>

          {/* Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design"
          >
            {/* <Modal.Header closeButton></Modal.Header> */}
            <span className="modalclose" onClick={handleClose}>
              {" "}
              X
            </span>
            <Modal.Body>
              {/* chnages for adding head  */}
              {/* <div className="wrapper-modal-div">
                <div className="modal_uin">
                  <div className="modal_exce">
                 
                    <p>Department</p>
                    <span>{department}</span>
                  </div>
                  <div className="modal_exce">
                    <p>Executor</p>
                    <span>{getExecutorEmail}</span>
                  </div>
                </div>
                <div className="modal_uin modelpopup">
                  <div className="modal_exce">
                    <p>Manager</p>
                    <span>{getManagerEmail}</span>
                  </div>
                  <div className="modal_exce">
                    <p>Periodicity</p>
                    <span>{getPeriodicity}</span>
                  </div>
                </div>
              </div> */}
              {/* changes end */}
              <div className="wrapper-modal-div">
                <div className="modal_uin">
                  <div className="modal_exce">
                    {/* second Modal call  */}
                    <p>Manager Emails</p>
                    <b>
                      {getManagerEmail ? (
                        <button
                          className="custom_btn_2"
                          onClick={() => {
                            setEmailModal(true);
                            setEmail(getManagerEmail);
                          }}
                        >
                          Manager Emails
                        </button>
                      ) : (
                        ""
                      )}
                    </b>
                  </div>
                  <div className="modal_exce">
                    <p>Executor Emails</p>
                    <b>
                      {getManagerEmail ? (
                        <button
                          className="custom_btn_2"
                          onClick={() => {
                            setEmailModal(true);
                            setEmail(getExecutorEmail);
                          }}
                        >
                          Executor Emails
                        </button>
                      ) : (
                        ""
                      )}
                    </b>
                  </div>
                  <div className="modal_exce">
                    <p>Head Emails</p>
                    <b>
                      {getManagerEmail ? (
                        <button
                          className="custom_btn_2 "
                          onClick={() => {
                            setEmailModal(true);
                            setEmail(getHeadEmail);
                          }}
                        >
                          Head Emails
                        </button>
                      ) : (
                        ""
                      )}
                    </b>
                  </div>
                </div>
                <div className="modal_uin modelpopup">
                  <div className="modal_exce">
                    <p>Department</p>
                    <span>{getDepartment}</span>
                  </div>
                  <div className="modal_exce">
                    <p>Periodicity</p>
                    <span>{getPeriodicity}</span>
                  </div>
                  <div className="modal_exce">{/* let it Empty  */}</div>
                </div>
              </div>

              {/* //Ends Modal  */}
            </Modal.Body>
          </Modal>

          {/* Remark Modal */}
          <Modal
            show={remarkShow}
            onHide={() => setRemarkShow(false)}
            // backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_cstm_design full-width-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Title></Modal.Title>
            <Modal.Body>
              <div className="wrapper-modal-div data-table text-center modal-table overflow-auto">
                <Table striped bordered hover>
                  <thead>
                    <tr className="custom_tr">
                      <th>UIN</th>
                      <th>Location</th>
                      <th>Remark</th>
                      <th>Manager Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterate all remarks */}
                    {remarkData && remarkData.length > 0 ? (
                      remarkData.map(
                        ({
                          _id,
                          uin,
                          location,
                          remarkFromManager,
                          companyComplianceId,
                          managerComplianceStatus,
                        }) => {
                          return (
                            <tr key={_id} className="cstm_tr">
                              <td>{uin}</td>
                              <td className="text-capitalize">{location}</td>

                              <td>
                                <p
                                  className="text-truncate"
                                  style={{ maxWidth: "590px" }}
                                  title={
                                    remarkFromManager[
                                      remarkFromManager.length - 1
                                    ]?.remark
                                  }
                                >
                                  {
                                    remarkFromManager[
                                      remarkFromManager.length - 1
                                    ]?.remark
                                  }
                                </p>
                              </td>
                              <td className="text-capitalize">
                                {managerComplianceStatus}
                              </td>
                              <td>
                                <Link
                                  to={`/compliance/view-acts/${location}/${uin}/${companyComplianceId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span className="icon">
                                    <FaEye />
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>No Remarks Available</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>

      {/* Model for displaying Head,Manager And Executer Email  */}
      <Modal
        show={emailModal}
        onHide={() => setEmailModal(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="py-0">
          <div className="data_table p-0" style={{ height: "17rem" }}>
            <Table striped bordered hover style={{ minWidth: "100%" }}>
              <thead>
                <tr className="custom_tr">
                  <th>Emails</th>
                </tr>
              </thead>
              <tbody>
                {email && email.length > 0
                  ? email.map((email, i) => (
                      <tr key={++i} className="cstm_tr">
                        <td>{email}</td>
                      </tr>
                    ))
                  : "Not Found"}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Compliance;
