import React from "react";
import { Placeholder } from "react-bootstrap";

const CardSkeleton = ({ height = "118" }) => {
  return (
    <>
      <Placeholder
        as="div"
        animation="wave"
        aria-hidden="true"
        className="w-100 "
      >
        <Placeholder
          xs={12}
          style={{ height: `${height}px`, borderRadius: "5px" }}
          className="w-100"
        />
      </Placeholder>
    </>
  );
};

export default CardSkeleton;
