import { api, AuthToken } from "./AuthController";

export async function getDashboardDetails() {
  var token = await AuthToken();

  var res = await api.get(`deshboard/userDeshboard`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
}

export async function getDashboardDetailsByCompany(id) {
  var token = await AuthToken();

  var res = await api.get(`compliance/dynamicDeshboard/?companyId=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
}

export async function getLitigationDashboards() {
  var token = await AuthToken();

  var res = await api.get(`deshboard/letigationDeshboard`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
}

export async function getRegistrationDashboards() {
  var token = await AuthToken();

  var res = await api.get(`deshboard/registrationDeshboard`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
}

export async function getUpcomingDashboards() {
  var token = await AuthToken();

  var res = await api.get(`deshboard/upcomingCompliance`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
}
export async function getChartDashboards() {
  var token = await AuthToken();

  var res = await api.get(`deshboard/riskGraph`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
}
