import React, { useState } from "react";
import "./Registration.css";
import Header from "../header/Header";
import Select from "react-select";
import { FaUpload } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import { useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FileUploader } from "react-drag-drop-files";

function Upload() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const ref = useRef();

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_library wrapper_upload1">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="custom_search remarks_tempo">
              <div className="form_tempo">
                <Select
                  options={optionList}
                  placeholder="Search by document name"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  // isMulti
                />
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select className="input">
                    <option>Select State</option>
                    <option>Uttar Pradesh</option>
                    <option>Delhi</option>
                  </select>
                </div>
                <div className="form_field">
                  <button className="download-excel">
                    <span>
                      <BsDownload />
                    </span>
                    Download excel
                  </button>
                </div>
              </div>
            </div>
            <div className="data_table">
              <Table striped bordered hover>
                <thead>
                  <tr className="custom_tr">
                    <th>S.N0.</th>
                    <th>DOCUMENT</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cstm_tr">
                    <td>1</td>
                    <td>Document 1</td>
                    <td>Mandatory</td>
                    <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </Button>
                      {/* <button>
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </button> */}
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>2</td>
                    <td>Document 2</td>
                    <td>Mandatory</td>
                    <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </Button>
                      {/* <button>
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </button> */}
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>3</td>
                    <td>Document 3</td>
                    <td>Mandatory</td>
                    <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </Button>
                      {/* <button>
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </button> */}
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>4</td>
                    <td>Document 4</td>
                    <td>Mandatory</td>
                    <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </Button>
                      {/* <button>
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </button> */}
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>5</td>
                    <td>Document 5</td>
                    <td>Non Mandatory</td>
                    <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </Button>
                      {/* <button>
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </button> */}
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>6</td>
                    <td>Document 6</td>
                    <td>Non Mandatory</td>
                    <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </Button>
                      {/* <button>
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </button> */}
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>7</td>
                    <td>Document 7</td>
                    <td>Non Mandatory</td>
                    <td>
                      <Button
                        className="custom_btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </Button>
                      {/* <button>
                        <span>
                          <FaUpload />
                        </span>
                        Upload
                      </button> */}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          // backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal_cstm_design4"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h6>Upload File</h6>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="Fileuploader_cstm">
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                // types={fileTypes}
              />
            </div>
            <p>
              {file ? `File name: ${file[0].name}` : "no files uploaded yet"}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="custom_btn"
              variant="secondary"
              onClick={handleClose}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
}

export default Upload;
