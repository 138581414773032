import { React, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import "../piechart/pie.css";
import Header from "../Layout/Header";
import PieChart from "../piechart/PieChart";
import {
  getChartDashboards,
  getDashboardDetails,
  getLitigationDashboards,
  getUpcomingDashboards,
} from "../../controller/DashboradController";
import { useState } from "react";
import { toast } from "react-toastify";
import { countArray, dateFormat } from "../../utils";
import CardSkeleton from "../../components/skeleton/CardSkeleton";

function Home() {
  const [dashboardData, setDashboardData] = useState({});
  const [litigationData, setLitigationData] = useState({});
  const [upcomingData, setUpcomingData] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [dashboardLoading2, setDashboardLoading2] = useState(false);
  const [dashboardLoading3, setDashboardLoading3] = useState(false);
  const [chartData, setChartData] = useState({});
  const [limit, setLimit] = useState(5);

  // full page loader
  const navigate = useNavigate();
  useEffect(() => {
    var local = localStorage.getItem("userLogin");
    if (!local) {
      navigate("/login");
    }
  }, []);

  // *********get dashboard details**********
  useEffect(() => {
    const getDashboardDetail = async () => {
      try {
        setDashboardLoading(true);
        var res = await getDashboardDetails();
        if (res.status === 200) {
          setDashboardData(res.data);
          setDashboardLoading(false);
        } else {
          toast.error(res.message);
          setDashboardLoading(false);
        }
      } catch (err) {
        toast.error(err);
        setDashboardLoading(false);
      }
    };
    getDashboardDetail();
  }, []);

  // *********get dashboard details**********
  useEffect(() => {
    const getLitigationDashboard = async () => {
      try {
        setDashboardLoading2(true);
        var res = await getLitigationDashboards();
        if (res.status === 200) {
          setLitigationData(res.data);
          setDashboardLoading2(false);
        } else {
          toast.error(res.message);
          setDashboardLoading2(false);
        }
      } catch (err) {
        setDashboardLoading2(false);
        toast.error(err);
      }
    };
    getLitigationDashboard();
  }, []);

  // *********get upcoming dashboard details**********
  useEffect(() => {
    const getUpcomingDashboard = async () => {
      try {
        setDashboardLoading3(true);
        var res = await getUpcomingDashboards();
        if (res.status === 200) {
          setUpcomingData(res.data);
          setDashboardLoading3(false);
        } else {
          toast.error(res.message);
          setDashboardLoading3(false);
        }
      } catch (err) {
        setDashboardLoading3(false);

        toast.error(err);
      }
    };
    getUpcomingDashboard();
  }, []);

  // *********get chart dashboard details**********
  useEffect(() => {
    const getChartDashboard = async () => {
      try {
        var res = await getChartDashboards();
        if (res.status === 200) {
          setChartData(res.data);
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err);
      }
    };
    getChartDashboard();
  }, []);

  return (
    <>
      <Header />
      <section className="home_page">
        <div className="container">
          <div className="home_page_select_date">
            {/* <div className="title_bar">
              <div className="form_field">
                <select className="input">
                  <option>Choose company</option>
                  <option>Select Industry</option>
                </select>
              </div>
              <div className="calender">
                <input type="date" />
              </div>
            </div> */}
          </div>
          <div className="wrapper_compliance_section">
            <div className="complaince_piechart">
              <div className="Complaince">
                <div className="complaince_text">
                  <h5>Compliance</h5>
                </div>
                <div className="cards_consult">
                  {/* loading skeleton */}
                  {dashboardLoading && (
                    <div className="row">
                      {countArray(10).map((data) => {
                        return (
                          <div className="col-lg-3 col-6" key={data}>
                            <div className="small-box  custom_div_styling">
                              <CardSkeleton height="90" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* main component */}
                  {!dashboardLoading && (
                    <div className="row">
                      <div className="col-lg-3 col-6">
                        <Link to="/compliance" className="d-block text-dark">
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.totalCompliances || 0}</h3>
                              <p>Total Compliance</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/complied"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.totalComplied || 0}</h3>
                              <p>Complied</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/overdue"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.totalOverDue || 0}</h3>
                              <p>Over Due</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/due-today"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.dueToday || 0}</h3>
                              <p>Due Today</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/pending-for-approval"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>
                                {dashboardData.totalPendingForApproval || 0}
                              </h3>
                              <p>Pending for approval</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/pending-for-compliance"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.totalPending || 0}</h3>
                              <p>Pending For Compliance</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/cantdo"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.cantDo || 0}</h3>
                              <p>Can't Do</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/reject-by-manage"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>
                                {dashboardData.totalRejectedByManager || 0}
                              </h3>
                              <p>Rejected By Manager</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/not-applicable"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.totalNotApplicable || 0}</h3>
                              <p>Not Applicable</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Link
                          to="/compliance/one-time"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{dashboardData.lifeTime || 0}</h3>
                              <p>One Time</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      {/* <div className="col-lg-3 col-6">
                      <Link
                        to="/compliance/one-time"
                        className="d-block text-dark"
                      >
                        <div className="small-box  custom_div_styling">
                          <div className="inner">
                            <h3>{dashboardData.lifeTime}</h3>
                            <p>One Time</p>
                          </div>
                        </div>
                      </Link>
                    </div> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="pie_chart">
                <div className="pie">
                  <div className="complaince_text">
                    <h5>Risk analysis</h5>
                  </div>
                  <div className="upcoming_list piechart_wrap">
                    <PieChart chartData={chartData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="complaince_piechart">
              <div className="Complaince">
                <div className="complaince_text">
                  <h5>Litigation</h5>
                </div>
                <div className="cards_consult">
                  {/* loading skeleton */}
                  {dashboardLoading2 && (
                    <div className="row">
                      {countArray(6).map((data) => {
                        return (
                          <div className="col-lg-4 col-6" key={data}>
                            <div className="small-box  custom_div_styling">
                              <CardSkeleton height="90" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* main component */}
                  {!dashboardLoading2 && (
                    <div className="row">
                      <div className="col-lg-4 col-6">
                        <Link to="/notices" className="d-block text-dark">
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{litigationData.totalLetigation || 0}</h3>
                              <p>Total</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-6">
                        <Link to="/notices/open" className="d-block text-dark">
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{litigationData.totalOpenLetigation || 0}</h3>
                              <p>Open</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-6">
                        <Link to="/notices/close" className="d-block text-dark">
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>
                                {litigationData.totalCloseLetigation || 0}
                              </h3>
                              <p>Closed</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-6">
                        <Link
                          to="/notices/no-action-required"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>
                                {litigationData.totalNoActionLetigation || 0}
                              </h3>
                              <p>No Action Required</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-6">
                        <Link
                          to="/notices/due-today"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>{litigationData.countTodayDue || 0}</h3>
                              <p>Due Today</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-6">
                        <Link
                          to="/notices/upcoming-hearing"
                          className="d-block text-dark"
                        >
                          <div className="small-box  custom_div_styling">
                            <div className="inner">
                              <h3>
                                {litigationData.totalUpcomingLetigation || 0}
                              </h3>
                              <p>Upcoming Hearing</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="pie_chart">
                <div className="pie">
                  <div className="complaince_text">
                    <h5>Upcoming compliances</h5>
                  </div>
                  {/* loading skeleton */}
                  {dashboardLoading3 && (
                    <div
                      className="upcoming_list overflow-auto text-break"
                      style={{ height: "18rem" }}
                    >
                      <ul>
                        {countArray(5).map((data) => {
                          return (
                            <li className="d-block" key={data}>
                              <CardSkeleton height="35" />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {/* main component */}
                  {!dashboardLoading3 && (
                    <div
                      className="upcoming_list overflow-auto text-break"
                      style={{ height: "18rem" }}
                    >
                      <ul>
                        {upcomingData && upcomingData.length > 0 ? (
                          upcomingData
                            .slice(0, limit)
                            .map(({ _id, name, ComplianceDate }) => {
                              return (
                                <li key={_id} className="d-block">
                                  <div>{name}</div>
                                  <br />
                                  <div>Date : {dateFormat(ComplianceDate)}</div>
                                </li>
                              );
                            })
                        ) : (
                          <li>
                            <strong className="w-100 text-center">
                              Compliance Not Available
                            </strong>
                          </li>
                        )}
                      </ul>
                      {upcomingData.length > 5 &&
                        (limit === 5 ? (
                          <button
                            className="custom_btn2 float-end my-2"
                            onClick={() => setLimit(upcomingData.length)}
                          >
                            View All
                          </button>
                        ) : (
                          <button
                            className="custom_btn2 float-end my-2"
                            onClick={() => setLimit(5)}
                          >
                            View Less
                          </button>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
