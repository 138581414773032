import { api, AuthToken } from "./AuthController";

export async function companyData() {
  var token = await AuthToken();

  var res = await api.get(`compliance/companyFilter`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function locationData(id, stateName) {
  var token = await AuthToken();

  var res = await api.get(
    `/compliance/locationByState?companyId=${id}&state=${stateName}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function complianceData(data) {
  var token = await AuthToken();
  if (data.executerStatus === 3) {
    var res = await api.get(
      `compliance/listing?state=${data.state}&subCompany=${
        data.company
      }&status=${data.status || ""}&risk=${data.risk}&date=${
        data.date
      }&search=${data.search}&page=${data.page}&remarkManager=${
        data.remarkManager
      }&executerStatus=${data.executerStatus || ""}&isExecuterApplicable=${
        data.isExecuterApplicable
      }&isCompiled=${data.isCompiled}&location=${
        data.location
      }&managerStatus='accepted'&from_date=${data.from_date}&to_date=${
        data.to_date
      }`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } else {
    var res = await api.get(
      `compliance/listing?state=${data.state}&company=${data.company}&status=${
        data.status || ""
      }&risk=${data.risk}&search=${data.search}&page=${
        data.page
      }&remarkManager=${data.remarkManager}&executerStatus=${
        data.executerStatus || ""
      }&isExecuterApplicable=${data.isExecuterApplicable}&isCompiled=${
        data.isCompiled
      }&currentDate=${data.currentDate}&location=${data.location}&from_date=${
        data.from_date
      }&to_date=${data.to_date}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  }
}

export async function remarkFromPartnerData() {
  var token = await AuthToken();

  var res = await api.get(`compliance/remarkListingFromManager`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function stateData(id) {
  var token = await AuthToken();

  var res = await api.get(`compliance/state?companyId=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function riskData() {
  var token = await AuthToken();

  var res = await api.get(`compliance/statusOrRisk`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function complianceDetail(data) {
  var token = await AuthToken();

  var res = await api.get(
    `compliance/uinDetails?uin=${data.uin}&companyComplianceId=${data.companyComplianceId}&complianceStartDate=${data.startDate}&complianceEndDate=${data.endDate}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function managerComplianceDetails(data) {
  var token = await AuthToken();

  var res = await api.get(
    `compliance/managerComplianceDetailsListing?companyComplianceId=${data.id}&uin=${data.uin}&complianceStartDate=${data.startDate}&complianceEndDate=${data.endDate}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res.data;
}

export async function storeNotAvailabilityData(data) {
  var token = await AuthToken();

  var res = await api.post(`compliance/execComplianceStatus`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function updateImage(data) {
  var token = await AuthToken();

  var res = await api.post(`compliance/imageUpdate`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function editAvailabilityData(data) {
  var token = await AuthToken();

  var res = await api.post(`compliance/updateExecuterData`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function storeManagerAcceptRejectComp(data) {
  var token = await AuthToken();

  var res = await api.post(`compliance/managerAcceptRejectComp`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function updateExecuterData(data) {
  var token = await AuthToken();

  var res = await api.post(`compliance/updateExecuterData`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}
