import moment from "moment";
import React from "react";
import imageUrl from "../../config/config";
import { dateFormat } from "../../utils";

const ManagerComplianceHistory = ({ compliance }) => {
  return (
    <>
      <div className="Managercompliance_details">
        <div className="row">
          <div className="col-lg-6">
            <div className="form_filed">
              <ul>
                <li>
                  <span>Compliance name</span>
                  <h5>{compliance ? compliance.complianceName : ""}</h5>
                </li>
                <li>
                  <span>Compliance Date</span>
                  <h5>
                    {compliance ? dateFormat(compliance.complianceDate) : ""}
                  </h5>
                </li>
                <li>
                  <div className="row">
                    <div className="col-6">
                      <span>Compliance Start Date</span>
                      <h5>
                        {compliance
                          ? dateFormat(compliance.complianceStartDate)
                          : ""}
                      </h5>
                    </div>
                    <div className="col-6">
                      <span>Compliance End Date</span>
                      <h5>
                        {compliance
                          ? dateFormat(compliance.complianceEndDate)
                          : ""}
                      </h5>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <span>Compliance Upload Date</span>
                  <h5>
                    {compliance
                      ? dateFormat(compliance.complianceUploadedDate)
                      : ""}
                  </h5>
                </li> */}
                {compliance ? (
                  <li>
                    <span>Penality Interest</span>
                    <h5>{compliance.penalityIntrest}</h5>
                  </li>
                ) : (
                  ""
                )}
                {compliance ? (
                  <li>
                    <span className="radio_copybtn"></span>
                    <h5>
                      {compliance.complied === "--"
                        ? "Not Applicable"
                        : compliance.complied}
                    </h5>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form_filed">
              <div className="heads_text">
                <h4>Attachments</h4>
              </div>
              <div className="wrapper_compliance">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr className="custom_tr">
                      <th>Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compliance.document.length > 0 ? (
                      compliance.document.map((file) => {
                        return (
                          <tr className="cstm_tr" key={file._id}>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span>{file.images}</span>
                                <button
                                  className="custom_btn btn btn-primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`${imageUrl}${file.images}`);
                                  }}
                                >
                                  Download
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No Documents Attached</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="remarks_manager_detail">
                <div className="heads_text">
                  <h4>Remarks</h4>
                </div>
                <p>{compliance ? compliance.remarks : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerComplianceHistory;
