import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Compliance.css";
import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";
import { useRef } from "react";

// import { useRef } from "react";

function NotApplicable() {
  //   const ref = useRef();

  const ref = useRef();
  const [fileName, SetFileName] = useState();

  const kyMbrImg = (e, i) => {
    // SetFileName(e.target.files[0].name);
    document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_applicable wrapper_not_applicable">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/executor/compliance/view-acts">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <select className="input">
                  <option>Select Month</option>
                  <option>Select Industry</option>
                </select>
              </div>
            </div>
            <div className="content_wrapper_acts">
              <ul>
                <li>
                  <span>Act name</span>
                  <h4>Act Name 1</h4>
                </li>
                <li>
                  <span>Rule name</span>
                  <h4>Labour Charge</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit
                  </p>
                </li>
                <li>
                  <span>Section/Rule</span>
                  <h4>Section 11B</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit
                  </p>
                </li>
                <li>
                  <span>Risk analysis</span>
                  <h4>Low</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit
                  </p>
                </li>
              </ul>
            </div>
            <form>
              <div className="content_wrapper_acts">
                <div className="upload_document">
                  <div className="form_control_message">
                    <span>Remarks</span>
                    <textarea
                      className="form-control"
                      id="form6Example7"
                      rows="4"
                      placeholder="Write something here…"
                    ></textarea>
                  </div>
                  <div className="heads_text">
                    <h4>Upload Documents</h4>
                    <span>(Optional)</span>
                  </div>
                  <div className="input_upload_file">
                    <label>
                      <input
                        className="input"
                        type="file"
                        onChange={kyMbrImg}
                      />
                      <div className="wrapper_upload_div">
                        <span className="upload_div1" id="image_">
                          file name {fileName}
                        </span>
                        <span className="Upload-file">+ Upload</span>
                      </div>
                    </label>
                    <span>+ Add more</span>
                  </div>
                </div>
              </div>
              <div className="submit_form_btn">
                <button className="custom_button">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotApplicable;
