import React from "react";
import { Link } from "react-router-dom";
import "./Registration.css";
import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";
import { BsDownload } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import file from "../../../assets/image/file.png";

function RegistrationFinal() {
  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_registration_detail wrapper_Registration_final">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Registration details</h5>
              </div>
            </div>
            <div className="content_wrapper_acts">
              <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Company name</span>
                  <b>Gourav & sons</b>
                </div>
                <div className="detail_section">
                  <span>Location</span>
                  <b>Noida</b>
                </div>
                <div className="detail_section">
                  <span>State</span>
                  <b>U.P.</b>
                </div>
                <div className="detail_section">
                  <span>Owner Name</span>
                  <b>Sourabh</b>
                </div>
                <div className="detail_section">
                  <span>Manager Name</span>
                  <b>Rahul singh</b>
                </div>
              </div>
              <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Act/rule</span>
                  <b>Section 11B</b>
                </div>
                <div className="detail_section">
                  <span>Registration type</span>
                  <b>Online</b>
                </div>
                <div className="detail_section">
                  <span>Registration no.</span>
                  <b>3472974972</b>
                </div>
                <div className="detail_section">
                  <span>Issue Date</span>
                  <b>22/02/2022</b>
                </div>
                <div className="detail_section">
                  <span>Expiry Date</span>
                  <b>22/03/2023</b>
                </div>
              </div>
              {/* <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Uploaded</span>
                  <b>Self</b>
                </div>
                <div className="detail_section">
                  <span>Consultant Mail</span>
                  <b>ashish@gmail.com</b>
                </div>
              </div> */}
            </div>

            <div className="data_table">
              <Table striped bordered hover>
                <thead>
                  <tr className="custom_tr">
                    <th>S.N0.</th>
                    <th>DOCUMENT</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cstm_tr">
                    <td>1</td>
                    <td>Document 1</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>2</td>
                    <td>Document 2</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>3</td>
                    <td>Document 3</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>4</td>
                    <td>Document 4</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>5</td>
                    <td>Document 5</td>
                    <td>Not Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>6</td>
                    <td>Document 6</td>
                    <td>Not Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>7</td>
                    <td>Document 7</td>
                    <td>Not Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="wrapper_docs_pdf">
              <div className="date-docs_cstm">
                <span>22/02/2022</span>
                <p>Enter issue Date</p>
              </div>
              <div className="date-docs_cstm">
                <span>22/02/2023</span>
                <p>Enter Expiry Date</p>
              </div>
            </div>
            <div className="wrapper_docs_pdf">
              <div className="date-docs_cstm">
                <div className="file_png_img">
                  <img src={file}></img>
                </div>
                <p>Upload License</p>
              </div>
              <div className="date-docs_cstm">
                <div className="file_png_img">
                  <img src={file}></img>
                </div>
                <p>Fees Attachment</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegistrationFinal;
