import React from "react";

const NotFound = ({ col = 5 }) => {
  return (
    <>
      <tr>
        <td colSpan={col}>No Data Found</td>
      </tr>
    </>
  );
};

export default NotFound;
