import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Registration.css";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { FaEye, FaEdit, FaTrashAlt, FaToggleOn } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { MdAutorenew } from "react-icons/md";
import Header from "../Layout/Header";
import Modal from "react-bootstrap/Modal";
import {
  companyData,
  deleteRegistration,
  locationData,
  registrationList,
  registrationType,
  remarkFromConsultant,
  statusRegistration,
} from "../../controller/RegistrationController";
import { toast } from "react-toastify";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";
import { countArray, dateFormat, isLoggedIn } from "../../utils";
import { getRegistrationDashboards } from "../../controller/DashboradController";
import { stateData } from "../../controller/ComplianceController";
import { BsDownload } from "react-icons/bs";
import { Url } from "../../config/config";
import Pagination from "react-js-pagination";
import CardSkeleton from "../../components/skeleton/CardSkeleton";
import Tooltip from "../../components/Tooltip/Tooltip";
// import Calendar from "../../../assets/image/calendar.png";

function Registration() {
  // full page loader+++++
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [remarkShow, setRemarkShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [toggleShow, setToggleShow] = useState(false);
  const [statusToggle, setStatusToggle] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const { type } = useParams();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dashboardLoading, setDashboardLoading] = useState(false);

  // remark handle
  const [remarkData, setRemarkData] = useState([]);
  const [totalRemarks, setTotalRemarks] = useState("");

  const [getSlectedCompany, setSlectedCompany] = useState("");
  const handelCompanySelect = (e) => {
    setSlectedCompany(e.target.value);
  };

  const [getSlectedState, setSlectedState] = useState("");
  const handelStateSelect = (e) => {
    setSlectedState(e.target.value);
  };

  const [getSlectedLocation, setSlectedLocation] = useState("");
  const handelLocationSelect = (e) => {
    setSlectedLocation(e.target.value);
  };

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedBy, setSelectedBy] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const handleSelectRemarkFromManager = async (e) => {
    try {
      var res = await remarkFromConsultant();
      if (res.status === 200) {
        setRemarkData(res.data.remark);
        setTotalRemarks(res.data.total);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const [allRegistrationList, setAllRegistrationList] = useState([]);
  const [registrationData, setRegistrationData] = useState({});

  // getting all registration lists
  const getRegistrationList = async (
    company = getSlectedCompany,
    state = getSlectedState,
    location = getSlectedLocation,
    registeredBy = selectedBy,
    registrationType = selectedType,
    status = selectedStatus,
    page = activePage
  ) => {
    showLoader();

    const statusSchema = {
      active: "active",
      hold: "hold",
      acknowledge: "acknowledge",
      expired: "expired",
      "new-request": "newRequest",
      "re-submitted": "reSubmitted",
      aboutToExpire: "aboutToExpire",
      inactive: "inactive",
    };
    const data = {
      company,
      state,
      location,
      registeredBy,
      registrationType,
      page,
      status: status === "" ? statusSchema[type] : status,
    };
    if (status === "reSubmitted") {
      Object.assign(data, {
        status: "reSubmitted",
        resubmitted: "reSubmitted",
        expiry: "",
      });
    }
    if (status === "expired") {
      Object.assign(data, {
        expiry: moment().format("YYYY-MM-DD"),
        status: "",
        resubmitted: "",
      });
    }
    try {
      const res = await registrationList(data);
      if (res.status === 200) {
        hideLoader();
        setAllRegistrationList(res.data.listing);
        setTotal(res.data.total);
      } else {
        hideLoader();
      }
    } catch (err) {
      hideLoader();
      console.log(err);
    }
  };

  const [getState, setState] = useState([]);
  const stateList = async (id) => {
    var res = await stateData(id);
    if (res.status === 200) {
      setState(res.data);
    } else {
      toast.error(res.messa);
    }
  };

  const [getCompany, setCompany] = useState([]);
  const companyList = async () => {
    var res = await companyData();
    if (res.status === 200) {
      setCompany(res.data);
    } else {
      toast.error(res.messa);
    }
  };

  const [getLocationsList, setLocationsList] = useState([]);
  const locationList = async (
    companyId = getSlectedCompany,
    stateId = getSlectedState
  ) => {
    var data = {
      companyId: companyId,
      stateId: stateId,
    };
    if (companyId !== "" && stateId !== "") {
      var res = await locationData(data);
      if (res.status === 200) {
        setLocationsList(res.data);
      } else {
        toast.error(res.message);
      }
    } else {
      setLocationsList([]);
    }
  };

  const [getRegistrationType, setRegistrationType] = useState([]);
  const registrationTypeList = async () => {
    var res = await registrationType();
    if (res.status === 200) {
      setRegistrationType(res.data);
    } else {
      toast.error(res.message);
    }
  };

  // getting dashboard count
  // *********get dashboard details**********
  useEffect(() => {
    const getRegistrationDashboard = async () => {
      try {
        setDashboardLoading(true);
        var res = await getRegistrationDashboards();
        if (res.status === 200) {
          setRegistrationData(res.data);
          setDashboardLoading(false);
        } else {
          toast.error(res.message);
          setDashboardLoading(false);
        }
      } catch (err) {
        toast.error(err);
        setDashboardLoading(false);
      }
    };
    getRegistrationDashboard();
  }, []);

  const statusHandler = (e) => {
    setSelectedStatus(e.target.value);
    if (e.target.value === "") {
      navigate("/registration");
      window.location.reload();
    }
  };

  const excelDownloadHandler = () => {
    const statusSchema = {
      active: "active",
      hold: "hold",
      acknowledge: "acknowledge",
      expired: "expired",
      "new-request": "newRequest",
      "re-submitted": "reSubmitted",
      aboutToExpire: "aboutToExpire",
      inactive: "inactive",
    };

    let expiry = "";
    let status = selectedStatus;

    if (status === "") {
      status = statusSchema[type];
    }

    if (status === "reSubmitted") {
      expiry = "";
    }
    if (status === "expired") {
      expiry = moment().format("YYYY-MM-DD");
    }

    window.open(
      `${Url}/register/downloadRegistrationExcel?company=${getSlectedCompany}&state=${getSlectedState}&search=&currentDate=${expiry}&registeredBy=${selectedBy}&registrationType=${selectedType}&consultantStatus=${selectedStatus}&email=${
        localStorage.getItem("userLogin") &&
        JSON.parse(localStorage.getItem("userLogin")).email
      }&role=${
        localStorage.getItem("userLogin") &&
        JSON.parse(localStorage.getItem("userLogin")).role
      }`
    );
  };

  useEffect(() => {
    handleSelectRemarkFromManager();
    companyList();
    registrationTypeList();

    // route filter
    const statusSchema = {
      active: "active",
      hold: "hold",
      acknowledge: "acknowledge",
      expired: "expired",
      "new-request": "newRequest",
      "re-submitted": "reSubmitted",
      aboutToExpire: "aboutToExpire",
      inactive: "inactive",
    };
    if (type) {
      setSelectedStatus(statusSchema[type]);
    }
    getRegistrationList();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    stateList(getSlectedCompany);
  }, [getSlectedCompany]);

  useEffect(() => {
    getRegistrationList();
    // eslint-disable-next-line
  }, [
    getSlectedCompany,
    getSlectedState,
    getSlectedLocation,
    selectedStatus,
    selectedBy,
    selectedType,
    activePage,
  ]);

  // delete handler

  const deleteRegistrationHandler = async () => {
    if (selectedId) {
      try {
        const res = await deleteRegistration(selectedId);
        if (res.status === 200) {
          toast.success(res.message);
          setDeleteShow(false);
          getRegistrationList();
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }
  };

  const statusChangeHandler = async () => {
    console.log("changes", selectedId);
    if (selectedId) {
      try {
        let data = {
          registrationId: selectedId,
          status: statusToggle,
        };
        const res = await statusRegistration(data);
        if (res.status === 200) {
          toast.success(res.message);
          setToggleShow(false);
          getRegistrationList();
          getRegistrationList();
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    locationList(getSlectedCompany, getSlectedState);
  }, [getSlectedState]);

  return (
    <section className="wrapper_praans_consultant_dashboard wrapper_compliance wrapper_Registration">
      <Header />
      <div className="container">
        <div className="wrapper_cards_consultant">
          <div className="cards_consult">
            <div className="cstm_cards_cols">
              {/* loading skeleton */}
              {dashboardLoading && (
                <>
                  {countArray(10).map((data) => {
                    return (
                      <div
                        className="small-box custom_div_styling p-0"
                        key={data}
                      >
                        <CardSkeleton />
                      </div>
                    );
                  })}
                </>
              )}
              {/* main component */}
              {!dashboardLoading && (
                <>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.totalRegistration || 0}</h3>
                      <p>Total registration</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/active");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.activeRegistration || 0}</h3>
                      <p>Active registration</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/expired");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.expiredRegistration || 0}</h3>
                      <p>Expired registration</p>
                    </div>
                  </a>
                  {/* <div className="small-box  custom_div_styling">
                <div className="inner">
                  <h3>{registrationData.selfApplied || 0}</h3>
                  <p>Self Applied</p>
                </div>
              </div> */}
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/acknowledge");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.acknowledgement || 0}</h3>
                      <p>Acknowledged</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/hold");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.hold || 0}</h3>
                      <p>Hold</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/new-request");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.newRequest || 0}</h3>
                      <p>New Request</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/re-submitted");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.resubmitted || 0}</h3>
                      <p>Resubmitted</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/aboutToExpire");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.aboutToExpireRegistration || 0}</h3>
                      <p>About To Expire</p>
                    </div>
                  </a>
                  <a
                    role="button"
                    onClick={() => {
                      navigate("/registration/inactive");
                      window.location.reload();
                    }}
                    className="small-box text-dark custom_div_styling"
                  >
                    <div className="inner">
                      <h3>{registrationData.inActiveRegistration || 0}</h3>
                      <p>Inactive</p>
                    </div>
                  </a>
                </>
              )}
            </div>
          </div>
          <div className="wrapper_custom_search">
            <div className="custom_search">
              <div className="form_field_wrap">
                <div className="form_field">
                  <select
                    className="input"
                    onChange={(e) => handelCompanySelect(e)}
                    value={getSlectedCompany}
                  >
                    <option value="">All Companies</option>
                    {getCompany && getCompany.length > 0
                      ? getCompany.map((value, i) => (
                          <option value={value._id} key={i}>
                            {value.companyName}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select
                    className="input"
                    onChange={(e) => handelStateSelect(e)}
                  >
                    <option value="">All States</option>
                    {getState && getState.length > 0
                      ? getState.map((value, i) => (
                          <option value={value} key={i}>
                            {value}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  {/* <select
                    className="input"
                    onChange={(e) => handelLocationSelect(e)}
                  >
                    <option value="">All Location</option>
                    {getLocationsList && getLocationsList.length > 0
                      ? getLocationsList.map((value, i) => (
                          <option value={value} key={i}>
                            {value}
                          </option>
                        ))
                      : ""}
                  </select> */}
                  <input
                    className="input"
                    placeholder="search location"
                    value={getSlectedLocation}
                    onChange={(e) => setSlectedLocation(e.target.value)}
                  />
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select
                    className="input"
                    onChange={statusHandler}
                    value={selectedStatus}
                  >
                    <option value="">All Status</option>
                    {[
                      { label: "Active", value: "active" },
                      { label: "Hold", value: "hold" },
                      { label: "Acknowledged", value: "acknowledge" },
                      { label: "Expired", value: "expired" },
                      { label: "New Request", value: "newRequest" },
                      { label: "Resubmitted", value: "reSubmitted" },
                      { label: "About To Expire", value: "aboutToExpire" },
                      { label: "Inactive", value: "inactive" },
                    ].map(({ label, value }) => {
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select
                    className="input"
                    onChange={(e) => setSelectedType(e.target.value)}
                    value={selectedType}
                  >
                    <option value="">All Registration type</option>
                    {getRegistrationType && getRegistrationType.length > 0
                      ? getRegistrationType.map((value, i) => (
                          <option value={value._id} key={1}>
                            {value.registrationType}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <select
                    className="input"
                    onChange={(e) => setSelectedBy(e.target.value)}
                    value={selectedBy}
                  >
                    <option value="">All Registration By</option>
                    {isLoggedIn().role !== "Consultant" && (
                      <option value="self">self</option>
                    )}
                    <option value="consultant">consultant</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="custom_search unwrap_custom_search">
              {isLoggedIn().role !== "Consultant" && (
                <div className="padding-right remarks">
                  <Button variant="primary" onClick={() => setRemarkShow(true)}>
                    Consultant Remark
                    {totalRemarks && (
                      <Badge bg="secondary"> {totalRemarks}</Badge>
                    )}
                    <span className="visually-hidden">unread messages</span>
                  </Button>
                </div>
              )}
              <div className="padding-left-right">
                {isLoggedIn().role === "Executor" && (
                  <Link
                    to={`/registration/notice_detail`}
                    className="btn btn-primary float-end"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    + Add
                  </Link>
                )}
              </div>
              <div className="">
                <button
                  className="download-excel"
                  onClick={() => excelDownloadHandler()}
                >
                  <span>
                    <BsDownload />
                  </span>
                  Download
                </button>
              </div>
            </div>
          </div>
          <div className="data_table">
            <Table striped bordered hover>
              <thead>
                <tr className="custom_tr">
                  <th>S.NO.</th>
                  <th>COMPANY</th>
                  <th>LOCATION</th>
                  <th>STATE</th>
                  <th className="text-center">REGISTRATION TYPE</th>
                  <th className="text-center">REGISTRATION NO.</th>
                  <th className="text-center">REGISTRATION BY</th>
                  <th>CONSULTANT EMAIL</th>
                  <th>ISSUE DATE</th>
                  <th className="text-center">EXPIRY DATE</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {allRegistrationList.length > 0 ? (
                  allRegistrationList.map(
                    (
                      {
                        _id,
                        company,
                        consultantStatus,
                        expireDate,
                        filledBy,
                        issueDate,
                        location,
                        registrationNumber,
                        registrationType,
                        state,
                        consultantEmail,
                        masterRegistraionId,
                        status,
                        actionStatus,
                      },
                      i
                    ) => {
                      return (
                        <tr className="cstm_tr" key={i}>
                          <td>{++i + (activePage - 1) * 10}</td>
                          <td>
                            <div>
                              <Tooltip limit={31}>{company}</Tooltip>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Tooltip limit={31}>{location}</Tooltip>
                            </div>
                          </td>
                          <td>
                            <div className="state_name">{state}</div>
                          </td>
                          <td>
                            <div>
                              <Tooltip limit={31}>{registrationType}</Tooltip>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Tooltip limit={22}>
                                {registrationNumber || "-- -- -- -- --"}
                              </Tooltip>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Tooltip limit={22}>{filledBy}</Tooltip>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Tooltip limit={22}>
                                {consultantEmail || "N/A"}
                              </Tooltip>
                            </div>
                          </td>
                          <td className="text-center">
                            {dateFormat(issueDate)}
                          </td>
                          <td className="text-center">
                            {dateFormat(expireDate)}
                          </td>
                          <td>
                            <div>
                              <Tooltip limit={15}>
                                {actionStatus === "inactive"
                                  ? "Inactive"
                                  : consultantStatus === "aboutToExpire"
                                  ? "About To Expire"
                                  : consultantStatus || "New Request"}
                                {/* {consultantStatus || "New Request"} */}
                              </Tooltip>
                            </div>
                          </td>
                          {/* <td>
                            {isLoggedIn().role === "Executor" && (
                              <>
                                {consultantStatus === "hold" && (
                                  <Link to={`/registration/edit/${_id}`}>
                                    <span className="icon">
                                      <FaRegEdit />
                                    </span>
                                  </Link>
                                )}
                                <a
                                  role="button"
                                  href
                                  className="inline-block"
                                  onClick={() => {
                                    setSelectedId(_id);
                                    setDeleteShow(true);
                                  }}
                                >
                                  <span className="icon">
                                    <FaTrash />
                                  </span>
                                </a>
                              </>
                            )}
                            <Link
                              to={`/registration/registration_detail/${_id}`}
                            >
                              <span className="icon">
                                <FaEye />
                              </span>
                            </Link>
                          </td> */}
                          <td>
                            <div className="ection_button">
                              <BsThreeDots />
                              <div className="action_section">
                                {isLoggedIn().role === "Executor" && (
                                  <>
                                    {actionStatus !== "inactive" && // Checking if actionStatus is not "inactive"
                                      consultantStatus === "hold" && ( // If consultantStatus is "hold"
                                        <Link
                                          to={`/registration/edit/${_id}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <FaEdit />
                                        </Link>
                                      )}

                                    {/* Fragment to wrap multiple elements */}
                                    <>
                                      {/* Conditionally rendering delete button based on actionStatus */}
                                      {/* {actionStatus !== "inactive" && (
                                        <a
                                          role="button"
                                          href=""
                                          className="inline-block"
                                          onClick={() => {
                                            setSelectedId(_id);
                                            setDeleteShow(true);
                                          }}
                                        >
                                          <FaTrashAlt />
                                        </a>
                                      )} */}
                                    </>
                                    {/* 
                                    <a
                                      role="button"
                                      href
                                      className="inline-block"
                                      onClick={() => {
                                        setSelectedId(_id);
                                        setStatusToggle("inactive");
                                        setToggleShow(true);
                                      }}
                                    >
                                      <FaToggleOn />
                                    </a> */}
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={actionStatus === "active"}
                                        onClick={() => {
                                          setSelectedId(_id);
                                          if (actionStatus === "inactive") {
                                            setStatusToggle("active");
                                          } else {
                                            setStatusToggle("inactive");
                                          }
                                          setToggleShow(true);
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexSwitchCheckChecked"
                                      ></label>
                                    </div>
                                    {/* 
                                    <div className="form-check form-switch">
                                      <Button
                                        variant="primary"
                                        onClick={() => {
                                          setSelectedId(_id);
                                          if (consultantStatus === "inactive") {
                                            setStatusToggle("active");
                                          } else {
                                            setStatusToggle("inactive");
                                          }
                                          setToggleShow(true);
                                        }}
                                      >
                                        {consultantStatus === "inactive"
                                          ? "active"
                                          : "inactive"}
                                      </Button>
                                    </div> */}
                                  </>
                                )}
                                {/* {actionStatus !== "inactive" && ( */}
                                <Link
                                  to={`/registration/registration_detail/${masterRegistraionId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaEye />
                                </Link>
                                {/* )} */}
                                {actionStatus !== "inactive" && (
                                  <>
                                    {isLoggedIn().role === "Executor" &&
                                      (consultantStatus === "expired" ||
                                        consultantStatus === "aboutToExpire" ||
                                        consultantStatus === "active") && (
                                        <Link
                                          to={`/registration/renew/${_id}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <MdAutorenew />
                                        </Link>
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan="11">
                      <div className="text-center">No data found</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="w-100 d-flex justify-content-center">{loader}</div>
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={(page) => setActivePage(page)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Remark Modal */}
      <Modal
        show={remarkShow}
        onHide={() => setRemarkShow(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design full-width-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title></Modal.Title>
        <Modal.Body>
          <div className="wrapper-modal-div data-table text-center modal-table overflow-auto">
            <Table striped bordered hover>
              <thead>
                <tr className="custom_tr">
                  <th>Remark</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {/* iterate all remarks */}
                {remarkData && remarkData.length > 0 ? (
                  remarkData.map(({ _id, registrationId, remarks }) => {
                    return (
                      <tr key={_id} className="cstm_tr">
                        <td>
                          <p
                            className="text-truncate"
                            style={{ maxWidth: "590px" }}
                            title={remarks[remarks.length - 1]?.remark}
                          >
                            {remarks[remarks.length - 1]?.remark}
                          </p>
                        </td>

                        <td>
                          <Link
                            to={`/registration/registration_detail/${registrationId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="icon">
                              <FaEye />
                            </span>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>No Remarks Available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={deleteShow}
        onHide={() => setDeleteShow(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>
          <h5 className="text-center">Delete Registration</h5>
        </Modal.Title>
        <Modal.Body>
          <div className="wrapper-modal-div text-center">
            <p>Are you sure you want to delete registration ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={() => setDeleteShow(false)} className="custom_btn2">
            No
          </button>
          <button className="custom_btn2" onClick={deleteRegistrationHandler}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal */}
      <Modal
        show={toggleShow}
        onHide={() => setToggleShow(false)}
        // backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>
          <h5 className="text-center">Change Registration Status</h5>
        </Modal.Title>
        <Modal.Body>
          <div className="wrapper-modal-div text-center">
            <p>Are you sure you want to Change registration status?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={() => setToggleShow(false)} className="custom_btn2">
            No
          </button>
          <button className="custom_btn2" onClick={statusChangeHandler}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Registration;
