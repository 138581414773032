import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Compliance.css";
import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Applicaple() {
  const [startDate, setStartDate] = useState();
  const [startDates, setStartDates] = useState();
  const [endDate, setEndDate] = useState();

  const [fileName] = useState();

  const kyMbrImg = (e, i) => {
    // SetFileName(e.target.files[0].name);
    document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_applicable">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/executor/compliance/view-acts">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <select className="input">
                  <option>Select Month</option>
                  <option>Select Industry</option>
                </select>
              </div>
            </div>
            <div className="content_wrapper_acts">
              <ul>
                <li>
                  <span>Act name</span>
                  <h4>Act Name 1</h4>
                </li>
                <li>
                  <span>Rule name</span>
                  <h4>Labour Charge</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit
                  </p>
                </li>
                <li>
                  <span>Section/Rule</span>
                  <h4>Section 11B</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit
                  </p>
                </li>
                <li>
                  <span>Risk analysis</span>
                  <h4>Low</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit
                  </p>
                </li>
              </ul>
            </div>
            <form>
              <div className="content_wrapper_acts">
                <div className="compliance_name">
                  <ul>
                    <li>
                      <span>Compliance name</span>
                      <h4>Provident Fund</h4>
                    </li>
                    <li>
                      <span>Compliance period</span>
                      <div className="flex_direction_cstm">
                        <DatePicker
                          selected={startDate}
                          className="input input1"
                          onChange={(date) => setStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          selectsStart
                          startDate={startDate}
                          placeholderText="from"
                          endDate={endDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                        <DatePicker
                          className="input input1"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          startDate={startDate}
                          placeholderText="To"
                          endDate={endDate}
                          minDate={startDate}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </div>
                    </li>
                    <li>
                      <span>Compliance Date</span>
                      <div className="flex_direction_cstm">
                        <DatePicker
                          selected={startDates}
                          className="input input1"
                          onChange={(date) => setStartDates(date)}
                          dateFormat="dd/MM/yyyy"
                          selectsStart
                          startDate={startDates}
                          placeholderText="Enter Date"
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </div>
                    </li>

                    <li>
                      <span>Compliance Upload Date</span>
                      <h6>22/02/2022</h6>
                    </li>

                    <li>
                      <span>Penality Interest</span>
                      <input
                        type="number"
                        className="input input_2"
                        placeholder="Enter amount"
                        required
                      ></input>
                    </li>
                    <li>
                      <span>Select Complied type</span>
                      <div className="wrapper_radio">
                        <div className="radio_wrap_">
                          <label htmlFor="red">
                            <input
                              type="radio"
                              name="colors"
                              id="red"
                              required
                            />
                            <span>Self</span>
                          </label>
                        </div>
                        <div className="radio_wrap_">
                          <label htmlFor="blue">
                            <input
                              type="radio"
                              name="colors"
                              id="blue"
                              required
                            />
                            <span>Consultant</span>
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="upload_document">
                  <div className="heads_text">
                    <h4>Upload Documents</h4>
                  </div>
                  <div className="input_upload_file">
                    <label>
                      <input
                        className="input"
                        type="file"
                        onChange={kyMbrImg}
                      />
                      <div className="wrapper_upload_div">
                        <span className="upload_div1" id="image_">
                          file name {fileName}
                        </span>
                        <span className="Upload-file">+ Upload</span>
                      </div>
                    </label>
                    <span>+ Add more</span>
                  </div>
                  <div className="form_control_message">
                    <span>Remarks</span>
                    <span>(Optional)</span>
                    <textarea
                      className="form-control"
                      id="form6Example7"
                      rows="4"
                      placeholder="Write something here…"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="submit_form_btn">
                <button className="custom_button">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Applicaple;
