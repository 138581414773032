import React from "react";
import { FaLock } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link } from "react-router-dom";
import "./Header.css";
import Logo from "../../../assets/image/logo.png";
import { FaSignOutAlt } from "react-icons/fa";

function Header() {
  return (
    <section className="header_consultant">
      <header className="header">
        <div className="header_bar">
          <div className="logo">
            <Link to="">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <DropdownButton id="dropdown-item-button" title="Hello Sandeep">
            <Dropdown.Item as="button">
              <span className="custom_lock">
                <FaLock />
              </span>
              Change Password
            </Dropdown.Item>
            <Dropdown.Item as="button">
              <span className="custom_lock">
                <FaSignOutAlt />
              </span>
              Logout
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </header>
    </section>
  );
}

export default Header;
