import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Url } from "../config/config";

export const api = axios.create({
  baseURL: Url,
});

export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const resInterceptor = (response) => {
      console.log(response,"axiosInterceptorResponse");
      
      
      if (response.data.status === 401) {
        localStorage.removeItem("userLogin");
        navigate("/login");
      }
      return response;
    };

    const errInterceptor = (error) => {
      if(error.response.status===401){
        navigate("/login");
        localStorage.removeItem("userLogin");
      
      }

      return error;
    };

    const interceptor = api.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    setIsSet(true);

    return () => api.interceptors.response.eject(interceptor);
  }, [navigate]);

  return isSet && children;
};

export async function AuthToken() {
  const items = JSON.parse(localStorage.getItem("userLogin"));

  return items.token;
}

export async function changePassword(data) {
  var token = await AuthToken();

  var res = await api.post(`/changePassword`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
}
