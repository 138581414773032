import React from "react";
import { Link } from "react-router-dom";
import "./Registration.css";
import Header from "../header/Header";
import { IoIosArrowBack } from "react-icons/io";

function RegistrationDetail() {
  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_registration_detail ">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Registration details</h5>
              </div>
            </div>
            <div className="content_wrapper_acts">
              <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Company name</span>
                  <b>Gourav & sons</b>
                </div>
                <div className="detail_section">
                  <span>Location</span>
                  <b>Noida</b>
                </div>
                <div className="detail_section">
                  <span>State</span>
                  <b>U.P.</b>
                </div>
                <div className="detail_section">
                  <span>Owner Name</span>
                  <b>Sourabh</b>
                </div>
                <div className="detail_section">
                  <span>Manager Name</span>
                  <b>Rahul singh</b>
                </div>
              </div>
              <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Act/rule</span>
                  <b>Section 11B</b>
                </div>
                <div className="detail_section">
                  <span>Registration type</span>
                  <b>Online</b>
                </div>
                <div className="detail_section">
                  <span>Registration no.</span>
                  <b>3472974972</b>
                </div>
                <div className="detail_section">
                  <span>Issue Date</span>
                  <b>22/02/2022</b>
                </div>
                <div className="detail_section">
                  <span>Expiry Date</span>
                  <b>22/03/2023</b>
                </div>
              </div>
              {/* <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Uploaded</span>
                  <b>Self</b>
                </div>
                <div className="detail_section">
                  <span>Consultant Mail</span>
                  <b>ashish@gmail.com</b>
                </div>
              </div> */}
            </div>
            <div className="applicable_not">
              <Link to="/consultant/registration/registration_detail/upload-status">
                Proceed
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegistrationDetail;
