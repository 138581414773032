import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./Login.css";
import { Url } from "../../config/config";
import Right from "../../assets/image/right.png";

function Succesful() {
  const history = useNavigate();
  const handelResendMail = async (e) => {
    e.preventDefault();
    try {
      let email = localStorage.getItem("emailId");
      const res = await axios({
        url: `${Url}/sendLink`,
        method: "POST",
        data: {
          email,
        },
      });
      if (res.data.status === 200) {
        toast.success(
          "Please check your email inbox. Password reset instructions sent to the associated email address."
        );
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error("Something wents wrong , please try again.");
    }
  };

  const backToLogin = () => {
    history("/login");
  };

  return (
    <section className="wrapper_log_in_page">
      <title>Praans | Forgot Password</title>
      <div className="log_in_page">
        <div className="text_governance">
          <div className="text_governance_flow">
            <div className="button_wrap">
              <span>Governance</span>
              <span>Risk</span>
            </div>
            <div className="button_wrap">
              <span>Compliance</span>
            </div>
          </div>
          <div className="company_bottom_text">
            <p>A company without any rule is no company at all</p>
          </div>
        </div>
        <div className="log_in_form">
          <div className="praans_logo sucessful_tick">
            <figure className="image_set image_right">
              <img src={Right} alt="Praans" />
            </figure>
            <div className="form_wrapper form_sucessful">
              <form>
                <h3>Success</h3>
                <span>
                  A link has been sent on your registered email. Click on the
                  link and reset the password.
                </span>
                <div className="button_wrap">
                  <button
                    className="custom_button styling_btn"
                    onClick={(e) => {
                      backToLogin(e);
                    }}
                  >
                    Back to login
                  </button>
                </div>
                <div
                  className="Resend_txt"
                  onClick={(e) => {
                    handelResendMail(e);
                  }}
                >
                  <span>Resend</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Succesful;
