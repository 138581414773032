import { api, AuthToken } from "./AuthController";

export async function companyData() {
  var token = await AuthToken();

  var res = await api.get(`compliance/companyFilter`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function locationData(id) {
  var token = await AuthToken();

  var res = await api.get(`/manage/locationListing?companyId=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function taskData(data) {
  var token = await AuthToken();

  var res = await api.get(
    `manage/managesListing?company=&page=${data.page}&state=&currentDate=&search=${data.search}&from_date=${data.from}&to_date=${data.to}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function deleteTaskData(id) {
  var token = await AuthToken();

  var res = await api.get(`manage/deleteManage?manageId=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function singleTaskData(manageId) {
  var token = await AuthToken();

  var res = await api.get(`/manage/manageDataById?manageId=${manageId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function managerByCompanyId(companyId) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/managerAccordingToCompany?companyId=${companyId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function headByCompanyId(companyId) {
  var token = await AuthToken();

  var res = await api.get(
    `letigation/hodAccordingToCompany?companyId=${companyId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function executerByCompanyId(companyId) {
  var token = await AuthToken();

  var res = await api.get(`manage/executerListing?companyId=${companyId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
}

export async function addTask(data) {
  var token = await AuthToken();

  var res = await api.post(`/manage/addManages`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}

export async function updateTask(data) {
  var token = await AuthToken();

  var res = await api.post(`/manage/updateManage`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
}
