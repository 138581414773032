import React, { useEffect, useState } from "react";
import "./Notification.css";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Header from "../../Layout/Header";
import { toast } from "react-toastify";
import {
  notificationList,
  setReadNotification,
} from "../../../controller/NotificationService";
import { dateFormat } from "../../../utils";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function Notification() {
  const [getSeletctedTab, setSeletctedTab] = useState("unread");
  const [allNotifications, setAllNotifications] = useState([]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [readModal, setReadModal] = useState(false);
  const [readData, setReadData] = useState({});

  const getLibraryClick = async (key) => {
    setSeletctedTab(key);
  };

  const getAllNotification = async () => {
    try {
      showLoader();
      const response = await notificationList(
        getSeletctedTab === "unread" ? 0 : 1
      );

      if (response.status === 200) {
        setAllNotifications(response.data.notificationListing || []);
        hideLoader();
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err?.response?.err?.message);
      hideLoader();
    }
  };

  useEffect(() => {
    getAllNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllNotifications([]);
    getAllNotification();
    // eslint-disable-next-line
  }, [getSeletctedTab]);

  const readNotification = async (id) => {
    try {
      const response = await setReadNotification(id);

      if (response.status !== 200) {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err?.response?.err?.message);
    }
  };

  const readNotificationHandler = (data) => {
    setReadModal(true);
    readNotification(data._id);
    setReadData({ ...data });
  };

  const closeModal = () => {
    setReadModal(false);
    getAllNotification();
  };

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_library notification">
        <div className="container">
          <div className="wrap_viewacts">
            <Tabs
              onSelect={getLibraryClick}
              defaultActiveKey={"unread"}
              id="uncontrolled-tab-example"
              //   className="mb-3"
            >
              <Tab eventKey="unread" title="Unread">
                <div className="custom_search remarks_tempo">
                  <div className="form_field_wrap">
                    <div className="form_field"></div>
                  </div>
                </div>
                <div className="data_table">
                  {allNotifications.length > 0 ? (
                    allNotifications.map(
                      ({ _id, title, remarks, postedOn }) => {
                        return (
                          <div
                            className="content_wrapper_acts"
                            key={_id}
                            role="button"
                            onClick={() => {
                              readNotificationHandler({
                                _id,
                                title,
                                remarks,
                                postedOn,
                              });
                            }}
                          >
                            <div className="dynamic_txt w-100">
                              <div className="d-flex justify-content-between">
                                <h5>{title}</h5>
                                <span class="badge bg-danger badge-dot h-25 p-1 d-block"></span>
                              </div>
                              <p style={{ wordBreak: "break-word" }}>
                                {remarks}
                              </p>
                              <span>{dateFormat(postedOn)}</span>
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="content_wrapper_acts">
                      <div className="dynamic_txt w-100 text-center">
                        <h5>No Notification Available</h5>
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="read" title="Read">
                <div className="custom_search remarks_tempo">
                  <div className="form_tempo"></div>
                  <div className="form_field_wrap">
                    <div className="form_field"></div>
                  </div>
                </div>
                <div className="data_table">
                  {allNotifications.length > 0 ? (
                    allNotifications.map(
                      ({ _id, title, remarks, postedOn }) => {
                        return (
                          <div className="content_wrapper_acts my-4" key={_id}>
                            <div className="dynamic_txt">
                              <h5>{title}</h5>
                              <p style={{ wordBreak: "break-word" }}>
                                {remarks}
                              </p>
                              <span>{dateFormat(postedOn)}</span>
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="content_wrapper_acts">
                      <div className="dynamic_txt w-100 text-center">
                        <h5>No Notification Available</h5>
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
            </Tabs>
            <div className="w-100 d-flex justify-content-center">{loader}</div>
          </div>
        </div>
      </section>
      <Modal
        show={readModal}
        onHide={closeModal}
        // backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design modal_cstm_design2 modal_cstm_design_6"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal_after_before">
            <div className="row">
              <div className="col-lg-12">
                <div className="custom_col_modl">
                  {/* <h5>Title</h5> */}
                  <div className="row">
                    <div className="col-9">
                      <h5 className="mx-1">{readData.title}</h5>
                    </div>
                    <div className="col-3">
                      <h6>{dateFormat(readData.postedOn)}</h6>
                    </div>
                  </div>
                  <div className="content_before_txt">
                    {/* <h5>Remarks</h5> */}
                    <p>{readData.remarks}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="custom_btn2" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Notification;
