import moment from "moment";

// import moment from "moment";
export function isLoggedIn() {
  let session = getObject("userLogin") || {};
  session = Object.keys(session).length && JSON.parse(session);
  let accessToken = session;
  return accessToken;
}

export function getObject(key) {
  if (window && window.localStorage) {
    return window.localStorage.getItem(key);
  }
}

export function multiPartData(data) {
  let multiPart = new FormData();
  for (let key in data) {
    multiPart.append(key, data[key]);
  }

  return multiPart;
}

export function checkPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export function capitalizeFirstLetter(str) {
  //return ;
  //return string;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function dateFormat(date) {
  return date ? moment(date).format("DD/MM/YYYY") : "-- -- ----";
}

// export function dateTimeFormat(date) {
//   return moment(date).format("DD/MM/YYYY H:mm:ss");
// }

export function ShortString(string) {
  return string.substr(-100);
}

export const stringTrimmer = (str) => {
  return str.substr(57);
};

// this function is used to get arrays of counts
export function countArray(limit = 0) {
  let arr = [];
  for (let i = 1; i <= +limit; i++) {
    arr.push(i);
  }
  return arr;
}
