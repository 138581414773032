import React from "react";
import "./Notification.css";
import Header from "../header/Header";
import { FaTrash } from "react-icons/fa";
import { useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Notification() {
  const ref = useRef();

  return (
    <>
      <Header />
      <section className="wrapper_notification">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <h6>Notification</h6>
              </div>
            </div>
            <div className="content_wrapper_acts">
              <ul>
                <li>
                  <div className="column_cstm">
                    <Row>
                      <Col lg={9} md={9} sm={7} xs={12}>
                        <div className="dynamic_txt">
                          <h5>Lorem Ipsume</h5>
                          <p>
                            lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                            lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                            lorem ipsum
                          </p>
                          <span>22 Jul 2022</span>
                        </div>
                      </Col>
                      <Col lg={3} md={3} sm={5} xs={12} className="text-right">
                        <div className="trash_middle">
                          <button>
                            <span>
                              <FaTrash />
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </li>
                <li>
                  <div className="column_cstm">
                    <Row>
                      <Col lg={9} md={9} sm={7} xs={12}>
                        <div className="dynamic_txt">
                          <h5>Lorem Ipsume</h5>
                          <p>
                            lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                            lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                            lorem ipsum
                          </p>
                          <span>22 Jul 2022</span>
                        </div>
                      </Col>
                      <Col lg={3} md={3} sm={5} xs={12}>
                        <div className="trash_middle">
                          <button>
                            <span>
                              <FaTrash />
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Notification;
