import React, { useState } from "react";
import "./Registration.css";
import Header from "../header/Header";
import Select from "react-select";
import { Link } from "react-router-dom";
// import { FaDownload, FaUpload } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";
import { FiUpload } from "react-icons/fi";
import Table from "react-bootstrap/Table";
import { useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { IoIosArrowBack } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function UploadStatus() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const ref = useRef();

  const [fileName, SetFileName] = useState();

  const kyMbrImg = (e) => {
    SetFileName(e.target.files[0].name);
    // document.getElementById("image_").innerHTML = e.target.files[0].name;
  };

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_registration_detail wrapper_library wrapper_upload1">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/consultant/registration/registration_detail">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Registration details</h5>
              </div>
            </div>
            <div className="custom_search remarks_tempo">
              <div className="form_tempo">
                <Select
                  options={optionList}
                  placeholder="Search by document name"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  // isMulti
                />
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <Button
                    className="custom_btn download-excel"
                    variant="primary"
                    onClick={handleShow}
                  >
                    Update Status
                  </Button>
                </div>
                <div className="form_field">
                  <button className="download-excel">
                    <span>
                      <BsDownload />
                    </span>
                    Download excel
                  </button>
                </div>
              </div>
            </div>
            <div className="data_table">
              <Table striped bordered hover>
                <thead>
                  <tr className="custom_tr">
                    <th>S.N0.</th>
                    <th>DOCUMENT</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cstm_tr">
                    <td>1</td>
                    <td>Document 1</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>2</td>
                    <td>Document 2</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>3</td>
                    <td>Document 3</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>4</td>
                    <td>Document 4</td>
                    <td>Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>5</td>
                    <td>Document 5</td>
                    <td>Not Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>6</td>
                    <td>Document 6</td>
                    <td>Not Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr className="cstm_tr">
                    <td>7</td>
                    <td>Document 7</td>
                    <td>Not Mandatory</td>
                    <td>
                      <button>
                        <span>
                          <BsDownload />
                        </span>
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal_cstm_design5"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Choose Status</h4>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col lg={12}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <label for="consume">
                          <span className="radio_button"></span>
                          <span>Active</span>
                        </label>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <label for="cons">
                          <span className="radio_button"></span>
                          <span>Hold</span>
                        </label>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <label for="consum">
                          <span className="radio_button"></span>
                          <span>Acknowledgement</span>
                        </label>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="regis_tration">
                        <input
                          className="input"
                          type="text"
                          placeholder="Registration Type"
                        />
                        <div className="da_te">
                          <div className="flex_direction_cstm">
                            <DatePicker
                              selected={startDate}
                              className="input input1"
                              onChange={(date) => setStartDate(date)}
                              dateFormat="dd/MM/yyyy"
                              selectsStart
                              startDate={startDate}
                              placeholderText="from"
                              endDate={endDate}
                              showYearDropdown
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              showMonthDropdown
                              useShortMonthInDropdown
                            />
                            <DatePicker
                              className="input input1"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              dateFormat="dd/MM/yyyy"
                              selectsEnd
                              startDate={startDate}
                              placeholderText="To"
                              endDate={endDate}
                              minDate={startDate}
                              showYearDropdown
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              showMonthDropdown
                              useShortMonthInDropdown
                            />
                          </div>
                          {/* from
                          <input className="input input5" type="date" />
                          to
                          <input className="input input5" type="date" /> */}
                        </div>
                        <div className="da_te da_te2">
                          <label className="upload_pan" for="upload1">
                            <input
                              type="file"
                              className="upload_pan1"
                              id="upload1"
                            />
                            <span className="icon_doc">
                              <FiUpload />
                            </span>
                            <p className="file_pan">UPLOAD GST</p>
                          </label>
                          <label className="upload_pan" for="upload1">
                            <input
                              type="file"
                              className="upload_pan1"
                              id="upload1"
                            />
                            <span className="icon_doc">
                              <FiUpload />
                            </span>
                            <p className="file_pan">UPLOAD GST</p>
                          </label>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <textarea
                        id="w3review"
                        name="w3review"
                        rows="4"
                        cols="50"
                        placeholder="Remarks"
                      ></textarea>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="form_field">
                        <div className="input_upload_file">
                          <label>
                            <input
                              className="input"
                              type="file"
                              onChange={kyMbrImg}
                            />
                            <div className="wrapper_upload_div">
                              <span className="upload_div1" id="image_">
                                file name {fileName}
                              </span>
                              <span className="Upload-file">
                                <GrAttachment />
                                Add Attchement
                              </span>
                            </div>
                          </label>
                          <span className="add_more_append">
                            + Add more Attchement
                          </span>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            <Link
              to="/consultant/registration/registration-detail-final"
              className="custom_btn"
              variant="secondary"
              onClick={handleClose}
            >
              Submit
            </Link>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
}

export default UploadStatus;
