import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./Manage.css";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import moment from "moment";
import Header from "../../Layout/Header";
import { dateFormat, isLoggedIn, stringTrimmer } from "../../../utils";
import { singleTaskData } from "../../../controller/ManageController";

const ViewTask = () => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);

  const [taskDetail, setTaskDetail] = useState({});

  const getTaskDetails = async (id) => {
    try {
      var res = await singleTaskData(id);
      if (res.status === 200) {
        setTaskDetail(res.data[0] || {});
        setFiles(res.data[0].document || []);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    getTaskDetails(id);
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <Header />
      <section className="wrapper_executor-viewacts wrapper_registration_detail">
        <div className="container">
          <div className="wrap_viewacts">
            <div className="wrapper_back_date">
              <div className="backward_styling">
                <Link to="/manage">
                  <span className="icon">
                    <IoIosArrowBack />
                  </span>
                </Link>
              </div>
              <div className="form_field">
                <h5>Manage Details</h5>
              </div>
            </div>
            <div className="content_wrapper_acts">
              <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Task Name</span>
                  <b>
                    <p className="tooltips-effect">
                      {taskDetail.taskName?.length > 150
                        ? taskDetail.taskName?.slice(0, 150) + "..."
                        : taskDetail.taskName}
                      <span className="tooltiptext text-black">
                        {taskDetail.taskName}
                      </span>
                    </p>
                  </b>
                </div>
                <div className="detail_section">
                  <span>Company Name</span>
                  <b>{taskDetail.company}</b>
                </div>

                <div className="detail_section ">
                  <span>Manager Email</span>
                  <b>
                    {taskDetail.managerEmail?.map((data) => `${data.email}, `)}
                  </b>
                </div>
                <div className="detail_section ">
                  <span>Executor Email</span>
                  <b>
                    {taskDetail.executerEmail?.map((data) => `${data.email}, `)}
                  </b>
                </div>
              </div>
            </div>

            <div className="content_wrapper_acts">
              <div className="wrapper_details_section">
                <div className="detail_section">
                  <span>Periodicity</span>
                  <b>{taskDetail.periodicity}</b>
                </div>
                <div className="detail_section">
                  <span>Start Date</span>
                  <b>
                    {taskDetail.startDate
                      ? dateFormat(taskDetail.startDate)
                      : "-- -- ----"}
                  </b>
                </div>
                <div className="detail_section">
                  <span>Expiry Date</span>
                  <b>
                    {taskDetail.dueDate
                      ? dateFormat(taskDetail.dueDate)
                      : "-- -- ----"}
                  </b>
                </div>
                <div className="detail_section">
                  {/* <span>Status</span>
                  <b>{taskDetail.status}</b> */}
                </div>
              </div>
            </div>

            {/* executer uploaded documents */}
            <div className="content_wrapper_acts">
              <div className="wrapper_compliance py-4">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr className="custom_tr">
                      <th>Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.length > 0 ? (
                      files.map((file) => {
                        return (
                          <tr className="cstm_tr" key={file}>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span>{stringTrimmer(file)}</span>
                                <button
                                  className="custom_btn btn btn-primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`${file}`);
                                  }}
                                >
                                  Download
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No Documents Attached</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewTask;
