import { api,AuthToken } from "./AuthController";

export async function librayListing(date) {
  var token = await AuthToken();

  var res = await api.get(
    `libraryAct/libraryActListing?type=${date.type}&search=${date.search}&state=${date.state}&page=${date.page}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function notificationListing(date) {
  var token = await AuthToken();

  var res = await api.get(
    `libraryAct/notificationActListing?notificationDate=${date.notificationDate}&search=${date.search}&state=${date.state}&effectiveDate=${date.effectiveDate}&page=${date.page}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}

export async function stateListing() {
  var token = await AuthToken();

  var res = await api.get(
    `libraryAct/allStateListing`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
}
