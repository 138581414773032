import React, { useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { FiUpload } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import { updateRegistrationStatus } from "../../controller/RegistrationController";
import { useNavigate } from "react-router-dom";

const UpdateRegistrationStatus = ({
  proceedModal,
  onClose,
  registrationId,
  getRegistrationDetails,
  registrationActionId,
  registrationDetails,
}) => {
  const navigate = useNavigate();

  console.log(registrationDetails);
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Submit",
    "Please wait..."
  );

  //   Active Status
  const [statusData, setStatusData] = useState({
    registrationId: registrationId,
    RegistrationType: "",
    issueDate: "",
    expireDate: "",
    consultantStatus: "active",
    file: "",
    remark: "",
    registrationNumber: "",
    license: "",
    feeAttachment: "",
  });

  //   Acknowledge Status
  const [attachments, setAttachments] = useState([""]);
  const kyMbrImg = (e, ind) => {
    const file = [...attachments];
    file[ind] = e.target.files[0];
    setAttachments(file);
  };

  //   update status handler
  const updateStatusHandler = async (e) => {
    e.preventDefault();
    if (statusData.consultantStatus === "active") {
      if (!statusData.registrationNumber) {
        toast.error("Please Enter Registration Number");
      } else if (!statusData.issueDate) {
        toast.error("Please Select Issue Date");
      } else if (!statusData.license) {
        toast.error("Please Select License File");
      } else {
        try {
          setSubmitLoading(true);
          let formData = new FormData();
          formData.append("registrationId", registrationDetails._id);
          if (registrationActionId) {
            formData.append("registrationActionId", registrationActionId);
          }
          formData.append("consultantStatus", statusData.consultantStatus);
          formData.append("issueDate", statusData.issueDate);
          formData.append("expireDate", statusData.expireDate);
          formData.append("registrationNumber", statusData.registrationNumber);
          formData.append("file", statusData.license);
          formData.append("file", statusData.feeAttachment);

          let res = await updateRegistrationStatus(formData);
          if (res.status === 200) {
            toast.success(res.message);
            onClose();
            setSubmitLoading(false);
            getRegistrationDetails();
            navigate("/registration");
          } else {
            setSubmitLoading(false);
            onClose();
            toast.error(res.message);
          }
        } catch (err) {
          setSubmitLoading(false);
          toast.error(err);
        }
      }
    }
    if (statusData.consultantStatus === "hold") {
      if (!statusData.remark) {
        toast.error("Please Enter Remark !");
      } else {
        try {
          setSubmitLoading(true);
          let formData = new FormData();
          formData.append("registrationId", registrationDetails._id);
          if (registrationActionId) {
            formData.append("registrationActionId", registrationActionId);
          }
          formData.append("consultantStatus", statusData.consultantStatus);
          formData.append("remarks", statusData.remark);
          let res = await updateRegistrationStatus(formData);

          if (res.status === 200) {
            toast.success(res.message);

            onClose();
            setSubmitLoading(false);
            getRegistrationDetails();
            navigate("/registration");
          } else {
            setSubmitLoading(false);
            onClose();
            toast.error(res.message);
          }
        } catch (err) {
          setSubmitLoading(false);
          toast.error(err);
        }
      }
    }
    if (statusData.consultantStatus === "acknowledge") {
      if (attachments.length <= 0) {
        toast.error("Please Select Attachment !");
      } else {
        try {
          setSubmitLoading(true);
          let formData = new FormData();
          formData.append("registrationId", registrationDetails._id);
          if (registrationActionId) {
            formData.append("registrationActionId", registrationActionId);
          }
          formData.append("consultantStatus", statusData.consultantStatus);
          if (attachments.length > 0) {
            for (let i = 0; i < attachments.length; i++) {
              formData.append("file", attachments[i]);
            }
          }
          let res = await updateRegistrationStatus(formData);
          if (res.status === 200) {
            toast.success(res.message);
            onClose();
            setSubmitLoading(false);
            getRegistrationDetails();
            navigate("/registration");
          } else {
            setSubmitLoading(false);
            onClose();
            toast.error(res.message);
          }
        } catch (err) {
          setSubmitLoading(false);
          toast.error(err);
        }
      }
    }
  };

  return (
    <>
      <Modal
        show={proceedModal}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_cstm_design5"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Choose Status</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={12}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() =>
                        setStatusData({
                          ...statusData,
                          consultantStatus: "active",
                        })
                      }
                    >
                      <label htmlFor="consume">
                        <span className="radio_button"></span>
                        <span>Active</span>
                      </label>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() =>
                        setStatusData({
                          ...statusData,
                          consultantStatus: "hold",
                        })
                      }
                    >
                      <label htmlFor="cons">
                        <span className="radio_button"></span>
                        <span>Hold</span>
                      </label>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="third"
                      onClick={() =>
                        setStatusData({
                          ...statusData,
                          consultantStatus: "acknowledge",
                        })
                      }
                    >
                      <label htmlFor="consum">
                        <span className="radio_button"></span>
                        <span>Acknowledgement</span>
                      </label>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="regis_tration">
                      <input
                        className="input"
                        type="text"
                        placeholder="Registration No."
                        value={statusData.registrationNumber}
                        maxLength={300}
                        onChange={(e) =>
                          setStatusData({
                            ...statusData,
                            registrationNumber: e.target.value,
                          })
                        }
                      />

                      <div className="da_te">
                        <div className="flex_direction_cstm">
                          <DatePicker
                            selected={statusData.issueDate}
                            className="input input1"
                            onChange={(date) =>
                              setStatusData({ ...statusData, issueDate: date })
                            }
                            dateFormat="dd/MM/yyyy"
                            selectsStart
                            startDate={statusData.issueDate}
                            placeholderText="Select Issue Date"
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                          />
                          <DatePicker
                            className="input input1"
                            selected={statusData.expireDate}
                            onChange={(date) =>
                              setStatusData({ ...statusData, expireDate: date })
                            }
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            placeholderText="Select Expiry Date"
                            endDate={statusData.expireDate}
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                          />
                        </div>
                      </div>
                      <div className="da_te da_te2">
                        <label className="upload_pan" htmlFor="upload1">
                          <input
                            type="file"
                            className="upload_pan1"
                            id="upload1"
                            onChange={(e) =>
                              setStatusData({
                                ...statusData,
                                license: e.target.files[0],
                              })
                            }
                          />
                          <span className="icon_doc">
                            <FiUpload />
                          </span>
                          <p className="file_pan">Upload License</p>
                          <p className="file_pan">{statusData.license?.name}</p>
                        </label>
                        <label className="upload_pan" htmlFor="upload2">
                          <input
                            type="file"
                            className="upload_pan1"
                            id="upload2"
                            onChange={(e) =>
                              setStatusData({
                                ...statusData,
                                feeAttachment: e.target.files[0],
                              })
                            }
                          />
                          <span className="icon_doc">
                            <FiUpload />
                          </span>
                          <p className="file_pan">Fees Attachment</p>
                          <p className="file_pan">
                            {statusData.feeAttachment?.name}
                          </p>
                        </label>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <textarea
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                      placeholder="Remarks"
                      value={statusData.remark}
                      onChange={(e) =>
                        setStatusData({
                          ...statusData,
                          remark: e.target.value,
                        })
                      }
                    ></textarea>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="form_field">
                      {attachments &&
                        attachments.map((attach, i) => {
                          return (
                            <div className="input_upload_file my-2" key={i}>
                              <label>
                                <input
                                  className="input"
                                  type="file"
                                  onChange={(e) => kyMbrImg(e, i)}
                                />
                                <div className="wrapper_upload_div">
                                  <span className="upload_div1" id="image_">
                                    {attach?.name ? attach?.name : "file name"}
                                  </span>
                                  <span className="Upload-file">
                                    <GrAttachment />
                                    Add Attachment
                                  </span>
                                </div>
                              </label>
                              {i !== 0 && (
                                <span
                                  className="add_more_append"
                                  onClick={(e) =>
                                    setAttachments(
                                      attachments.filter(
                                        (file, index) => index !== i
                                      )
                                    )
                                  }
                                >
                                  Remove
                                </span>
                              )}
                            </div>
                          );
                        })}
                      <button
                        className="custom_btn my-4"
                        onClick={() => setAttachments([...attachments, ""])}
                      >
                        + Add more Attachment
                      </button>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="custom_btn"
            variant="secondary"
            onClick={updateStatusHandler}
            ref={submitButtonElement}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateRegistrationStatus;
