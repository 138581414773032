import moment from "moment";
import React from "react";
import { imageUrl } from "../../config/config";

const ComplianceHistory = ({
  isApplicable,
  compileType,
  documents,
  remark,
  managerRemark,
  executorData,
  complianceName,
}) => {
  return (
    <>
      <div className="applicable_not mt-3 text-start">
        <div className={`not_app applicable`}>
          {isApplicable ? "Applicable" : "Not Applicable"}
        </div>
      </div>

      {/* Applicable */}
      {isApplicable ? (
        <>
          <form>
            <div className="content_wrapper_acts">
              <div className="compliance_name">
                <ul>
                  <li>
                    <span>Select Complied type</span>
                    <div className="wrapper_radio">
                      <div className="radio_wrap_">
                        <label htmlFor="red">
                          <input
                            type="radio"
                            name="colors"
                            id="red"
                            checked={compileType == "complied" ? true : false}
                            value="complied"
                            required
                          />
                          <span>Complied</span>
                        </label>
                      </div>
                      <div className="radio_wrap_">
                        <label htmlFor="blue">
                          <input
                            type="radio"
                            name="colors"
                            value="cannot do"
                            checked={compileType === "cannot do" ? true : false}
                            id="blue"
                            required
                          />
                          <span>Cannot do</span>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span>Compliance name</span>
                    <h4>{complianceName}</h4>
                  </li>
                  <li>
                    <span>
                      Compliance period
                      {compileType === "cannot do" && "(Optional)"}
                    </span>
                    From :{" "}
                    <input
                      className="input"
                      placeholder="From .."
                      type="date"
                      // ref={ref}
                      value={executorData.complianceStartDate}
                      // onFocus={() => (ref.current.type = "date")}
                      // onBlur={() => (ref.current.type = "text")}
                    />
                    To :{" "}
                    <input
                      className="input"
                      placeholder="To .."
                      type="date"
                      value={executorData.complianceEndDate}
                      // ref={ref}
                      // onFocus={() => (ref.current.type = "date")}
                      // onBlur={() => (ref.current.type = "text")}
                    />
                  </li>
                  <li>
                    <div className="row">
                      <div className="col-5">
                        <span>
                          Compliance Date
                          {compileType === "cannot do" && "(Optional)"}
                        </span>
                        <input
                          className="input mw-100"
                          placeholder="Filter by date"
                          type="date"
                          value={executorData.complianceDate}
                          // ref={ref}
                          // onFocus={() => (ref.current.type = "date")}
                          // onBlur={() => (ref.current.type = "text")}
                        />
                      </div>
                      <div className="col-5">
                        <li>
                          <span>Penality/ Interest (Optional)</span>
                          <input
                            type="number"
                            className="input input_2 mw-100"
                            placeholder="Enter amount"
                            value={executorData.getPenaltyInterest}
                            required
                          ></input>
                        </li>
                      </div>
                    </div>
                  </li>

                  {/* <li>
                    <span>
                      Compliance Upload Date{" "}
                      {compileType === "cannot do" && "(Optional)"}
                    </span>
                    <h6>
                      {moment(executorData.createdAt).format("DD MMM YYYY")}
                    </h6>
                  </li> */}
                </ul>
              </div>
              <div className="upload_document">
                <>
                  <div className="wrapper_compliance">
                    <table className="table table-striped table-bordered table-hover">
                      <thead>
                        <tr className="custom_tr">
                          <th>Documents</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents.length > 0 ? (
                          documents.map((file) => {
                            return (
                              <tr className="cstm_tr" key={file._id}>
                                <td>
                                  <div className="d-flex justify-content-between">
                                    <span>{file.images}</span>
                                    <button
                                      className="custom_btn btn btn-primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        window.open(
                                          `${imageUrl}${file.images}`
                                        );
                                      }}
                                    >
                                      Download
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>No Documents Attached</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>

                <div className="form_control_message">
                  <span>Remarks</span>
                  {compileType === "complied" && <span>(Optional)</span>}

                  <textarea
                    className="form-control"
                    id="form6Example7"
                    rows="4"
                    value={remark}
                    placeholder="Write something here…"
                  ></textarea>
                </div>
                {isApplicable && managerRemark.length > 0 && (
                  <div className="form_control_message">
                    <h4>Manager Remark</h4>
                    {managerRemark[managerRemark.length - 1]?.remark}
                  </div>
                )}
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <form>
            <div className="content_wrapper_acts">
              <div className="upload_document">
                <div className="form_control_message">
                  <span>Remarks</span>
                  <p>{remark}</p>
                </div>

                <div className="wrapper_compliance py-4">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr className="custom_tr">
                        <th>Documents</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.length > 0 ? (
                        documents.map((file) => {
                          return (
                            <tr className="cstm_tr" key={file._id}>
                              <td>
                                <div className="d-flex justify-content-between">
                                  <span>{file.images}</span>
                                  <button
                                    className="custom_btn btn btn-primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(`${imageUrl}${file.images}`);
                                    }}
                                  >
                                    Download
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Documents Attached</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {!isApplicable && managerRemark.length > 0 && (
                  <div className="form_control_message">
                    <h4>Manager Remark</h4>
                    {managerRemark[managerRemark.length - 1]?.remark}
                  </div>
                )}
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default ComplianceHistory;
